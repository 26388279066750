import { Box, Typography } from '@mui/joy';
import Layout from '../features/components/Layout';
import { useProfileMutation } from '../app/services/user';
import { useEffect } from 'react';

export default function Info() {
	const [profile, { data, isLoading }] = useProfileMutation();

	useEffect(() => {
		(async () => {
			await profile();
		})();
	}, [profile]);

	useEffect(() => {
		if (data) {
			console.log(data);
		}
	}, [data]);

	return (
		<Layout>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					my: 1,
					gap: 1,
					flexWrap: 'wrap',
					'& > *': {
						minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
						flexGrow: 1,
					},
				}}>
				<Typography level="h1" fontSize="xl4">
					Info {isLoading ? 'loading...' : data?.login}
				</Typography>
			</Box>
		</Layout>
	);
}
