import { configureStore } from '@reduxjs/toolkit'
import { api } from './services/auth'
import authReducer from '../features/auth/authSlice'
import sidebarReducer from '../features/sidebars/barSlice'
import { apiUser } from './services/user'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [apiUser.reducerPath]: apiUser.reducer,
    auth: authReducer,
    sidebars: sidebarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(apiUser.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
