import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssBaseline as CssBase2 } from '@mui/material';
import Box from '@mui/joy/Box';
import SecondSidebar from '../sidebars/SecondSidebar';
import Header from './Header';
import ColorSchemeToggle from '../../utils/ColorSchemaToggle';
import customTheme from '../../utils/theme';
import useScript from '../../hooks/useScript';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCurrentPlan, selectCurrentUser, setCredentials, setPlan } from '../auth/authSlice';
import { getFromStorage, getToken, removeUser } from '../../app/utils';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/joy/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import { List, ListItem, Menu, MenuItem, Typography, Badge } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { useGetCurrentPlanMutation, useGetNotificationsQuery, useProfileMutation, useReadAllNotificationsMutation } from '../../app/services/user';
import moment from 'moment';
import { Alert } from '../../utils/Alert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LinkIcon from '@mui/icons-material/Link';

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export default function Layout(props: { children: React.ReactNode }) {
	const status = useScript(`https://unpkg.com/feather-icons`);
	const location = useLocation();
	const user_data = useSelector(selectCurrentUser);
	const thisPlan = useSelector(selectCurrentPlan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [open2, setOpen2] = React.useState(false);
	const [textError, setTextError] = React.useState('');
	const [color, setColor] = React.useState('success' as 'success' | 'error' | 'warning' | 'info' | 'primary' | 'secondary' | 'neutral' | 'primarySocket' | undefined);

	const [anchorElNotif, setAnchorElNotif] = React.useState<null | HTMLElement>(null);
	const openNotif = Boolean(anchorElNotif);

	const handleClickNotif = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!openNotif) {
			readAll();
		}

		openNotif ? setAnchorElNotif(null) : setAnchorElNotif(event.currentTarget);
	};

	const [getCurrentPlan, { data: plan_data, isLoading: plan_loading }] = useGetCurrentPlanMutation();
	const [getUser, { data: user_data2, isLoading: user_loading, isError: userError, isSuccess: userIsSuccess }] = useProfileMutation();
	const [readAll, { data: readAllData, isLoading: readAllLoading, isSuccess: readingSuccess }] = useReadAllNotificationsMutation();
	const { data: notifications, refetch } = useGetNotificationsQuery();

	React.useEffect(() => {
		if (readingSuccess) {
			refetch();
		}
	}, [readingSuccess]);

	React.useEffect(() => {
		refetch();
	}, [refetch]);

	React.useEffect(() => {
		if (!thisPlan) {
			getCurrentPlan();
		}
	}, [getCurrentPlan]);

	React.useEffect(() => {
		if (plan_data) {
			dispatch(setPlan(plan_data));
		}
	}, [dispatch, plan_data]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEnhancedEffect(() => {
		// Feather icon setup: https://github.com/feathericons/feather#4-replace
		// @ts-ignore
		if (typeof feather !== 'undefined') {
			// @ts-ignore
			feather.replace();
		}
	}, [status, location]);

	React.useEffect(() => {
		if (!user_data && plan_data) {
			const user_storage = getFromStorage();

			if (user_storage && user_storage.login) {
				dispatch(
					setCredentials({
						user: user_storage,
						refreshToken: getToken() as string,
						// plan: thisPlan || plan_data || null,
					}),
				);
			} else {
				getUser();
			}
		}
	}, [dispatch, navigate, user_data, plan_data]);

	React.useEffect(() => {
		if (userIsSuccess) {
			dispatch(
				setCredentials({
					user: user_data2,
					refreshToken: getToken() as string,
					// plan: thisPlan || plan_data || null,
				}),
			);
		}
	}, [dispatch, userIsSuccess, user_data2]);

	React.useEffect(() => {
		if (userError) {
			removeUser();
			navigate('/login');
		}
	}, [userError, notifications]);

	React.useEffect(() => {
		console.log(thisPlan)
	}, [thisPlan]);

	return (
		<CssVarsProvider disableTransitionOnChange theme={customTheme} defaultMode="light">
			<GlobalStyles
				styles={{
					'[data-feather], .feather': {
						color: 'var(--Icon-color)',
						margin: 'var(--Icon-margin)',
						fontSize: 'var(--Icon-fontSize, 20px)',
						width: '1em',
						height: '1em',
					},
				}}
			/>
			<Alert open={open2} setOpen={setOpen2} text={textError} color={color} />
			<CssBaseline />
			<CssBase2 />
			<Box sx={{ display: 'flex', minHeight: '100dvh' }}>
				<Header notifications={notifications || []} />
				<SecondSidebar />
				<Box
					component="main"
					className="MainContent"
					sx={(theme: any) => ({
						'--FirstSidebar-width': '270px',
						px: {
							xs: 2,
							md: 6,
						},
						pt: {
							xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
							sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
							md: 3,
						},
						pb: {
							xs: 2,
							sm: 2,
							md: 3,
						},
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '100dvh',
						gap: 1,
						ml: {
							md: 'var(--FirstSidebar-width, 0px)',
						},
					})}>
					<Box sx={{ display: { xs: 'none', md: 'inline-flex' }, alignItems: 'center', justifyContent: 'flex-end', zIndex: 99 }}>
						<Box
							sx={{
								display: { xs: 'none', md: 'inline-flex' },
								alignItems: 'center',
								mr: 3,
							}}>
							<Typography>
								{t('header.your_subscription')}:{' '}
								{plan_loading ? (
									'...'
								) : (
									<>
										<b>
											{
												// @ts-ignore
												t(`plans.${thisPlan?.name || plan_data?.plan?.name}`)
											}
										</b>{' '}
										({moment(thisPlan?.end_date || plan_data?.end_date).format('DD/MM/YYYY')})
									</>
								)}
							</Typography>
							<IconButton
								sx={{ ml: '15px' }}
								size="sm"
								variant="soft"
								color="success"
								onClick={() => {
									navigate('/upgrade');
								}}>
								<UpgradeIcon />
							</IconButton>
						</Box>
						<Badge badgeContent={notifications?.filter((notification: any) => notification?.isRead === false).length} color="danger">
							<IconButton sx={{ ml: '5px' }} id="basic-demo-button" aria-controls={openNotif ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={openNotif ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClickNotif}>
								<NotificationsIcon />
							</IconButton>
						</Badge>
						<IconButton sx={{ ml: '5px', display: { xs: 'none', md: 'inline-flex' } }} id="basic-demo-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClick}>
							<LanguageIcon />
						</IconButton>
						<Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} aria-labelledby="basic-demo-button">
							<MenuItem
								onClick={() => {
									i18n.changeLanguage('en');
								}}>
								English
							</MenuItem>
							<MenuItem
								onClick={() => {
									i18n.changeLanguage('uk');
								}}>
								Українська
							</MenuItem>
						</Menu>
						<List
							size={'sm'}
							variant="outlined"
							sx={{
								minWidth: '200px',
								maxWidth: {
									xs: 'calc(100% - 100px)',
									md: 'calc(100vw - 30px)',
								},
								maxHeight: 'calc(100vh - var(--Header-height) - 80px)',
								backgroundColor: 'white',
								zIndex: 999999999,
								borderRadius: 'sm',
								position: 'absolute',
								right: 0,
								top: 'calc(var(--Header-height) + 60px)',
								transform: 'translate(-25%, 0)',
								display: openNotif ? 'block' : 'none',
								overflow: 'auto',
							}}>
							{notifications?.map((notification: any) => (
								<ListItem key={notification.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
									<IconButton
										variant="plain"
										color="neutral"
										size="sm"
										sx={{
											mr: 1,
										}}
										onClick={() => {
											if (notification?.link) {
												window.open(`${process.env.REACT_APP_MAIN_URL}/${notification.link}`, '_blank');
											}
										}}>
										{notification?.link ? <LinkIcon /> : null}
									</IconButton>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											flexWrap: 'wrap',
											gap: 1,
											flex: 1,
										}}>
										<Box>
											<Typography level="body2">{notification.title}</Typography>
											<Typography level="body3">{notification.message}</Typography>
										</Box>
										<Box>
											<Typography level="body3">
												{new Date(notification.createdAt).toLocaleDateString('uk-UA', {
													day: 'numeric',
													month: 'long',
													year: 'numeric',
													minute: 'numeric',
													hour: 'numeric',
												})}
											</Typography>
										</Box>
									</Box>
								</ListItem>
							))}
							{notifications?.length === 0 && <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No notifications</ListItem>}
						</List>
						<ColorSchemeToggle sx={{ ml: '5px', display: { xs: 'none', md: 'inline-flex' } }} />
					</Box>
					{props.children}
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
