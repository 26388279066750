import { AspectRatio, Button } from '@mui/joy';
import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function ImageUploader({ setSelectedFile }: { setSelectedFile: (file: File | null) => void }) {
	const [image, setImage] = useState<File | any>(null);

	const handleFileSelect = (event: any) => {
		setSelectedFile(event.target.files[0]);

		setImage(URL.createObjectURL(event.target.files[0]));
	};

	return (
		<Stack spacing={4} direction="column" justifyContent={'center'}>
			<Stack spacing={2} direction="row" alignItems={'center'} maxWidth={800}>
				{image?.length > 0 ? (
					<>
						<AspectRatio ratio={2 / 1} sx={{ width: '500px' }}>
							<LazyLoadImage
								src={image}
								style={{
									marginLeft: '5px',
								}}
							/>
						</AspectRatio>
					</>
				) : (
					<></>
				)}

				<input accept=".png,.jpg,.jpeg" multiple style={{ display: 'none' }} id="image-file" type="file" onChange={handleFileSelect} />
			</Stack>

			<Stack spacing={2} direction="row" alignItems={'center'} sx={{ marginTop: '15px !important' }}>
				<label htmlFor="image-file">
					<Button color="primary" component="span">
						{t('exhibitsPage.addPhoto')}
					</Button>
				</label>
			</Stack>
		</Stack>
	);
}
