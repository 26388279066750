import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';

export interface Hall {
    id: number;
    name: string;
    type: string;
    status: number;
    museumId: number;
}

export const api = createApi({
	baseQuery: customBaseQuery,
	endpoints: builder => ({
		getHalls: builder.mutation<any, void>({
            query: () => ({
                url: `/hall/all`,
                method: 'GET',
            }),
        }),
        createHall: builder.mutation({
            query: (data: any) => ({
                url: `/hall`,
                method: 'POST',
                body: data,
            }),
        }),
        updateHall: builder.mutation({
            query: (data: any) => ({
                url: `/hall`,
                method: 'PATCH',
                body: data,
            }),
        }),
        deleteHall: builder.mutation({
            query: (id: any) => ({
                url: `/hall/${id}`,
                method: 'DELETE',
            }),
        }),
	}),
});

export const { useCreateHallMutation, useDeleteHallMutation, useGetHallsMutation, useUpdateHallMutation } = api;
