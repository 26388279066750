import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';
import axios from 'axios';
import Cookies from 'js-cookie';

export interface User {
	id: number;
	login: string;
}

export interface UpdateSettings {
	id?: number;
	key: string;
	value: string;
	userId?: number;
}

export interface Request {
	userId: number;
}

export const api = createApi({
	baseQuery: customBaseQuery,
	endpoints: builder => ({
		settings: builder.mutation<[], void>({
			query: () => ({
				url: `/museum/settings`,
				method: 'GET',
			}),
		}),
		updateSettings: builder.mutation({
			query: (data: UpdateSettings[]) => ({
				url: `/museum/settings`,
				method: 'PUT',
				body: data,
			}),
		}),
		getMuseum: builder.mutation<any, void>({
			query: () => ({
				url: `/museum`,
				method: 'GET',
			}),
		}),
		updateMuseum: builder.mutation({
			query: (data: any) => ({
				url: `/museum`,
				method: 'PATCH',
				body: data,
			}),
		}),
		uploadBanner: builder.mutation({
			queryFn: async data => {
				const res = await axios.post(`${process.env.REACT_APP_API_URL}/museum/uploadBanner`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + Cookies.get('refreshToken'),
					},
				});

				return res.data;
			},
		}),
		getAllMuseums: builder.mutation<any, void>({
			query: () => ({
				url: `/museum/all`,
				method: 'GET',
			}),
		}),
		updateMuseumStatus: builder.mutation({
			query: ({ id, status }: { id: number; status: number }) => ({
				url: `/museum/${id}/updateStatus/${status}`,
				method: 'POST',
			}),
		}),
		getCities: builder.mutation<any, void>({
			query: () => ({
				url: `/client/data/ukraine-city`,
				method: 'GET',
			}),
		}),
		setDebt: builder.mutation({
			query: (data: any) => ({
				url: `/museum/setDebt`,
				method: 'POST',
				body: data,
			}),
		}),
	}),
});

export const { useSetDebtMutation, useGetCitiesMutation, useSettingsMutation, useUpdateSettingsMutation, useGetMuseumMutation, useUpdateMuseumMutation, useUploadBannerMutation, useGetAllMuseumsMutation, useUpdateMuseumStatusMutation } = api;
