import Layout from '../features/components/Layout';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Alert } from '../utils/Alert';
import { useGetAllMuseumsMutation, useSetDebtMutation, useUpdateMuseumStatusMutation } from '../app/services/museum';
import { Loading } from '../utils/Loading';
import { Box, Button, Select, Sheet, Table, Option, Input } from '@mui/joy';

export default function Museums() {
	const [getAllMuseum, { data, isLoading, error }] = useGetAllMuseumsMutation();
	const [updateStatus, { data: updateData, isLoading: updateIsLoading, error: updateError }] = useUpdateMuseumStatusMutation();
	const [setDebt, { data: debtData, isLoading: debtIsLoading, error: debtError }] = useSetDebtMutation();

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

	const { t } = useTranslation();

	useEffect(() => {
		getAllMuseum();
	}, [getAllMuseum]);

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			{isLoading ? (
				<Loading />
			) : (
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
					<Sheet
						variant="outlined"
						sx={{
							'--TableCell-height': '40px',
							// the number is the amount of the header rows.
							'--TableHeader-height': 'calc(1 * var(--TableCell-height))',
							'--Table-firstColumnWidth': '40px',
							'--Table-lastColumnWidth': '80px',
							// background needs to have transparency to show the scrolling shadows
							'--TableRow-stripeBackground': 'rgba(0 0 0 / 0.04)',
							'--TableRow-hoverBackground': 'rgba(0 0 0 / 0.08)',
							overflow: 'auto',
							background: theme => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
                        linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
                        radial-gradient(
                        farthest-side at 0 50%,
                        rgba(0, 0, 0, 0.12),
                        rgba(0, 0, 0, 0)
                        ),
                        radial-gradient(
                            farthest-side at 100% 50%,
                            rgba(0, 0, 0, 0.12),
                            rgba(0, 0, 0, 0)
                        )
                        0 100%`,
							backgroundSize: '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
							backgroundRepeat: 'no-repeat',
							backgroundAttachment: 'local, local, scroll, scroll',
							backgroundPosition: 'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
							backgroundColor: 'background.surface',
						}}>
						<Table
							borderAxis="bothBetween"
							hoverRow
							sx={{
								'& tr > *:first-child': {
									position: 'sticky',
									left: 0,
									boxShadow: '1px 0 var(--TableCell-borderColor)',
									bgcolor: 'background.surface',
								},
								// '& tr > *:last-child': {
								// 	position: 'sticky',
								// 	right: 0,
								// 	bgcolor: 'var(--TableCell-headBackground)',
								// },
							}}>
							<thead>
								<tr>
									<th style={{ width: 'var(--Table-firstColumnWidth)' }}>№</th>
									<th style={{ width: 200 }}>{t('museumAdminPage.museumName')}</th>
									<th style={{ width: 150 }}>{t('museumAdminPage.museumType')}</th>
									<th style={{ width: 250 }}>{t('museumAdminPage.email')}</th>
									<th style={{ width: 200 }}>{t('museumAdminPage.workStatus')}</th>
									<th style={{ width: 200 }}>{t('museumAdminPage.planName')}</th>
									<th style={{ width: 200 }}>{t('museumAdminPage.profit')}</th>
									<th style={{ width: 200 }}>{t('museumAdminPage.debt')}</th>
									{/* <th aria-label="last" style={{ width: 'var(--Table-lastColumnWidth)' }} /> */}
								</tr>
							</thead>
							<tbody>
								{data?.map((row: any, index: number) => (
									<tr key={row.id}>
										<td>{index + 1}</td>
										<td>{row.name}</td>
										<td>
											{
												// @ts-ignore
												t('settingsPage.museumTypes', { returnObjects: true })?.filter((item: any, index: number) => index === Number(row.type))
											}
										</td>
										<td>{row?.users[0]?.login || ''}</td>
										<td>
											<Select
												defaultValue={Number(row.workStatus)}
												onChange={(e, newVal) => {
													updateStatus({ id: row.id, status: Number(newVal) });
												}}
												sx={{
													'& .MuiSelect-listbox': {
														zIndex: 10000,
														position: 'absolute !important',
													},
												}}>
												{
													// @ts-ignore
													t('settingsPage.adminStatus', { returnObjects: true })?.map((item: any, index: number) => (
														<Option key={index} value={index}>
															{item}
														</Option>
													))
												}
											</Select>
										</td>
										<td>{row?.plan?.name || ''}</td>
										<td>{row?.profit || '0'}</td>
										<td>
											<form
												style={{ display: 'flex', gap: 1 }}
												onSubmit={(e: any) => {
													e.preventDefault();

													const data = new FormData(e.target);
													const debt = data.get('debt');

													setDebt({ museum_id: row.id, debt: Number(debt) });
												}}>
												<Input type="number" defaultValue={row?.debt || ''} name='debt' />
												<Button size="sm" variant="plain" color="primary" type="submit">
													<i className="fa-solid fa-check" style={{ color: 'var(--joy-palette-success-500, #1A7D36)' }}></i>
												</Button>
											</form>
										</td>
										{/* <td>
											<Box sx={{ display: 'flex', gap: 1 }}>
												<Button
													size="sm"
													variant="plain"
													color="neutral"
													onClick={() => {
														// setCurrentGuide(row);
														// setIsModalOpen({ ...isModalOpen, update: true });
													}}>
													<i className="fa-solid fa-check" style={{ color: 'var(--joy-palette-success-500, #1A7D36)' }}></i>
												</Button>
												<Button
													size="sm"
													variant="soft"
													color="danger"
													onClick={async () => {
														// deleteGuide(row.id);
													}}>
													<i className="fa-solid fa-close"></i>
												</Button>
											</Box>
										</td> */}
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
			)}
		</Layout>
	);
}
