import { Box, Input, Select, Option, Button, CircularProgress, AspectRatio, IconButton, Card, Modal, Sheet, ModalClose, FormControl, FormLabel, Textarea, Checkbox, Menu, MenuItem, ListItemDecorator, ListDivider, Typography } from '@mui/joy';
import Layout from '../features/components/Layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddExhibitMutation, useDelExhibitsMutation, useExhibitsMutation, useGetExhibitMutation, useGetMemoryMutation, useUpdateImgMutation } from '../app/services/exhibits';
import { Stack } from '@mui/material';
import JoyTypography from '@mui/joy/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Add from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useGetHallsMutation } from '../app/services/halls';
import { Alert } from '../utils/Alert';
import { Loading } from '../utils/Loading';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteForever, Edit } from '@mui/icons-material';
import { useEditExhibitMutation } from '../app/services/exhibits';
import ImagesUploader from '../utils/ImagesUploader';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { selectCurrentPlan } from '../features/auth/authSlice';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomCalendarYear } from './Excursions';

const ModalCreateExhibit = ({ exhibits, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { exhibits: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [createExhibit, { isLoading: createIsLoading, isSuccess: createIsSuccess }] = useAddExhibitMutation();
	const [getHalls, { data: hallsData }] = useGetHallsMutation();

	const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
	const [dateArrived, setDateArrived] = useState<Date | null>(null);
	const [dateCreated, setDateCreated] = useState<Date | null>(null);
	const [dateRestoration, setDateRestoration] = useState<Date | null>(null);
	const [countDescription, setCountDescription] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		getHalls();
	}, []);

	useEffect(() => {
		if (createIsSuccess) {
			setTextAlert(t('success.date_added'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, create: false });
			setCountDescription(0);
			setSelectedFile(null);
			exhibits();
		}
	}, [createIsSuccess]);

	const handleUpload = (data: any) => {
		if (selectedFile) {
			const formData = new FormData();

			for (let i = 0; i < selectedFile.length; i++) {
				// @ts-ignore
				formData.append('files[]', selectedFile[i], selectedFile[i].name);
			}

			Object.keys(data).forEach(key => {
				// @ts-ignore
				if (key === 'tags') {
					// if , in string
					if (data[key].includes(',')) {
						// @ts-ignore
						data[key].split(',').forEach((tag: string) => {
							formData.append('tags', tag);
						});
					} else {
						// @ts-ignore
						formData.append('tags', [data[key]]);
					}

					return;
				}
				data[key] && formData.append(key, data[key]);
			});

			createExhibit(formData);
		}
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['create']} onClose={() => setIsModalOpen({ ...isModalOpen, create: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<JoyTypography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('exhibitsPage.addExhibit')}
				</JoyTypography>
				<form
					autoComplete="off"
					onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();

						const data = {
							// @ts-ignore
							name: event.currentTarget?.name?.value,
							article: event.currentTarget?.article?.value,
							source: event.currentTarget?.source?.value,
							description: event.currentTarget?.description?.value,
							type: event.currentTarget?.type[1].value,
							dateCreated: dateCreated,
							dateArrived: dateArrived,
							dateRestoration: dateRestoration,
							state: event.currentTarget?.state[1].value,
							hall: event.currentTarget?.hall[1].value || null,
							tags: event.currentTarget?.tags?.value,
							oneCopy: event.currentTarget?.oneCopy.checked ? 1 : 0,
						};

						// return;
						if (selectedFile?.length === 0 || selectedFile === null) {
							setTextAlert(t('errors.empty_field') + ' (' + t('exhibitsPage.photo') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.name.length > 170) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.exhibitName') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.exhibitInfo') + ') - 3000');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.article.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.article') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.source.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.source') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						handleUpload(data);
					}}>
					<Stack spacing={2}>
						<FormLabel>
							{
								// @ts-ignore
								JSON.parse(Cookies.get('museum'))?.type === '4' ? t('exhibitsPage.collectionAssigned') : t('exhibitsPage.hallAssigned')
							}
						</FormLabel>
						<Select
							defaultValue={
								//@ts-ignore
								(hallsData && hallsData.filter((hall: any) => hall.type === 'hall')[0]?.id) || undefined
							}
							name="hall">
							{
								//@ts-ignore
								hallsData
									?.filter((hall: any) => hall.type === 'hall')
									.map((hall: any, index: number) => {
										return (
											<Option key={index} value={hall?.id}>
												{hall.name}
											</Option>
										);
									})
							}
						</Select>

						<FormControl>
							<FormLabel>{t('exhibitsPage.exhibitName')}</FormLabel>
							<Input autoFocus required name="name" />
						</FormControl>
						<FormControl>
							<FormLabel>{t('exhibitsPage.exhibitInfo')}</FormLabel>
							<Typography
								sx={{
									mb: 1,
									//@ts-ignore
									color: countDescription > 3000 ? 'red' : 'inherit',
								}}>
								{countDescription}/3000
							</Typography>
							<Textarea
								required
								maxRows={4}
								minRows={2}
								name="description"
								onChange={e => {
									setCountDescription(e.target.value.length);
								}}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>{t('exhibitsPage.article')}</FormLabel>
							<Input name="article" />
						</FormControl>

						<FormControl>
							<FormLabel>{t('exhibitsPage.source')}</FormLabel>
							<Input name="source" />
						</FormControl>

						<FormLabel>{t('exhibitsPage.type')}</FormLabel>
						<Select defaultValue={0} name="type">
							{
								//@ts-ignore
								t('exhibitsPage.types', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</Select>

						<FormLabel>{t('exhibitsPage.state')}</FormLabel>
						<Select defaultValue={0} name="state">
							{
								//@ts-ignore
								t('exhibitsPage.states', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</Select>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
								mb: 1,
							}}>
							<Checkbox
								defaultChecked={false}
								color="primary"
								name="oneCopy"
								sx={{
									mr: '15px',
								}}
							/>
							<FormLabel>{t('exhibitsPage.oneCopy')}</FormLabel>
						</Box>

						<Stack spacing={2} direction="row" alignItems={'center'}>
							<Checkbox
								checked={dateCreated !== null}
								color="primary"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									if (!event.target.checked) {
										setDateCreated(null);
									} else {
										setDateCreated(new Date());
									}
								}}
							/>

							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CustomCalendarYear
									currentValue={dayjs(dateCreated)}
									setValue={(date: any) => {
										setDateCreated(new Date(date));
									}}
									text={t('exhibitsPage.dateCreated')}
									disabled={dateCreated === null}
								/>
							</LocalizationProvider>
						</Stack>
						<Stack spacing={2} direction="row" alignItems={'center'}>
							<Checkbox
								checked={dateArrived !== null}
								color="primary"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									if (!event.target.checked) {
										setDateArrived(null);
									} else {
										setDateArrived(new Date());
									}
								}}
							/>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CustomCalendarYear
									currentValue={dayjs(dateArrived)}
									setValue={(date: any) => {
										setDateArrived(new Date(date));
									}}
									text={t('exhibitsPage.dateArrived')}
									disabled={dateArrived === null}
								/>
							</LocalizationProvider>
						</Stack>
						<Stack spacing={2} direction="row" alignItems={'center'}>
							<Checkbox
								checked={dateRestoration !== null}
								color="primary"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									if (!event.target.checked) {
										setDateRestoration(null);
									} else {
										setDateRestoration(new Date());
									}
								}}
							/>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CustomCalendarYear
									currentValue={dayjs(dateRestoration)}
									setValue={(date: any) => {
										setDateRestoration(new Date(date));
									}}
									text={t('exhibitsPage.dateRestoration')}
									disabled={dateRestoration === null}
								/>
							</LocalizationProvider>
						</Stack>

						<FormControl>
							<FormLabel>{t('exhibitsPage.tags')}</FormLabel>
							<Textarea maxRows={4} minRows={2} name="tags" placeholder={t('exhibitsPage.inputTags') || ''} />
						</FormControl>

						<ImagesUploader setSelectedFile={setSelectedFile} />

						<Button type="submit" disabled={createIsLoading}>
							{createIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('exhibitsPage.addExhibit')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalUpdateExhibit = ({ exhibits, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor, currentExhibit }: { exhibits: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void; currentExhibit: number }) => {
	const [exhibit, { isLoading: exhibitIsLoading, isSuccess: exhibitIsSuccess, data: exhibitData }] = useGetExhibitMutation();
	const [updateExhibit, { isLoading: updateIsLoading, isSuccess: updateIsSuccess }] = useEditExhibitMutation();
	const [getHalls, { data: hallsData }] = useGetHallsMutation();
	const [updateImage, { isLoading: updateImageIsLoading, isSuccess: updateImageIsSuccess }] = useUpdateImgMutation();

	const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
	const [dateArrived, setDateArrived] = useState<Date | null>(null);
	const [dateCreated, setDateCreated] = useState<Date | null>(null);
	const [dateRestoration, setDateRestoration] = useState<Date | null>(null);
	const [countDescription, setCountDescription] = useState<number>(0);
	const [oneCopy, setOneCopy] = useState(0);

	const [temp, setTemp] = useState<any>(null);

	const { t } = useTranslation();

	useEffect(() => {
		if (isModalOpen.update) {
			exhibit(currentExhibit);
			getHalls();
		}
	}, [isModalOpen.update]);

	useEffect(() => {
		if (exhibitIsSuccess) {
			setDateArrived(exhibitData?.dateArrived ? new Date(exhibitData?.dateArrived) : null);
			setDateCreated(exhibitData?.dateCreated ? new Date(exhibitData?.dateCreated) : null);
			setDateRestoration(exhibitData?.dateRestoration ? new Date(exhibitData?.dateRestoration) : null);

			setTemp(exhibitData);
			setCountDescription(exhibitData?.description?.length || 0);
			setSelectedFile(null);

			setOneCopy(exhibitData?.oneCopy);
		}
	}, [exhibitIsSuccess]);

	useEffect(() => {
		if (updateIsSuccess) {
			setTextAlert(t('success.date_updated'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, update: false });
			exhibits();
		}
	}, [updateIsSuccess]);

	useEffect(() => {
		if (updateImageIsSuccess) {
			setTextAlert(t('success.image_updated'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, update: false });
			exhibits();
		}
	}, [updateImageIsSuccess]);

	const handleUpload = (data: any) => {
		if (selectedFile) {
			const formData = new FormData();

			for (let i = 0; i < selectedFile.length; i++) {
				// @ts-ignore
				formData.append('files[]', selectedFile[i], selectedFile[i].name);
			}

			// Object.keys(data).forEach(key => {
			// 	// @ts-ignore
			// 	if (key === 'tags') {
			// 		// if , in string
			// 		if (data[key].includes(',')) {
			// 			// @ts-ignore
			// 			data[key].split(',').forEach((tag: string) => {
			// 				console.log(tag);
			// 				formData.append('tags', tag);
			// 			});
			// 		} else {
			// 			// @ts-ignore
			// 			formData.append('tags', [data[key]]);
			// 		}

			// 		return;
			// 	}
			// 	data[key] && formData.append(key, data[key]);
			// });

			// createExhibit(formData);
		}
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['update']} onClose={() => setIsModalOpen({ ...isModalOpen, update: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<JoyTypography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('exhibitsPage.edit')}
				</JoyTypography>
				<form
					autoComplete="off"
					onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();

						const data = {
							// @ts-ignore
							name: event.currentTarget?.name?.value,
							source: event.currentTarget?.source?.value,
							article: event.currentTarget?.article?.value,
							description: event.currentTarget?.description?.value,
							type: event.currentTarget?.type[1].value,
							dateCreated: dateCreated,
							dateArrived: dateArrived,
							dateRestoration: dateRestoration,
							state: event.currentTarget?.state[1].value,
							hall: event.currentTarget?.hall[1].value,
							tags: event.currentTarget?.tags?.value.split(',').map((tag: string) => tag.trim()),
							oneCopy: event.currentTarget?.oneCopy.checked ? 1 : 0,
						};

						// if (selectedFile?.length === 0 || selectedFile === null) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('exhibitsPage.photo') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						// handleUpload(data);

						if (selectedFile?.length !== 0 && selectedFile !== null) {
							const formData = new FormData();

							for (let i = 0; i < selectedFile.length; i++) {
								// @ts-ignore
								formData.append('files[]', selectedFile[i], selectedFile[i].name);
							}

							updateImage({ id: currentExhibit, data: formData });
						}

						if (data.name.length > 170) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.exhibitName') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.exhibitInfo') + ') - 3000');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.article.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.article') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.source.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('exhibitsPage.source') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						updateExhibit({ id: currentExhibit, data });
					}}>
					<Stack spacing={2}>
						<FormLabel>{t('exhibitsPage.hallAssigned')}</FormLabel>
						<Select
							name="hall"
							value={temp?.hall?.id}
							onChange={event => {
								// @ts-ignore
								setTemp({ ...temp, hall: hallsData?.filter((hall: any) => hall.type === 'hall')[parseInt(event?.target?.id.split('-')[event?.target?.id.split('-').length - 1])] });
							}}>
							{
								//@ts-ignore
								hallsData
									?.filter((hall: any) => hall.type === 'hall')
									.map((hall: any, index: number) => {
										return (
											<Option key={index} value={hall?.id}>
												{hall.name}
											</Option>
										);
									})
							}
						</Select>

						<FormControl>
							<FormLabel>{t('exhibitsPage.exhibitName')}</FormLabel>
							<Input
								autoFocus
								required
								name="name"
								value={temp?.name || ''}
								onChange={event => {
									setTemp({ ...temp, name: event.target.value });
								}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>{t('exhibitsPage.exhibitInfo')}</FormLabel>
							<Typography
								sx={{
									mb: 1,
									//@ts-ignore
									color: countDescription > 3000 ? 'red' : 'inherit',
								}}>
								{countDescription}/3000
							</Typography>
							<Textarea
								required
								maxRows={4}
								minRows={2}
								name="description"
								value={temp?.description || ''}
								onChange={event => {
									setCountDescription(event.target.value.length);
									setTemp({ ...temp, description: event.target.value });
								}}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>{t('exhibitsPage.article')}</FormLabel>
							<Input
								// required
								name="article"
								value={temp?.article || ''}
								onChange={event => {
									setTemp({ ...temp, article: event.target.value });
								}}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>{t('exhibitsPage.source')}</FormLabel>
							<Input
								// required
								name="source"
								value={temp?.source || ''}
								onChange={event => {
									setTemp({ ...temp, source: event.target.value });
								}}
							/>
						</FormControl>

						<FormLabel>{t('exhibitsPage.type')}</FormLabel>
						<Select
							value={temp?.type || 0}
							onChange={event => {
								// @ts-ignore
								setTemp({ ...temp, type: parseInt(event?.target?.id.split('-')[event?.target?.id.split('-').length - 1]) });
							}}
							name="type">
							{
								//@ts-ignore
								t('exhibitsPage.types', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</Select>

						<FormLabel>{t('exhibitsPage.state')}</FormLabel>
						<Select
							value={temp?.state || 0}
							name="state"
							onChange={event => {
								// @ts-ignore
								setTemp({ ...temp, state: parseInt(event?.target?.id.split('-')[event?.target?.id.split('-').length - 1]) });
							}}>
							{
								//@ts-ignore
								t('exhibitsPage.states', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</Select>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
								mb: 1,
							}}>
							<Checkbox
								checked={temp?.oneCopy === 1}
								onChange={event => {
									console.log(event?.target?.checked);
									// @ts-ignore
									setTemp({ ...temp, oneCopy: Number(event?.target?.checked) });
								}}
								color="primary"
								name="oneCopy"
								sx={{
									mr: '15px',
								}}
							/>
							<FormLabel>{t('exhibitsPage.oneCopy')}</FormLabel>
						</Box>

						<Stack spacing={2} direction="row" alignItems={'center'}>
							<Checkbox
								checked={dateCreated !== null}
								color="primary"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									if (!event.target.checked) {
										setDateCreated(null);
									} else {
										setDateCreated(new Date());
									}
								}}
							/>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CustomCalendarYear
									currentValue={dayjs(dateCreated)}
									setValue={(date: any) => {
										setDateCreated(new Date(date));
									}}
									text={t('exhibitsPage.dateCreated')}
									disabled={dateCreated === null}
								/>
							</LocalizationProvider>
						</Stack>
						<Stack spacing={2} direction="row" alignItems={'center'}>
							<Checkbox
								checked={dateArrived !== null}
								color="primary"
								onChange={event => {
									if (!event.target.checked) {
										setDateArrived(null);
									} else {
										setDateArrived(new Date());
									}
								}}
							/>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CustomCalendarYear
									currentValue={dayjs(dateArrived)}
									setValue={(date: any) => {
										setDateArrived(new Date(date));
									}}
									text={t('exhibitsPage.dateArrived')}
									disabled={dateArrived === null}
								/>
							</LocalizationProvider>
						</Stack>
						<Stack spacing={2} direction="row" alignItems={'center'}>
							<Checkbox
								checked={dateRestoration !== null}
								color="primary"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									if (!event.target.checked) {
										setDateRestoration(null);
									} else {
										setDateRestoration(new Date());
									}
								}}
							/>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
							<CustomCalendarYear
									currentValue={dayjs(dateRestoration)}
									setValue={(date: any) => {
										setDateRestoration(new Date(date));
									}}
									text={t('exhibitsPage.dateRestoration')}
									disabled={dateRestoration === null}
								/>
							</LocalizationProvider>
						</Stack>

						<FormControl>
							<FormLabel>{t('exhibitsPage.tags')}</FormLabel>
							<Textarea
								maxRows={4}
								minRows={2}
								name="tags"
								placeholder={t('exhibitsPage.inputTags') || ''}
								value={typeof temp?.exhibitTags === 'object' ? temp?.exhibitTags.map((tag: any) => tag.name).join(', ') : temp?.exhibitTags}
								onChange={event => {
									setTemp({ ...temp, exhibitTags: event.target.value });
								}}
							/>
						</FormControl>

						<ImagesUploader setSelectedFile={setSelectedFile} />

						<Button type="submit" disabled={updateIsLoading}>
							{updateIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('exhibitsPage.editExhibit')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalDeleteExhibit = ({ exhibits, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor, currentExhibit }: { exhibits: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void; currentExhibit: number }) => {
	const [exhibit, { isLoading: exhibitIsLoading, isSuccess: exhibitIsSuccess, data: exhibitData }] = useGetExhibitMutation();
	const [delExhibit, { isSuccess: delIsSuccess, isLoading }] = useDelExhibitsMutation();
	const [getHalls, { data: hallsData }] = useGetHallsMutation();

	const { t } = useTranslation();

	useEffect(() => {
		if (isModalOpen.delete) {
			exhibit(currentExhibit);
			getHalls();
		}
	}, [isModalOpen.delete]);

	useEffect(() => {
		if (delIsSuccess) {
			setTextAlert(t('success.date_deleted'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, delete: false });
			exhibits();
		}
	}, [delIsSuccess, exhibits]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['delete']} onClose={() => setIsModalOpen({ ...isModalOpen, delete: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<JoyTypography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('exhibitsPage.delete') + ' ' + exhibitData?.name + '?'}
				</JoyTypography>

				<Button
					type="button"
					disabled={isLoading}
					onClick={() => {
						delExhibit(exhibitData?.id);
					}}>
					{isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('exhibitsPage.delete')}
				</Button>
			</Sheet>
		</Modal>
	);
};

const ExhibitCard = ({ exhibit, exhibits, setIsModalOpen, isModalOpen, setCurrentExhibit }: { exhibit: any; exhibits: () => void; setIsModalOpen: (arg: any) => void; isModalOpen: any; setCurrentExhibit: (id: number) => void }) => {
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Card variant="outlined" sx={{ width: 320 }}>
			<JoyTypography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
				{exhibit.name}
			</JoyTypography>
			<JoyTypography level="body2">{new Date(exhibit.dateCreated).toLocaleString('uk')}</JoyTypography>
			<IconButton id="menu-button" aria-controls={open ? 'menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClick} sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
				<MoreVertIcon />
			</IconButton>
			<Menu id="menu" anchorEl={anchorEl} open={open} onClose={handleClose} aria-labelledby="menu-button" placement="bottom-end">
				<MenuItem
					onClick={() => {
						setIsModalOpen({ ...isModalOpen, update: true });
						setCurrentExhibit(exhibit.id);
						handleClose();
					}}>
					<ListItemDecorator>
						<Edit />
					</ListItemDecorator>{' '}
					{t('exhibitsPage.editExhibit')}
				</MenuItem>
				<ListDivider />
				<MenuItem
					onClick={() => {
						setIsModalOpen({ ...isModalOpen, delete: true });
						setCurrentExhibit(exhibit.id);
						handleClose();
					}}
					variant="soft"
					color="danger">
					<ListItemDecorator sx={{ color: 'inherit' }}>
						<DeleteForever />
					</ListItemDecorator>{' '}
					{t('exhibitsPage.delete')}
				</MenuItem>
			</Menu>
			<AspectRatio minHeight="120px" maxHeight="200px" sx={{ my: 2 }}>
				<LazyLoadImage src={exhibit.exhibitImages[0]?.url} alt="" />
			</AspectRatio>
			<Box sx={{ display: 'flex' }}>
				<div>
					<JoyTypography level="body3">{t('exhibitsPage.dateAdded')}:</JoyTypography>
					<JoyTypography fontSize="lg" fontWeight="lg">
						{new Date(exhibit.timeCreated).toLocaleString('uk', { day: 'numeric', month: 'long', year: 'numeric' })}
					</JoyTypography>
				</div>
				<Button
					variant="solid"
					size="sm"
					color="primary"
					sx={{ ml: 'auto', fontWeight: 600 }}
					onClick={() => {
						window.open(`${process.env.REACT_APP_MAIN_URL}/${JSON.parse(Cookies.get('museum') || '{}')?.url}/exhibit/${exhibit.uuid}`, '_blank');
					}}>
					{t('exhibitsPage.view')}
				</Button>
			</Box>
		</Card>
	);
};

export default function Exhibits() {
	const [exhibits, { isLoading: isLoadingExhibits, data: exhibitsData, isSuccess: isSuccessExhibits }] = useExhibitsMutation();
	const [getMemory, { isLoading: isLoadingMemory, data: memoryData }] = useGetMemoryMutation();

	const [isModalOpen, setIsModalOpen] = useState<any>({});
	const [currentExhibit, setCurrentExhibit] = useState<number>(0);
	const currentPlan = useSelector(selectCurrentPlan);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

	const { t } = useTranslation();

	useEffect(() => {
		exhibits();
	}, [exhibits, getMemory]);

	useEffect(() => {
		if (isSuccessExhibits) {
			getMemory();
		}
	}, [isSuccessExhibits, getMemory]);

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<Box sx={{ width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 2,
						mb: 2,
						flexDirection: {
							xs: 'column',
							sm: 'row',
						},
					}}>
					<h1>{t('exhibits')}</h1>
					{/* {isLoadingMemory ? (
						<CircularProgress color="primary" determinate={false} size="sm" variant="soft" />
					) : (
						memoryData > 0 && (
							<JoyTypography level="body2" sx={{ color: 'text.secondary' }}>
								{t('exhibitsPage.used') + ' ' + (memoryData / 1024 / 1024).toFixed(2) + ' ' + t('exhibitsPage.mb')}
							</JoyTypography>
						)
					)} */}
					{isLoadingExhibits ? (
						<CircularProgress color="primary" determinate={false} size="sm" variant="soft" />
					) : (
						<JoyTypography level="body2" sx={{ color: 'text.secondary', textTransform: 'lowercase' }}>
							{t('exhibitsPage.used') + ' ' + exhibitsData?.length + ' / ' + currentPlan?.description?.exhibits?.max} {t('exhibits')}
						</JoyTypography>
					)}
					<Button
						startDecorator={<Add />}
						variant="solid"
						size="md"
						color="primary"
						sx={{ fontWeight: 600 }}
						onClick={() => {
							console.log(exhibitsData?.length, currentPlan?.description?.exhibits?.max);
							if (exhibitsData?.length !== undefined && currentPlan?.description?.exhibits?.max !== undefined) {
								if (exhibitsData?.length >= currentPlan?.description?.exhibits?.max) {
									setColor('danger');
									setTextAlert(t('errors.exhibitsLimit') || '');
									setOpen(true);
								} else {
									setIsModalOpen({ ...isModalOpen, create: true });
								}
							}
						}}>
						{t('exhibitsPage.addExhibit')}
					</Button>
					<ModalDeleteExhibit exhibits={exhibits} currentExhibit={currentExhibit} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setColor={setColor} setTextAlert={setTextAlert} />
					<ModalCreateExhibit exhibits={exhibits} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setColor={setColor} setTextAlert={setTextAlert} />
					<ModalUpdateExhibit exhibits={exhibits} currentExhibit={currentExhibit} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setColor={setColor} setTextAlert={setTextAlert} />
				</Box>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
					{isLoadingExhibits ? (
						<Loading />
					) : (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
							{exhibitsData?.map((exhibit: any) => {
								return <ExhibitCard setCurrentExhibit={setCurrentExhibit} exhibit={exhibit} exhibits={exhibits} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} key={exhibit?.id} />;
							})}
						</Box>
					)}
				</Box>
			</Box>
		</Layout>
	);
}
