import { useEffect, useState } from 'react';
import { getFromStorage, getToken } from '../app/utils';
import * as CryptoJS from 'crypto-js';
import { useGetMuseumMutation } from '../app/services/museum';

export const BtnRedirectMain = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => {
	const [museum, { data: dataMuseum, isLoading: isLoadingMuseum }] = useGetMuseumMutation();
	const [keyForLogin, setKeyForLogin] = useState('');

	useEffect(() => {
		museum();
	}, []);

	useEffect(() => {
		const refreshToken = getToken();
		if (refreshToken) {
			const user = getFromStorage();

			const cryptoRefreshKey = CryptoJS.AES.encrypt(
				JSON.stringify({
					refreshToken,
					user,
				}),
				process.env.REACT_APP_SECRET_KEY_REF || '',
			).toString();

			setKeyForLogin(encodeURIComponent(cryptoRefreshKey));
		}
	}, []);

	return (
		<>
			<a style={style} href={`${process.env.REACT_APP_MAIN_URL}/authed?museum=${dataMuseum?.url}&u=${keyForLogin}`}>
				{children}
			</a>
		</>
	);
};
