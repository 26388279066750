import { Route, Routes } from 'react-router-dom';
import Login from './features/auth/Login';
import { NotFound } from './pages/NotFound';
import { PrivateOutlet } from './utils/PrivateOutlet';
import Info from './pages/Info';
import Settings from './pages/Settings';
import Register from './features/auth/Register';
import Exhibits from './pages/Exhibits';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { Experimental_CssVarsProvider as MaterialCssVarsProvider, THEME_ID, experimental_extendTheme as materialExtendTheme } from '@mui/material/styles';
import Excursions from './pages/Excursions';
import Guides from './pages/Guides';
import Booking from './pages/Booking';
import { PrivateAdminOutlet } from './utils/PrivateAdminOutlet';
import Museums from './pages/Museums';
import Upgrade from './pages/Upgrade';
import ForgotPassword from './features/auth/ForgotPwd';
import ResetPassword from './features/auth/ResetPassword';
import { Halls } from './pages/Halls';
import Events from './pages/Events';
import { useEffect, useState } from 'react';
import { getToken } from './app/utils';
import { io } from 'socket.io-client';
import { Alert } from './utils/Alert';

const materialTheme = materialExtendTheme();

function App() {
	const socket = io(process.env.REACT_APP_API_SERVER || 'http://localhost:5000', {
		autoConnect: true,
		auth: {
			token: getToken(),
		},
	});

	const [open2, setOpen2] = useState(false);
	const [textError, setTextError] = useState('');
	const [color, setColor] = useState('success');

	useEffect(() => {
		if (getToken()) {
			socket.on('connect', () => {
				console.log('connected');
			});

			socket.on('notification', data => {
				setColor('primarySocket');
				setTextError(data.message);
				setOpen2(true);
			});
		}
	}, []);

	return (
		<div className="App">
			<Alert open={open2} setOpen={setOpen2} text={textError} color={color as 'success' | 'error' | 'warning' | 'info' | 'primary' | 'secondary' | 'neutral' | 'primarySocket' | undefined} />
			<MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
				<JoyCssVarsProvider>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password" element={<ResetPassword />} />
						<Route element={<PrivateOutlet />}>
							<Route path="/" element={<Info />} index />
							<Route path="settings" element={<Settings />} />
							<Route path="exhibits" element={<Exhibits />} />
							<Route path="excursions" element={<Excursions />} />
							<Route path="events" element={<Events />} />
							<Route path="guides" element={<Guides />} />
							<Route path="booking" element={<Booking />} />
							<Route path="upgrade" element={<Upgrade />} />
							<Route path="halls" element={<Halls />} />
						</Route>
						<Route element={<PrivateAdminOutlet />}>
							<Route path="/museums" element={<Museums />} index />
						</Route>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</JoyCssVarsProvider>
			</MaterialCssVarsProvider>
		</div>
	);
}

export default App;
