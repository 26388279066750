import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { sidebarMenu } from '../../utils';

export type secondBar = {
	key: string;
	name: string;
	icon: string;
	isAdmin?: boolean;
};

export type firstBar = {
	key: string;
	name: string;
	icon: string;
	subMenu: secondBar[] | [];
};

type SideBars = {
	firstBar: firstBar;
	secondBar: secondBar;
};

const slice = createSlice({
	name: 'sidebars',
	initialState: { firstBar: sidebarMenu[0], secondBar: sidebarMenu[0].subMenu[0] } as SideBars,
	reducers: {
		setFirstBar: (state, { payload: firstBar }: PayloadAction<firstBar>) => {
			state.firstBar = firstBar;
		},
		setSecondBar: (state, { payload: secondBar }: PayloadAction<secondBar>) => {
			state.secondBar = secondBar;
		},
	},
});

export const { setFirstBar, setSecondBar } = slice.actions;

export default slice.reducer;

export const selectFirstBar = (state: RootState) => state.sidebars.firstBar;
export const selectSecondBar = (state: RootState) => state.sidebars.secondBar;
