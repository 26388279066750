import { Box, Input, Select, Option, Typography, Checkbox } from '@mui/joy';
import { DigitalClock, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';

export const TimeWork = ({
	time_work,
	setTimeWork,
}: {
	time_work: {
		mon: { start: string; end: string };
		tue: { start: string; end: string };
		wed: { start: string; end: string };
		thu: { start: string; end: string };
		fri: { start: string; end: string };
		sat: { start: string; end: string };
		sun: { start: string; end: string };
	};
	setTimeWork: React.Dispatch<
		React.SetStateAction<{
			mon: { start: string; end: string };
			tue: { start: string; end: string };
			wed: { start: string; end: string };
			thu: { start: string; end: string };
			fri: { start: string; end: string };
			sat: { start: string; end: string };
			sun: { start: string; end: string };
		}>
	>;
}) => {
	const { t } = useTranslation();

	const times = Array.from({ length: 24 }, (_, i) => {
		return Array.from({ length: 2 }, (_, j) => {
			return `${i.toString().length === 1 ? `0${i}` : `${i === 24 ? '00' : i}`}:${j * 30 === 0 ? '00' : j * 30}`;
		});
	});

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			{Object.keys(time_work).map(key => {
				return (
					<Box display="flex" flexDirection="row" alignItems="center" key={key}>
						<Box
							sx={{
								minWidth: 60,
							}}>
							{t('days.' + key)}
						</Box>
						<Box
							sx={{
								minWidth: 40,
							}}>
								<Checkbox
								// @ts-ignore
									checked={time_work[key].start !== '' && time_work[key].end !== ''}
									onChange={(e) => {
										if (!e.target.checked) {
											setTimeWork({
												...time_work,
												[key]: {
													start: '',
													end: '',
												},
											});
										} else {
											setTimeWork({
												...time_work,
												[key]: {
													start: '09:00',
													end: '18:00',
												},
											});
										}
									}}
								/>
						</Box>
						<Box
							sx={{
								minWidth: 120,
							}}>
							{key && (
								<Select
									value={
										// @ts-ignore
										time_work[key].start
									}
									sx={{ mx: 2, mb: 1 }}
									onChange={(e: any, newValue: any) => {
										if (newValue) {
											setTimeWork({
												...time_work,
												[key]: {
													// @ts-ignore
													...time_work[key],
													start: newValue,
												},
											});
										}
									}}>
									{
										// @ts-ignore
										times.map((time, index) => {
											return time.map((t, i) => {
												return (
													<Option value={t} key={t}>
														{t}
													</Option>
												);
											});
										})
									}
								</Select>
							)}
						</Box>
						-
						<Box
							sx={{
								minWidth: 120,
							}}>
							{key && (
								<Select
									value={
										// @ts-ignore
										time_work[key].end
									}
									sx={{ mx: 2, mb: 1 }}
									onChange={(e: any, newValue: any) => {
										if (newValue) {
											setTimeWork({
												...time_work,
												[key]: {
													// @ts-ignore
													...time_work[key],
													end: newValue,
												},
											});
										}
									}}>
									{
										// @ts-ignore
										times.map((time, index) => {
											return time.map((t, i) => {
												return (
													<Option value={t} key={t}>
														{t}
													</Option>
												);
											});
										})
									}
								</Select>
							)}
						</Box>
					</Box>
				);
			})}
		</Box>
	);
};
