import JoySnackbar from '../features/components/JoySnackbar';
import * as Toast from '@radix-ui/react-toast';
import Box from '@mui/joy/Box';
import { useEffect } from 'react';

export const Alert = (props: any) => {
	const myProps = { ...props };

	if (myProps?.color === 'primarySocket') {
		myProps.color = 'primary';
	}

	useEffect(() => {
		if (props?.color === 'primarySocket' && props?.open) {

			const newAudio = new Audio('/msg_sound.mp3');
			newAudio.play();
		}
	}, [props?.open]);

	return (
		<>
			<audio
				id="sound"
				controls
				autoPlay={false}
				style={{
					position: 'absolute',
					top: '-1000000000px',
					left: '-1000000000px',
				}}>
				<source src="/msg_sound.mp3" type="audio/mpeg" />
			</audio>
			<Toast.Provider swipeDirection="up" duration={3000} swipeThreshold={1}>
				<JoySnackbar open={props.open} onOpenChange={props.setOpen} color={myProps?.color} variant="soft">
					{props.text}
				</JoySnackbar>
				<Box
					component={Toast.Viewport}
					sx={{
						'--viewport-padding': '25px',
						position: 'fixed',
						top: 0,
						left: 0,
						transform: 'translateX(50vw) translateX(-50%)',
						// maxidth: '370px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: 'var(--viewport-padding)',
						gap: '10px',
						maxWidth: '100vw',
						margin: 0,
						listStyle: 'none',
						zIndex: 2147483647,
						outline: 'none',
					}}
				/>
			</Toast.Provider>
		</>
	);
};
