import { Box, Button, CircularProgress, FormControl, FormLabel, Input, Modal, ModalClose, Sheet, Stack, Textarea, Typography, Select as SelectJoy, Option, Checkbox as CheckboxJoy } from '@mui/joy';
import Layout from '../features/components/Layout';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Add from '@mui/icons-material/Add';
import { Alert } from '../utils/Alert';
import ImagesUploader from '../utils/ImagesUploader';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetHallsMutation } from '../app/services/halls';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItemText, MenuItem, Select, Checkbox } from '@mui/material';
import { useGuidesMutation } from '../app/services/guides';
import { useAddEventMutation, useDelEventMutation, useEditEventMutation, useEventsMutation, useGetEventMutation, useUpdateImgEventMutation } from '../app/services/events';
import { CustomTable } from '../features/components/CustomTable';
import { Loading } from '../utils/Loading';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const ModalCreateEvent = ({ events, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { events: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [createEvent, { isSuccess: createIsSuccess, isLoading: createIsLoading }] = useAddEventMutation();

	const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
	const [price, setPrice] = useState(0);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [countPersonTo, setCountPersonTo] = useState(10);
	const [countDescription, setCountDescription] = useState(0);
	const requestInput = useRef<any>(null);

	const { t } = useTranslation();

	useEffect(() => {
		if (createIsSuccess) {
			events();
			setTextAlert(t('success.date_added'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, create: false });
			setSelectedFile(null);
		}
	}, [createIsSuccess]);

	const handleUpload = (data: any) => {
		if (selectedFile) {
			const formData = new FormData();

			for (let i = 0; i < selectedFile.length; i++) {
				// @ts-ignore
				formData.append('files[]', selectedFile[i], selectedFile[i].name);
			}

			Object.keys(data).forEach(key => {
				// @ts-ignore
				if (key === 'tags') {
					// if , in string
					if (data[key].includes(',')) {
						// @ts-ignore
						data[key].split(',').forEach((tag: string) => {
							formData.append('tags', tag);
						});
					} else {
						// @ts-ignore
						formData.append('tags', [data[key]]);
					}

					return;
				}

				if (key === 'halls') {
					data[key].forEach((hall: any) => {
						formData.append('halls', hall);
					});

					return;
				}

				if (key === 'guides') {
					data[key].forEach((guide: any) => {
						formData.append('guides', guide);
					});

					return;
				}

				if (key === 'date') {
					formData.append('date', dayjs(data[key]).format('YYYY-MM-DD HH:mm'));
					return;
				}

				data[key] && formData.append(key, data[key]);
			});

			createEvent(formData);
		}
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['create']} onClose={() => setIsModalOpen({ ...isModalOpen, create: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('eventPage.addEvent')}
				</Typography>
				<form
					autoComplete="off"
					onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();
						console.log(event.currentTarget?.adult?.checked);
						const data = {
							// @ts-ignore
							name: event.currentTarget?.name?.value,
							description: event.currentTarget?.description?.value,
							status: event.currentTarget?.status[1].value,
							price,
							max_persons: countPersonTo,
							date: startDate,
							request: event.currentTarget?.request?.checked ? 1 : 0,
							person_age: event.currentTarget?.adult?.checked ? 1 : 0,
							tags: event.currentTarget?.tags?.value,
						};

						// if (selected.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('eventPage.hallAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						// if (selectedGuides.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('eventPage.guidesAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						if (data.name.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('eventPage.eventName') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('eventPage.eventInfo') + ') - 3000');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (countPersonTo < 1) {
							setTextAlert(t('errors.max_count_person'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (price < 0) {
							setTextAlert(t('errors.price'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate === null) {
							setTextAlert(t('errors.empty_field') + ' (' + t('eventPage.date') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate < new Date()) {
							setTextAlert(t('errors.date_end_current'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (selectedFile?.length === 0 || selectedFile === null) {
							setTextAlert(t('errors.empty_field') + ' (' + t('eventPage.photo') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('eventPage.eventInfo') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						handleUpload(data);
					}}>
					<Stack spacing={2}>
						<FormControl>
							<FormLabel>{t('eventPage.eventName')}</FormLabel>
							<Input autoComplete="off" autoFocus required name="name" />
						</FormControl>
						<FormControl>
							<FormLabel>{t('eventPage.eventInfo')}</FormLabel>
							<Typography
								sx={{
									mb: 1,
									//@ts-ignore
									color: countDescription > 3000 ? 'red' : 'inherit',
								}}>
								{countDescription}/3000
							</Typography>
							<Textarea
								required
								maxRows={4}
								minRows={2}
								name="description"
								onChange={e => {
									setCountDescription(e.target.value.length);
								}}
							/>
						</FormControl>

						<FormLabel>{t('eventPage.status')}</FormLabel>
						<SelectJoy defaultValue={0} name="status">
							{
								//@ts-ignore
								t('eventPage.statuses', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</SelectJoy>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormLabel>{t('eventPage.for_adult')}</FormLabel>
							<Checkbox name="adult" />
						</Stack>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormLabel>{t('eventPage.request')}</FormLabel>
							<Checkbox name="request" ref={requestInput} />
						</Stack>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormControl>
								<FormLabel>{t('eventPage.person_max')}</FormLabel>
								<Input type="number" required name="person_to" value={countPersonTo} onChange={e => setCountPersonTo(parseInt(e.target.value))} />
							</FormControl>
						</Stack>

						<FormControl>
							<FormLabel>{t('eventPage.price')}</FormLabel>
							<Stack direction="row" spacing={2} alignItems="center">
								<Input
									type="number"
									value={price}
									onChange={e => {
										// @ts-ignore
										setPrice(e.target.value);
										// @ts-ignore
										if (parseFloat(e.target.value) > 0) {
											console.log(requestInput.current.children[0]);
											// requestInput.current.children[1].click();
											requestInput.current.children[0].checked === false && requestInput.current.children[0].click();
										} else {
											requestInput.current.children[0].checked === true && requestInput.current.children[0].click();

										}
									}}
								/>
							</Stack>
						</FormControl>

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack direction="row" spacing={2} alignItems="center" mt={2}>
								<DateTimePicker
									label={t('eventPage.start_date')}
									format="DD.MM.YYYY HH:mm"
									viewRenderers={{
										hours: renderTimeViewClock,
										minutes: renderTimeViewClock,
										seconds: renderTimeViewClock,
									}}
									ampm={false}
									sx={{
										'& .MuiInputBase-root': {
											borderRadius: '10px !important',
											borderColor: 'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF)) !important',
											color: 'var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-800, #25252D)) !important',
											height: 'var(--Input-minHeight) !important',
											'&:hover': {
												borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder, var(--joy-palette-neutral-300, #B9B9C6)) !important',
												color: 'var(--joy-palette-neutral-outlinedHoverColor, var(--joy-palette-neutral-900, #131318)) !important',
											},
										},
									}}
									// value={dayjs(startDate)}
									onChange={(e, t) => {
										// @ts-ignore
										// console.log(new Date(e));
										// @ts-ignore
										setStartDate(new Date(e));
									}}
								/>
							</Stack>
						</LocalizationProvider>

						<FormControl>
							<FormLabel>{t('eventPage.tags')}</FormLabel>
							<Textarea maxRows={4} minRows={2} name="tags" placeholder={t('eventPage.inputTags') || ''} />
						</FormControl>

						<ImagesUploader setSelectedFile={setSelectedFile} />

						<Button type="submit" disabled={createIsLoading}>
							{createIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('eventPage.addEvent')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalUpdateEvent = ({ events, currentEvent, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { events: () => void; currentEvent: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [updateEvent, { isSuccess: createIsSuccess, isLoading: createIsLoading }] = useEditEventMutation();
	const [updateImage, dataUplImg] = useUpdateImgEventMutation();

	const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
	const [price, setPrice] = useState(0);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [countPersonTo, setCountPersonTo] = useState(10);
	const [tmp, setTmp] = useState<any>();
	const [countDescription, setCountDescription] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		if (dataUplImg.isSuccess) {
			updateEvent({
				id: currentEvent?.id,
				data: tmp,
			});
		}
	}, [dataUplImg.isSuccess, tmp]);

	useEffect(() => {
		if (currentEvent?.id) {
			setPrice(currentEvent?.price);
			setCountPersonTo(currentEvent.max_persons);
			setStartDate(new Date(currentEvent.date));
			setCountDescription(currentEvent.description.length);
		}
	}, [isModalOpen.update]);

	useEffect(() => {
		if (createIsSuccess) {
			setTextAlert(t('success.date_updated'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, update: false });
			events();
			setSelectedFile(null);
		}
	}, [createIsSuccess]);

	const handleUpload = (data: any) => {
		if (selectedFile) {
			const formData = new FormData();

			for (let i = 0; i < selectedFile.length; i++) {
				// @ts-ignore
				formData.append('files[]', selectedFile[i], selectedFile[i].name);
			}

			Object.keys(data).forEach(key => {
				// @ts-ignore
				if (key === 'tags') {
					// if , in string
					if (data[key].includes(',')) {
						// @ts-ignore
						data[key].split(',').forEach((tag: string) => {
							formData.append('tags', tag);
						});
					} else {
						// @ts-ignore
						formData.append('tags', [data[key]]);
					}

					return;
				}

				data[key] && formData.append(key, data[key]);
			});

			updateEvent(formData);
		}
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['update']} onClose={() => setIsModalOpen({ ...isModalOpen, update: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('eventPage.update')}
				</Typography>
				<form
					autoComplete="off"
					onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();
						console.log(startDate)
						console.log(dayjs(startDate).format('YYYY-MM-DD HH:mm'))
						const data = {
							// @ts-ignore
							name: event.currentTarget?.name?.value,
							description: event.currentTarget?.description?.value,
							status: event.currentTarget?.status[1].value,
							price,
							max_persons: countPersonTo,
							date: dayjs(startDate).format('YYYY-MM-DD HH:mm'),
							request: event.currentTarget?.request.checked ? 1 : 0,
							person_age: event.currentTarget?.adult.checked ? 1 : 0,
							tags: event.currentTarget?.tags?.value,
						};

						// if (selected.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('eventPage.hallAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						// if (selectedGuides.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('eventPage.guidesAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						if (data.name.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('eventPage.eventName') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('eventPage.eventInfo') + ') - 3000');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (countPersonTo < 0) {
							setTextAlert(t('errors.max_count_person'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (price < 0) {
							setTextAlert(t('errors.price'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate === null) {
							setTextAlert(t('errors.empty_field') + ' (' + t('eventPage.date') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate < new Date()) {
							setTextAlert(t('errors.date_end_current'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (selectedFile && selectedFile !== null) {
							updateImage({
								id: currentEvent?.id,
								data: selectedFile,
							});

							setTmp(data);
						} else {
							updateEvent({
								id: currentEvent?.id,
								data,
							});
						}
						// handleUpload(data);
					}}>
					<Stack spacing={2}>
						<FormControl>
							<FormLabel>{t('eventPage.eventName')}</FormLabel>
							<Input autoFocus required name="name" defaultValue={currentEvent?.name} />
						</FormControl>
						<FormControl>
							<FormLabel>{t('eventPage.eventInfo')}</FormLabel>
							<Typography
								sx={{
									mb: 1,
									//@ts-ignore
									color: countDescription > 3000 ? 'red' : 'inherit',
								}}>
								{countDescription}/3000
							</Typography>
							<Textarea
								required
								maxRows={4}
								minRows={2}
								name="description"
								defaultValue={currentEvent?.description}
								onChange={e => {
									setCountDescription(e.target.value.length);
								}}
							/>
						</FormControl>

						<FormLabel>{t('eventPage.status')}</FormLabel>
						<SelectJoy defaultValue={currentEvent?.status} name="status">
							{
								//@ts-ignore
								t('eventPage.statuses', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</SelectJoy>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormLabel>{t('eventPage.for_adult')}</FormLabel>
							<Checkbox name="adult" defaultChecked={currentEvent?.person_age === 1 ? true : false} />
						</Stack>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormLabel>{t('eventPage.request')}</FormLabel>
							<Checkbox name="request" defaultChecked={currentEvent?.request === 1 ? true : false} />
						</Stack>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormControl>
								<FormLabel>{t('eventPage.person_max')}</FormLabel>
								<Input type="number" required name="person_to" value={countPersonTo} onChange={e => setCountPersonTo(parseInt(e.target.value))} />
							</FormControl>
						</Stack>

						<FormControl>
							<FormLabel>{t('eventPage.price')}</FormLabel>
							<Stack direction="row" spacing={2} alignItems="center">
								<Input
									type="number"
									value={price}
									onChange={
										// @ts-ignore
										e => setPrice(e.target.value)
									}
								/>
							</Stack>
						</FormControl>

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack direction="row" spacing={2} alignItems="center" mt={2}>
								<DateTimePicker
									label={t('eventPage.start_date')}
									format="DD.MM.YYYY HH:mm"
									viewRenderers={{
										hours: renderTimeViewClock,
										minutes: renderTimeViewClock,
										seconds: renderTimeViewClock,
									}}
									ampm={false}
									sx={{
										'& .MuiInputBase-root': {
											borderRadius: '10px !important',
											borderColor: 'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF)) !important',
											color: 'var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-800, #25252D)) !important',
											height: 'var(--Input-minHeight) !important',
											'&:hover': {
												borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder, var(--joy-palette-neutral-300, #B9B9C6)) !important',
												color: 'var(--joy-palette-neutral-outlinedHoverColor, var(--joy-palette-neutral-900, #131318)) !important',
											},
										},
									}}
									value={dayjs(startDate)}
									onChange={(e, t) => {
										// alert(e)	
										// @ts-ignore
										setStartDate(dayjs(e));
									}}
								/>
							</Stack>
						</LocalizationProvider>

						<FormControl>
							<FormLabel>{t('eventPage.tags')}</FormLabel>
							<Textarea
								maxRows={4}
								minRows={2}
								name="tags"
								placeholder={t('eventPage.inputTags') || ''}
								defaultValue={
									// @ts-ignore
									currentEvent?.tags?.map((tag: string) => tag.name).join(', ')
								}
							/>
						</FormControl>

						<ImagesUploader setSelectedFile={setSelectedFile} />

						<Button type="submit" disabled={createIsLoading}>
							{createIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('eventPage.update')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalDeleteEvent = ({ events, currentEvent, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { events: () => void; currentEvent: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [getHalls, { data: hallsData, isSuccess: getHallsSuccess }] = useGetHallsMutation();
	const [deleteEvent, { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess }] = useDelEventMutation();
	const { t } = useTranslation();

	useEffect(() => {
		if (deleteIsSuccess) {
			events();
			setTextAlert(t('success.date_deleted'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, delete: false });
		}
	}, [deleteIsSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['delete']} onClose={() => setIsModalOpen({ ...isModalOpen, delete: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('eventPage.delete') + ' ' + currentEvent?.name + '?'}
				</Typography>

				<Button
					type="button"
					disabled={deleteIsLoading}
					onClick={() => {
						deleteEvent(currentEvent?.id);
					}}>
					{deleteIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('eventPage.delete')}
				</Button>
			</Sheet>
		</Modal>
	);
};

const ModalDeleteAllEvent = ({ events, currentEvent, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { events: () => void; currentEvent: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [getHalls, { data: hallsData, isSuccess: getHallsSuccess }] = useGetHallsMutation();
	const [deleteEvent, { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess }] = useDelEventMutation();
	const { t } = useTranslation();

	const deleteSelected = (ids: any) => {
		ids.forEach((id: any) => {
			(async () => {
				await deleteEvent(id);
			})();
		});
	};

	useEffect(() => {
		if (deleteIsSuccess) {
			events();
			setTextAlert(t('success.date_deleted'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, deleteAll: false });
		}
	}, [deleteIsSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['deleteAll']} onClose={() => setIsModalOpen({ ...isModalOpen, deleteAll: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('settingsPage.delete') + ' вибрані екскурсії?'}
				</Typography>

				<Button
					type="button"
					disabled={deleteIsLoading}
					onClick={() => {
						deleteSelected(currentEvent);
					}}>
					{deleteIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('settingsPage.delete')}
				</Button>
			</Sheet>
		</Modal>
	);
};

export default function Events() {
	const [isModalOpen, setIsModalOpen] = useState<any>({});
	const [events, { isLoading, isError, data }] = useEventsMutation();
	const [getEvent, { isLoading: getIsLoading, isSuccess: getIsSuccess, data: eventData }] = useGetEventMutation();

	const [currentEvent, setCurrentEvent] = useState<any>({});
	const [status, setStatus] = useState<any>(0);
	const user_data = useSelector(selectCurrentUser);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

	const { t } = useTranslation();
	const navigator = useNavigate();

	useEffect(() => {
		events();

		if ([2].includes(parseInt(user_data?.museum?.type))) {
			navigator('/settings');
		}
	}, [events]);

	useEffect(() => {
		if (getIsSuccess) {
			setCurrentEvent(eventData);
			if (status === 1) {
				setIsModalOpen({ ...isModalOpen, update: true });
			}
			if (status === 2) {
				setIsModalOpen({ ...isModalOpen, delete: true });
			}
		}
	}, [getIsSuccess, status]);

	useEffect(() => {
		if (status === 3) {
			setIsModalOpen({ ...isModalOpen, deleteAll: true });
			setStatus(0);
		}
	}, [status]);

	const DeleteActions = ({ id }: { id: number }) => {
		return (
			<Box sx={{ display: 'flex', gap: 1 }}>
				<Button
					size="sm"
					variant="plain"
					color="info"
					data-action
					onClick={() => {
						setStatus(1);
						getEvent(id);
					}}>
					<i className="fa-solid fa-edit"></i>
				</Button>
				<Button
					size="sm"
					variant="soft"
					color="danger"
					data-action
					onClick={() => {
						setStatus(2);
						getEvent(id);
					}}>
					<i className="fa-solid fa-trash"></i>
				</Button>
			</Box>
		);
	};

	const columns = [
		{
			id: 'id',
			disablePadding: false,
			label: 'ID',
			numeric: false,
			width: '70px',
		},
		{
			id: 'name',
			disablePadding: false,
			label: t('eventPage.table.name'),
			numeric: false,
			width: '180px',
		},
		{
			id: 'date',
			disablePadding: false,
			label: t('eventPage.table.date'),
			numeric: false,
			width: '90px',
		},
		{
			id: 'price',
			disablePadding: false,
			label: t('eventPage.table.price'),
			numeric: false,
			width: '120px',
		},
		{
			id: 'years_limit',
			disablePadding: false,
			label: t('eventPage.table.years_limit'),
			numeric: false,
			width: '160px',
		},
		{
			id: 'status',
			disablePadding: false,
			label: t('eventPage.table.status'),
			numeric: false,
			width: '90px',
		},
	];

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<Box sx={{ width: '100%' }}>
				<ModalCreateEvent events={events} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />
				{currentEvent && <ModalUpdateEvent events={events} currentEvent={currentEvent} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />}
				{currentEvent && <ModalDeleteEvent events={events} currentEvent={currentEvent} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />}
				{currentEvent && <ModalDeleteAllEvent events={events} currentEvent={currentEvent} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />}
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2, mb: 2 }}>
					<h1>{t('events')}</h1>

					<Button startDecorator={<Add />} variant="solid" size="md" color="primary" sx={{ fontWeight: 600 }} onClick={() => setIsModalOpen({ ...isModalOpen, create: true })}>
						{t('eventPage.addEvent')}
					</Button>
				</Box>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
					{isLoading ? (
						<Loading />
					) : (
						<CustomTable
							title={t('eventPage.table.title')}
							rows={
								// @ts-ignore
								data?.length > 0
									? data?.map((item: any, index: number) => ({
											id: item.id,
											name: item.name,
											date: new Date(item.date).toLocaleDateString('uk-UA', {
												day: 'numeric',
												year: 'numeric',
												month: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
											}),
											price: item.price > 0 ? item.price + ' грн' : 'Безкоштовно',
											years_limit: item.person_age === 0 ? t('eventPage.table.years_limit_not') : t('eventPage.for_adult') || '',
											// @ts-ignore
											status: t('eventPage.statuses', { returnObjects: true })[item.status] || '',
									  }))
									: []
							}
							headCells={columns}
							Actions={DeleteActions}
							deleteSelected={selected => {
								setCurrentEvent(selected);
								setStatus(3);
							}}
						/>
					)}
				</Box>
			</Box>
		</Layout>
	);
}
