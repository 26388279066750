import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isTokenExpired } from '../app/utils';
import { useAuth } from '../hooks/useAuth';

export function PrivateAdminOutlet() {
	const location = useLocation();
    const auth = useAuth();

	return !isTokenExpired() && auth?.user?.role === 'admin'  ? <Outlet /> : <Navigate to="/settings" state={{ from: location }} />;
}
