import { Box, Table } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Tooltip from '@mui/joy/Tooltip';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HeadCell {
	disablePadding: boolean;
	id: string;
	label: string;
	numeric: boolean;
	isStatus?: boolean;
	isDate?: boolean;
	width?: string;
}

interface Props {
	title: string;
	rows: any;
	headCells: HeadCell[];
	Actions?: React.FC<any>;
	deleteSelected: (ids: string[]) => void;
}

export const CustomTable = ({ title, rows, headCells, Actions, deleteSelected }: Props) => {
	const { t } = useTranslation();

	function labelDisplayedRows({ from, to, count }: { from: number; to: number; count: number }) {
		return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
	}

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	type Order = 'asc' | 'desc';

	function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
	}

	function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
		const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	}

	interface EnhancedTableProps {
		numSelected: number;
		onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
		onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
		order: Order;
		orderBy: string;
		rowCount: number;
	}

	function EnhancedTableHead(props: EnhancedTableProps) {
		const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
		const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

		return (
			<thead>
				<tr>
					<th>
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							slotProps={{
								input: {
									'aria-label': 'select all desserts',
								},
							}}
							sx={{ verticalAlign: 'sub' }}
						/>
					</th>
					{headCells.map(headCell => {
						const active = orderBy === headCell.id;
						return (
							<th
								key={headCell.id}
								aria-sort={active ? ({ asc: 'ascending', desc: 'descending' } as const)[order] : undefined}
								style={{
									width: headCell.width ? headCell.width : 'auto',
									minWidth: headCell.width ? 'auto' : headCell.width,
								}}>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link
									underline="none"
									color="neutral"
									textColor={active ? 'primary.plainColor' : undefined}
									component="button"
									onClick={createSortHandler(headCell.id)}
									fontWeight="lg"
									startDecorator={headCell.numeric ? <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} /> : null}
									endDecorator={!headCell.numeric ? <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} /> : null}
									sx={{
										'& svg': {
											transition: '0.2s',
											transform: active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
										},
										'&:hover': { '& svg': { opacity: 1 } },
									}}>
									{headCell.label}
									{active ? (
										<Box component="span" sx={visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</Box>
									) : null}
								</Link>
							</th>
						);
					})}
					<th
						colSpan={2}
						style={{
							width: '120px',
							minWidth: '120px',
						}}>
						{t('table.actions') || 'Actions'}
					</th>
				</tr>
			</thead>
		);
	}

	interface EnhancedTableToolbarProps {
		numSelected: number;
		selected: string[];
	}

	function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
		const { numSelected, selected } = props;

		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					py: 1,
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: 'background.level1',
					}),
					borderTopLeftRadius: 'var(--unstable_actionRadius)',
					borderTopRightRadius: 'var(--unstable_actionRadius)',
				}}>
				{numSelected > 0 ? (
					<Typography sx={{ flex: '1 1 100%' }} component="div">
						{numSelected} {t('table.selected') || 'selected'}
					</Typography>
				) : (
					<Typography level="h6" sx={{ flex: '1 1 100%' }} id="tableTitle" component="div">
						{title}
					</Typography>
				)}

				{numSelected > 0 && Actions ? (
					<Tooltip title={t('table.delete') || 'Delete'}>
						<IconButton
							size="sm"
							color="danger"
							variant="soft"
							sx={{mr: 15}}
							onClick={() => {
								deleteSelected(selected);
							}}>
							<i className="fa-solid fa-trash"></i>
						</IconButton>
					</Tooltip>
				) : (
					<></>
					// <Tooltip title="Filter list">
					// 	<IconButton size="sm" variant="outlined" color="neutral">
					// 		<FilterListIcon />
					// 	</IconButton>
					// </Tooltip>
				)}
			</Box>
		);
	}

	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');
	const [selected, setSelected] = React.useState<string[]>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			// @ts-ignore
			const newSelected = rows.map(n => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
		setRowsPerPage(parseInt(newValue!.toString(), 10));
		setPage(0);
	};

	const getLabelDisplayedRowsTo = () => {
		if (rows.length === -1) {
			return (page + 1) * rowsPerPage;
		}
		return rowsPerPage === -1 ? rows.length : Math.min(rows.length, (page + 1) * rowsPerPage);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	return (
		<Sheet variant="outlined" sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm', overflow: 'auto' }}>
			<EnhancedTableToolbar numSelected={selected.length} selected={selected} />
			<Table
				aria-labelledby="tableTitle"
				hoverRow
				sx={{
					'--TableCell-headBackground': 'transparent',
					'--TableCell-selectedBackground': theme => theme.vars.palette.info.softBg,
					'& thead th': {
						position: 'sticky',
						left: 0,
					},
					'& thead th:nth-child(1)': {
						width: '40px',
					},
					'& thead th:nth-child(2)': {
						width: 'auto',
					},
					'& tr > *:nth-child(n+3)': { textAlign: 'left' },
				}}>
				<EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
				<tbody>
					{
						// @ts-ignore
						stableSort(rows, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								// @ts-ignore
								const isItemSelected = isSelected(row.id);
								const labelId = `enhanced-table-checkbox-${index}`;

								return (
									<tr
										onClick={event => {
											// @ts-ignore
											if (event.target.dataset?.action !== 'true') handleClick(event, row.id);
										}}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										// selected={isItemSelected}
										style={
											isItemSelected
												? ({
														'--TableCell-dataBackground': 'var(--TableCell-selectedBackground)',
														'--TableCell-headBackground': 'var(--TableCell-selectedBackground)',
												  } as React.CSSProperties)
												: {}
										}>
										<th scope="row">
											<Checkbox
												checked={isItemSelected}
												slotProps={{
													input: {
														'aria-labelledby': labelId,
													},
												}}
												sx={{ verticalAlign: 'top' }}
											/>
										</th>
										<th id={labelId} scope="row">
											{row.id}
										</th>
										{
											// @ts-ignore
											Object.keys(row).map((key, index) => {
												if (key === 'id') return null;

												if (headCells[index]?.isStatus) {
													return (
														<td key={index}>
															{row.status === -1 ? (
																<i
																	className="fa-solid fa-times"
																	style={{
																		color: '#e55353',
																		fontSize: '1.2rem',
																	}}></i>
															) : row.status === 1 ? (
																<i
																	className="fa-solid fa-check"
																	style={{
																		color: '#4caf50',
																		fontSize: '1.2rem',
																	}}></i>
															) : (
																<i
																	className="fa-regular fa-clock"
																	style={{
																		color: '#ffc145',
																		fontSize: '1.2rem',
																	}}></i>
															)}
														</td>
													);
												}

												if (headCells[index]?.isDate) {
													return <td key={index}>{new Date(row[key]).toLocaleString('ru-RU')}</td>;
												}

												return (
													<td key={index} style={{ wordBreak: 'break-all' }}>
														{
															// @ts-ignore
															typeof row[key] === 'object'
																? // @ts-ignore
																  JSON.stringify(row[key])
																: // @ts-ignore
																  row[key]
														}
													</td>
												);
											})
										}
										<td colSpan={2}>
											{
												// @ts-ignore
												Actions && <Actions id={row.id} />
											}
										</td>
									</tr>
								);
							})
					}
					{emptyRows > 0 && (
						<tr
							style={
								{
									height: `calc(${emptyRows} * 40px)`,
									'--TableRow-hoverBackground': 'transparent',
								} as React.CSSProperties
							}>
							<td colSpan={headCells.length + 3} />
						</tr>
					)}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={headCells.length + 3}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 2,
									justifyContent: 'flex-end',
								}}>
								<FormControl orientation="horizontal" size="sm">
									<FormLabel>{t('table.show') || ''}:</FormLabel>
									<Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
										<Option value={25}>25</Option>
										<Option value={50}>50</Option>
										<Option value={100}>100</Option>
										{rows.length > 50 && <Option value={rows.length}>{rows.length}</Option>}
									</Select>
								</FormControl>
								<Typography textAlign="center" sx={{ minWidth: 80 }}>
									{labelDisplayedRows({
										from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
										to: getLabelDisplayedRowsTo(),
										count: rows.length === -1 ? -1 : rows.length,
									})}
								</Typography>
								<Box sx={{ display: 'flex', gap: 1 }}>
									<IconButton size="sm" color="neutral" variant="outlined" disabled={page === 0} onClick={() => handleChangePage(page - 1)} sx={{ bgcolor: 'background.surface' }}>
										<KeyboardArrowLeftIcon />
									</IconButton>
									<IconButton size="sm" color="neutral" variant="outlined" disabled={rows.length !== -1 ? page >= Math.ceil(rows.length / rowsPerPage) - 1 : false} onClick={() => handleChangePage(page + 1)} sx={{ bgcolor: 'background.surface' }}>
										<KeyboardArrowRightIcon />
									</IconButton>
								</Box>
							</Box>
						</td>
					</tr>
				</tfoot>
			</Table>
		</Sheet>
	);
};
