import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';

export interface Guide {
	id: number;
	name: string;
	phone: string;
	halls: number[];
}

export interface UpdateSettings {
	id: number;
	key: string;
	value: string;
	userId: number;
}

export interface Request {
	userId: number;
}

export const api = createApi({
	baseQuery: customBaseQuery,
	endpoints: builder => ({
		guides: builder.mutation<Guide[], void>({
			query: () => ({
				url: `/guides`,
				method: 'GET',
			}),
		}),
		delGuide: builder.mutation({
			query: id => ({
				url: `/guides/${id}`,
				method: 'DELETE',
			}),
		}),
		getGuide: builder.mutation({
			query: id => ({
				url: `/guides/${id}`,
				method: 'GET',
			}),
		}),
		editGuide: builder.mutation({
			query: ({ id, data }) => ({
				url: `/guides/${id}`,
				method: 'PATCH',
				body: data,
			}),
		}),
		addGuide: builder.mutation({
			query: data => ({
				url: `/guides`,
				method: 'POST',
				body: data,
			}),
		}),
	}),
});

export const { useAddGuideMutation, useDelGuideMutation, useEditGuideMutation, useGetGuideMutation, useGuidesMutation } = api;
