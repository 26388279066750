import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton from '@mui/joy/IconButton';
import Sheet from '@mui/joy/Sheet';
import ColorSchemeToggle from '../../utils/ColorSchemaToggle';
import { toggleSidebar } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Badge, Box, List, ListItem, Menu, MenuItem, Typography } from '@mui/joy';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LinkIcon from '@mui/icons-material/Link';

export default function Header({ notifications }: any) {
	const { i18n } = useTranslation();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [anchorElNotif, setAnchorElNotif] = React.useState<null | HTMLElement>(null);
	const openNotif = Boolean(anchorElNotif);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClickNotif = (event: React.MouseEvent<HTMLButtonElement>) => {
		openNotif ? setAnchorElNotif(null) : setAnchorElNotif(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Sheet
			sx={{
				display: { xs: 'flex', md: 'none' },
				alignItems: 'center',
				position: 'fixed',
				top: 0,
				width: '100vw',
				height: 'var(--Header-height)',
				zIndex: 9995,
				py: 1,
				px: 2,
				gap: 1,
				boxShadow: 'sm',
			}}>
			<GlobalStyles
				styles={(theme: any) => ({
					':root': {
						'--Header-height': '52px',
						[theme.breakpoints.up('md')]: {
							'--Header-height': '0px',
						},
					},
				})}
			/>
			<IconButton onClick={() => toggleSidebar()} variant="outlined" color="neutral" size="sm">
				<i data-feather="menu" />
			</IconButton>
			<Badge badgeContent={notifications?.filter((notification: any) => notification?.isRead === false).length} color="danger">
				<IconButton sx={{ ml: '5px' }} id="basic-demo-button" aria-controls={openNotif ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={openNotif ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClickNotif}>
					<NotificationsIcon />
				</IconButton>
			</Badge>
			<IconButton sx={{ ml: '5px' }} id="basic-demo-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClick}>
				<LanguageIcon />
			</IconButton>
			<Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} aria-labelledby="basic-demo-button">
				<MenuItem
					onClick={() => {
						i18n.changeLanguage('en');
					}}>
					English
				</MenuItem>
				<MenuItem
					onClick={() => {
						i18n.changeLanguage('uk');
					}}>
					Українська
				</MenuItem>
			</Menu>
			<List
				size={'sm'}
				variant="outlined"
				sx={{
					minWidth: '200px',
					maxWidth: {
						xs: 'calc(100% - 100px)',
						md: 'calc(100vw - 30px)',
					},
					maxHeight: 'calc(100vh - var(--Header-height) - 30px)',
					backgroundColor: 'white',
					zIndex: 9999999999999999999999999,
					borderRadius: 'sm',
					position: 'absolute',
					left: 0,
					top: 'calc(var(--Header-height) + 15px)',
					transform: 'translate(15%, 0)',
					display: openNotif ? 'block' : 'none',
					overflow: 'auto',
				}}>
				{notifications?.map((notification: any) => (
					<ListItem key={notification.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<IconButton
							variant="plain"
							color="neutral"
							size="sm"
							sx={{
								mr: 1,
							}}
							onClick={() => {
								if (notification?.link) {
									window.open(`${process.env.REACT_APP_MAIN_URL}/${notification.link}`, '_blank');
								}
							}}>
							{notification?.link ? <LinkIcon /> : null}
						</IconButton>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								gap: 1,
								flex: 1,
							}}>
							<Box>
								<Typography level="body2">{notification.title}</Typography>
								<Typography level="body3">{notification.message}</Typography>
							</Box>
							<Box>
								<Typography level="body3">
									{new Date(notification.createdAt).toLocaleDateString('uk-UA', {
										day: 'numeric',
										month: 'long',
										year: 'numeric',
										minute: 'numeric',
										hour: 'numeric',
									})}
								</Typography>
							</Box>
						</Box>
					</ListItem>
				))}
				{notifications?.length === 0 && <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No notifications</ListItem>}
			</List>
			<ColorSchemeToggle id={undefined} />
		</Sheet>
	);
}
