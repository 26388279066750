import { Box, Button, CircularProgress, FormControl, FormLabel, Input, Modal, ModalClose, Sheet, Stack, Textarea, Typography, Select as SelectJoy, Option, Checkbox as CheckboxJoy } from '@mui/joy';
import Layout from '../features/components/Layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Add from '@mui/icons-material/Add';
import { Alert } from '../utils/Alert';
import ImagesUploader from '../utils/ImagesUploader';
import { LocalizationProvider, DatePicker, YearCalendar, DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetHallsMutation } from '../app/services/halls';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItemText, MenuItem, Select, Checkbox } from '@mui/material';
import { useGuidesMutation } from '../app/services/guides';
import { useAddExcursionMutation, useDelExcursionMutation, useEditExcursionMutation, useExcursionsMutation, useGetExcursionMutation, useUpdateImgExcursionMutation } from '../app/services/excursions';
import { CustomTable } from '../features/components/CustomTable';
import { Loading } from '../utils/Loading';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from 'moment';

export const CustomCalendar = ({ currentValue, setValue, text, disabled = false }: any) => {
	const [open, setOpen] = useState(false);

	return (
		<Box
			sx={{
				width: '100%',
			}}>
			<DatePicker
				disabled={disabled}
				label={text}
				format="YYYY-MM-DD"
				sx={{
					'& .MuiInputBase-root': {
						borderRadius: '10px !important',
						borderColor: 'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF)) !important',
						color: 'var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-800, #25252D)) !important',
						height: 'var(--Input-minHeight) !important',
						'&:hover': {
							borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder, var(--joy-palette-neutral-300, #B9B9C6)) !important',
							color: 'var(--joy-palette-neutral-outlinedHoverColor, var(--joy-palette-neutral-900, #131318)) !important',
						},
					},
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: 'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF)) !important',
					},
					'& .MuiInputLabel-root': {
						color: 'var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-800, #25252D)) !important',
					},
				}}
				value={dayjs(currentValue)}
				onOpen={() => setOpen(true)}
				slots={{
					dialog: (props: any) => {
						return (
							<Modal
								{...props}
								open={open}
								onClose={() => setOpen(false)}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Calendar									
									value={{
										year: dayjs(currentValue).year(),
										month: dayjs(currentValue).month() + 1,
										day: dayjs(currentValue).date(),
									}}
									onChange={(e: any) => {
										setValue(
											moment()
												.year(e.year)
												.month(e.month - 1)
												.date(e.day)
												.format('YYYY-MM-DD'),
										);
										setOpen(false);
									}}
									shouldHighlightWeekends
								/>
							</Modal>
						);
					},
					popper: (props: any) => {
						return (
							<Modal
								{...props}
								open={open}
								onClose={() => setOpen(false)}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Calendar
									selectorStartingYear={1}
									value={{
										year: dayjs(currentValue).year(),
										month: dayjs(currentValue).month() + 1,
										day: dayjs(currentValue).date(),
									}}
									onChange={(e: any) => {
										setValue(
											moment()
												.year(e.year)
												.month(e.month - 1)
												.date(e.day)
												.format('YYYY-MM-DD'),
										);
										setOpen(false);
									}}
									shouldHighlightWeekends
								/>								
							</Modal>
						);
					},
				}}
				onClose={() => setOpen(false)}
			/>
		</Box>
	);
};

export const CustomCalendarYear = ({ currentValue, setValue, text, disabled = false }: any) => {
	const [open, setOpen] = useState(false);

	return (
		<Box
			sx={{
				width: '100%',
			}}>
			<DatePicker
				disabled={disabled}
				label={text}
				format="YYYY"
				sx={{
					'& .MuiInputBase-root': {
						borderRadius: '10px !important',
						borderColor: 'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF)) !important',
						color: 'var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-800, #25252D)) !important',
						height: 'var(--Input-minHeight) !important',
						'&:hover': {
							borderColor: 'var(--joy-palette-neutral-outlinedHoverBorder, var(--joy-palette-neutral-300, #B9B9C6)) !important',
							color: 'var(--joy-palette-neutral-outlinedHoverColor, var(--joy-palette-neutral-900, #131318)) !important',
						},
					},
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: 'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF)) !important',
					},
					'& .MuiInputLabel-root': {
						color: 'var(--joy-palette-neutral-outlinedColor, var(--joy-palette-neutral-800, #25252D)) !important',
					},
				}}
				value={dayjs(currentValue)}
				onOpen={() => setOpen(true)}
				slots={{
					dialog: (props: any) => {
						return (
							<Modal
								{...props}
								open={open}
								onClose={() => setOpen(false)}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								{/* <Calendar									
									value={{
										year: dayjs(currentValue).year(),
										month: dayjs(currentValue).month() + 1,
										day: dayjs(currentValue).date(),
									}}
									onChange={(e: any) => {
										setValue(
											moment()
												.year(e.year)
												.month(e.month - 1)
												.date(e.day)
												.format('YYYY-MM-DD'),
										);
										setOpen(false);
									}}
									shouldHighlightWeekends
								/> */}
								<YearCalendar
									value={currentValue}
									minDate={dayjs(new Date().setFullYear(
										dayjs(currentValue).year() - (dayjs(currentValue).year() + 1) ,
									))}
									maxDate={dayjs()}
									onChange={(e: any) => {
										setValue(e);
										setOpen(false);
									}}
									disableFuture
								/>
							</Modal>
						);
					},
					popper: (props: any) => {
						return (
							<Modal
								{...props}
								open={open}
								onClose={() => setOpen(false)}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								{/* <Calendar
									selectorStartingYear={1}
									value={{
										year: dayjs(currentValue).year(),
										month: dayjs(currentValue).month() + 1,
										day: dayjs(currentValue).date(),
									}}
									onChange={(e: any) => {
										setValue(
											moment()
												.year(e.year)
												.month(e.month - 1)
												.date(e.day)
												.format('YYYY-MM-DD'),
										);
										setOpen(false);
									}}
									shouldHighlightWeekends
								/> */}

								<YearCalendar
									value={currentValue}
									onChange={(e: any) => {
										console.log(e);
										setValue(e);
										setOpen(false);
									}}
									minDate={dayjs(new Date().setFullYear(
										dayjs(currentValue).year() - (dayjs(currentValue).year() + 1) ,
									))}
									maxDate={dayjs()}
									disableFuture
								/>
							</Modal>
						);
					},
				}}
				onClose={() => setOpen(false)}
			/>
		</Box>
	);
};

const ModalCreateExcursion = ({ excursions, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { excursions: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [createExcursion, { isSuccess: createIsSuccess, isLoading: createIsLoading }] = useAddExcursionMutation();
	const [getHalls, { data: hallsData, isSuccess: getHallsSuccess }] = useGetHallsMutation();
	const [getGuides, { data: guidesData }] = useGuidesMutation();

	const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
	const [hallsByGuides, setHallsByGuides] = useState<any>([]);
	const [price, setPrice] = useState({
		adult: undefined,
		child: undefined,
		family: undefined,
	});
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [daysOpen, setDaysOpen] = useState({
		mon: false,
		tue: false,
		wed: false,
		thu: false,
		fri: false,
		sat: false,
		sun: false,
	});
	const [countPersonFrom, setCountPersonFrom] = useState(1);
	const [countPersonTo, setCountPersonTo] = useState(10);
	const [countDescription, setCountDescription] = useState(0);

	const [selected, setSelected] = useState([]);
	const [selectedGuides, setSelectedGuides] = useState([]);
	const isAllSelected = hallsByGuides?.length > 0 && selected.length === hallsByGuides?.length;
	// @ts-ignore
	const isAllSelectedGuides = guidesData?.length > 0 && selectedGuides.length === guidesData?.length;

	const { t } = useTranslation();

	const handleChange = (event: any) => {
		const value = event.target.value;
		if (value[value.length - 1] === 'all') {
			setSelected(selected.length === hallsByGuides?.length ? [] : hallsByGuides.map((hall: any) => hall.id));
			return;
		}
		setSelected(value);
	};

	const handleChangeGuides = (event: any) => {
		const value = event.target.value;
		if (value[value.length - 1] === 'all') {
			// @ts-ignore
			const r = selectedGuides.length === guidesData?.length ? [] : guidesData.map((guide: any) => guide.id);
			// @ts-ignore
			setSelectedGuides(r);

			// @ts-ignore
			// const guides = guidesData?.filter((guide: any) => r.includes(guide.id));

			// @ts-ignore
			// const halls_from_guides = guides.map((guide: any) => guide.halls.map((hall: any) => hall.id)).flat();

			// @ts-ignore
			// const unique_halls_from_guides = [...new Set(halls_from_guides)];

			// setHallsByGuides(hallsData?.filter((hall: any) => unique_halls_from_guides.includes(hall.id)));

			return;
		}

		// @ts-ignore
		// const guides = guidesData?.filter((guide: any) => value.includes(guide.id));

		// @ts-ignore
		// const halls_from_guides = guides.map((guide: any) => guide.halls.map((hall: any) => hall.id)).flat();

		// @ts-ignore
		// const unique_halls_from_guides = [...new Set(halls_from_guides)];

		// setHallsByGuides(hallsData?.filter((hall: any) => unique_halls_from_guides.includes(hall.id)));

		setSelectedGuides(value);
	};

	// useEffect(() => {
	// 	if (selectedGuides.length > 0) {
	// 		setHallsByGuides(getHallsByGuides());
	// 	}
	// }, [selectedGuides]);

	useEffect(() => {
		getHalls();
		getGuides();
	}, [getHalls, getGuides]);

	useEffect(() => {
		if (createIsSuccess) {
			excursions();
			setTextAlert(t('success.date_added'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, create: false });
			setSelectedFile(null);
		}
	}, [createIsSuccess]);

	useEffect(() => {
		if (getHallsSuccess) {
			setHallsByGuides(hallsData);
		}
	}, [getHallsSuccess]);

	const handleUpload = (data: any) => {
		if (selectedFile) {
			const formData = new FormData();

			for (let i = 0; i < selectedFile.length; i++) {
				// @ts-ignore
				formData.append('files[]', selectedFile[i], selectedFile[i].name);
			}

			Object.keys(data).forEach(key => {
				// @ts-ignore
				if (key === 'tags') {
					// if , in string
					if (data[key].includes(',')) {
						// @ts-ignore
						data[key].split(',').forEach((tag: string) => {
							formData.append('tags', tag);
						});
					} else {
						// @ts-ignore
						formData.append('tags', [data[key]]);
					}

					return;
				}

				if (key === 'halls') {
					data[key].forEach((hall: any) => {
						formData.append('halls', hall);
					});

					return;
				}

				if (key === 'guides') {
					data[key].forEach((guide: any) => {
						formData.append('guides', guide);
					});

					return;
				}

				data[key] && formData.append(key, data[key]);
			});

			createExcursion(formData);
		}
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['create']} onClose={() => setIsModalOpen({ ...isModalOpen, create: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('excursionPage.addExcursion')}
				</Typography>
				<form
					autoComplete="off"
					onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();

						const data = {
							// @ts-ignore
							name: event.currentTarget?.name?.value,
							description: event.currentTarget?.description?.value,
							status: event.currentTarget?.status[1].value,
							days_of_week: JSON.stringify(daysOpen),
							price: JSON.stringify(price),
							count_person_from: countPersonFrom,
							count_person_to: countPersonTo,
							start_date: moment(startDate).format('YYYY-MM-DD'),
							end_date: moment(endDate).format('YYYY-MM-DD'),
							guides: selectedGuides,
							halls: selected,
							person_age: event.currentTarget?.adult[0].checked ? 1 : 0,
							tags: event.currentTarget?.tags?.value,
						};

						// if (selected.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.hallAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						// if (selectedGuides.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.guidesAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						if (data.name.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('excursionPage.excursionName') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('excursionPage.excursionInfo') + ') - 3000');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (countPersonTo < countPersonFrom) {
							setTextAlert(t('errors.max_count_person'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (
							Object.keys(price).filter((key: any) => {
								// @ts-ignore
								return price[key] !== undefined && price[key] !== null;
							}).length === 0
						) {
							setTextAlert(t('errors.price'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate === null || endDate === null) {
							setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.date') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate > endDate) {
							setTextAlert(t('errors.date_end'));
							setColor('danger');
							setOpen(true);
							return;
						}

						// @ts-ignore
						if (Object.keys(daysOpen).filter((day: any) => daysOpen[day] === true).length === 0) {
							setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.days') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (selectedFile?.length === 0 || selectedFile === null) {
							setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.photo') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('excursionPage.excursionInfo') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						handleUpload(data);
					}}>
					<Stack spacing={2}>
						<FormControl>
							<FormLabel>{t('excursionPage.excursionName')}</FormLabel>
							<Input autoComplete="off" autoFocus required name="name" />
						</FormControl>
						<FormControl>
							<FormLabel>{t('excursionPage.excursionInfo')}</FormLabel>
							<Typography
								sx={{
									mb: 1,
									//@ts-ignore
									color: countDescription > 3000 ? 'red' : 'inherit',
								}}>
								{countDescription}/3000
							</Typography>
							<Textarea
								required
								maxRows={4}
								minRows={2}
								name="description"
								onChange={e => {
									setCountDescription(e.target.value.length);
								}}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>{t('excursionPage.guidesAssigned')}</FormLabel>
							<Select
								sx={{
									borderRadius: '10px',
								}}
								labelId="mutiple-select-label"
								multiple
								name="guides"
								value={selectedGuides}
								onChange={handleChangeGuides}
								renderValue={() => {
									if (selectedGuides.length === guidesData?.length) {
										return guidesData?.map((option: any) => option.name).join(', ');
									} else {
										return (
											guidesData
												// @ts-ignore
												?.filter((option: any) => selectedGuides.indexOf(option.id) > -1)
												.map((option: any) => option.name)
												.join(', ')
										);
									}
								}}>
								<MenuItem value="all">
									<ListItemIcon>
										<Checkbox
											checked={isAllSelectedGuides}
											indeterminate={
												// @ts-ignore
												selectedGuides.length > 0 && selectedGuides.length < guidesData?.length
											}
										/>
									</ListItemIcon>
									<ListItemText primary={t('excursionPage.select_all') || 'Select all'} />
								</MenuItem>
								{guidesData?.map((option: any) => (
									<MenuItem key={option.id} value={option.id}>
										<ListItemIcon>
											<Checkbox
												checked={
													// @ts-ignore
													selectedGuides.indexOf(option.id) > -1
												}
											/>
										</ListItemIcon>
										<ListItemText primary={option.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<FormLabel>{t('excursionPage.hallAssigned')}</FormLabel>
							<Select
								sx={{
									borderRadius: '10px',
								}}
								labelId="mutiple-select-label"
								multiple
								// disabled={selectedGuides.length === 0}
								name="guides"
								value={selected}
								onChange={handleChange}
								renderValue={() => {
									if (selected.length === hallsData?.length) {
										return hallsByGuides?.map((option: any) => option.name).join(', ');
									} else {
										return (
											hallsByGuides
												// @ts-ignore
												?.filter((option: any) => selected.indexOf(option.id) > -1)
												.map((option: any) => option.name)
												.join(', ')
										);
									}
								}}>
								<MenuItem value="all">
									<ListItemIcon>
										<Checkbox checked={isAllSelected} indeterminate={selected.length > 0 && selected.length < hallsByGuides?.length} />
									</ListItemIcon>
									<ListItemText primary={t('excursionPage.select_all') || 'Select all'} />
								</MenuItem>
								{hallsByGuides.map((option: any) => (
									<MenuItem key={option.id} value={option.id}>
										<ListItemIcon>
											<Checkbox
												checked={
													// @ts-ignore
													selected.indexOf(option.id) > -1
												}
											/>
										</ListItemIcon>
										<ListItemText primary={option.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormLabel>{t('excursionPage.status')}</FormLabel>
						<SelectJoy defaultValue={0} name="status">
							{
								//@ts-ignore
								t('excursionPage.statuses', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</SelectJoy>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormLabel>{t('excursionPage.for_adult')}</FormLabel>
							<Checkbox name="adult" />
						</Stack>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormControl>
								<FormLabel>{t('excursionPage.person_min')}</FormLabel>
								<Input type="number" required name="person_from" value={countPersonFrom} onChange={e => setCountPersonFrom(parseInt(e.target.value))} />
							</FormControl>
							<FormControl>
								<FormLabel>{t('excursionPage.person_max')}</FormLabel>
								<Input type="number" required name="person_to" value={countPersonTo} onChange={e => setCountPersonTo(parseInt(e.target.value))} />
							</FormControl>
						</Stack>

						{Object.keys(price).map((key: string, index: number) => {
							return (
								<FormControl key={index}>
									<FormLabel>{t('excursionPage.price') + ' - ' + t('excursionPage.' + key)}</FormLabel>
									<Stack direction="row" spacing={2} alignItems="center">
										<FormControl>
											<CheckboxJoy
												name={key}
												checked={
													// @ts-ignore
													price[key] !== undefined
												}
												onChange={
													// @ts-ignore
													e =>
														setPrice({
															...price,
															[key]:
																// @ts-ignore
																e.target.checked ? null : undefined,
														})
												}
											/>
										</FormControl>
										<Input
											// disabled={
											// 	// @ts-ignore
											// 	price[key] === undefined
											// }
											name={key}
											type="number"
											value={
												// @ts-ignore
												price[key] ? price[key] : ''
											}
											onClick={e => {
												setPrice({
													...price,
													[key]: null,
												});
											}}
											onChange={
												// @ts-ignore
												e =>
													setPrice({
														...price,
														[key]:
															// @ts-ignore
															e.target.value ? (parseInt(e.target.value) > 0 ? parseInt(e.target.value) : parseInt(e.target.value) * -1) : 0,
													})
											}
										/>
									</Stack>
								</FormControl>
							);
						})}

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack direction="row" spacing={2} alignItems="center" mt={2}>
								<CustomCalendar
									currentValue={startDate}
									setValue={(date: any) => {
										setStartDate(date);
									}}
									text={t('excursionPage.start_date')}
								/>
								<CustomCalendar
									currentValue={endDate}
									setValue={(date: any) => {
										setEndDate(date);
									}}
									text={t('excursionPage.end_date')}
								/>
							</Stack>
						</LocalizationProvider>

						<Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
							{
								//@ts-ignore
								Object.keys(t('days', { returnObjects: true })).map((key: string, index: number) => {
									return (
										<FormControl key={index}>
											<FormLabel>{t('days.' + key)}</FormLabel>
											<CheckboxJoy
												name={key}
												checked={
													// @ts-ignore
													daysOpen[key]
												}
												onChange={
													// @ts-ignore
													e =>
														setDaysOpen({
															...daysOpen,
															[key]: e.target.checked,
														})
												}
											/>
										</FormControl>
									);
								})
							}
						</Stack>

						<FormControl>
							<FormLabel>{t('excursionPage.tags')}</FormLabel>
							<Textarea maxRows={4} minRows={2} name="tags" placeholder={t('excursionPage.inputTags') || ''} />
						</FormControl>

						<ImagesUploader setSelectedFile={setSelectedFile} />

						<Button type="submit" disabled={createIsLoading}>
							{createIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('excursionPage.addExcursion')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalUpdateExcursion = ({ excursions, currentExcursion, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { excursions: () => void; currentExcursion: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [updateExcursion, { isSuccess: createIsSuccess, isLoading: createIsLoading }] = useEditExcursionMutation();
	const [getHalls, { data: hallsData, isSuccess: getHallsSuccess }] = useGetHallsMutation();
	const [getGuides, { data: guidesData }] = useGuidesMutation();
	const [updateImage, dataUplImg] = useUpdateImgExcursionMutation();

	const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
	const [hallsByGuides, setHallsByGuides] = useState<any>([]);
	const [price, setPrice] = useState({
		adult: undefined,
		child: undefined,
		family: undefined,
	});
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [daysOpen, setDaysOpen] = useState({
		mon: false,
		tue: false,
		wed: false,
		thu: false,
		fri: false,
		sat: false,
		sun: false,
	});
	const [countPersonFrom, setCountPersonFrom] = useState(0);
	const [countPersonTo, setCountPersonTo] = useState(10);
	const [tmp, setTmp] = useState<any>();
	const [countDescription, setCountDescription] = useState(0);

	const [selected, setSelected] = useState(
		currentExcursion?.halls?.map((hall: any) => {
			return hall.id;
		}) || [],
	);
	const [selectedGuides, setSelectedGuides] = useState(
		currentExcursion?.guides?.map((guide: any) => {
			return guide.id;
		}) || [],
	);
	const isAllSelected = hallsByGuides?.length > 0 && selected.length === hallsByGuides?.length;
	// @ts-ignore
	const isAllSelectedGuides = guidesData?.length > 0 && selectedGuides.length === guidesData?.length;

	const { t } = useTranslation();

	const handleChange = (event: any) => {
		const value = event.target.value;
		if (value[value.length - 1] === 'all') {
			setSelected(selected.length === hallsByGuides?.length ? [] : hallsByGuides.map((hall: any) => hall.id));
			return;
		}
		setSelected(value);
	};

	const handleChangeGuides = (event: any) => {
		const value = event.target.value;
		if (value[value.length - 1] === 'all') {
			// @ts-ignore
			const r = selectedGuides.length === guidesData?.length ? [] : guidesData.map((guide: any) => guide.id);
			// @ts-ignore
			setSelectedGuides(r);

			// @ts-ignore
			// const guides = guidesData?.filter((guide: any) => r.includes(guide.id));

			// @ts-ignore
			// const halls_from_guides = guides.map((guide: any) => guide.halls.map((hall: any) => hall.id)).flat();

			// @ts-ignore
			// const unique_halls_from_guides = [...new Set(halls_from_guides)];

			// setHallsByGuides(hallsData?.filter((hall: any) => unique_halls_from_guides.includes(hall.id)));

			return;
		}

		// @ts-ignore
		// const guides = guidesData?.filter((guide: any) => value.includes(guide.id));

		// @ts-ignore
		// const halls_from_guides = guides.map((guide: any) => guide.halls.map((hall: any) => hall.id)).flat();

		// @ts-ignore
		// const unique_halls_from_guides = [...new Set(halls_from_guides)];

		// setHallsByGuides(hallsData?.filter((hall: any) => unique_halls_from_guides.includes(hall.id)));

		setSelectedGuides(value);
	};

	useEffect(() => {
		if (dataUplImg.isSuccess) {
			updateExcursion({
				id: currentExcursion?.id,
				data: tmp,
			});
		}
	}, [dataUplImg.isSuccess, tmp]);

	useEffect(() => {
		getHalls();
		getGuides();

		if (currentExcursion?.id) {
			setDaysOpen(currentExcursion.days_of_week);
			setPrice({
				adult: currentExcursion?.price?.adult || undefined,
				child: currentExcursion?.price?.child || undefined,
				family: currentExcursion?.price?.family || undefined,
			});
			setCountPersonFrom(currentExcursion.count_person_from);
			setCountPersonTo(currentExcursion.count_person_to);
			setStartDate(new Date(currentExcursion.start_date));
			setEndDate(new Date(currentExcursion.end_date));
			setSelected(currentExcursion.halls.map((hall: any) => hall.id));
			setHallsByGuides(currentExcursion.halls.map((hall: any) => hall));
			setSelectedGuides(currentExcursion.guides.map((guide: any) => guide.id));
			setCountDescription(currentExcursion.description.length);
		}
	}, [isModalOpen.update]);

	useEffect(() => {
		if (createIsSuccess) {
			setTextAlert(t('success.date_updated'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, update: false });
			excursions();
			setSelectedFile(null);
		}
	}, [createIsSuccess]);

	useEffect(() => {
		if (getHallsSuccess) {
			setHallsByGuides(hallsData);
		}
	}, [getHallsSuccess]);

	const handleUpload = (data: any) => {
		if (selectedFile) {
			const formData = new FormData();

			for (let i = 0; i < selectedFile.length; i++) {
				// @ts-ignore
				formData.append('files[]', selectedFile[i], selectedFile[i].name);
			}

			Object.keys(data).forEach(key => {
				// @ts-ignore
				if (key === 'tags') {
					// if , in string
					if (data[key].includes(',')) {
						// @ts-ignore
						data[key].split(',').forEach((tag: string) => {
							formData.append('tags', tag);
						});
					} else {
						// @ts-ignore
						formData.append('tags', [data[key]]);
					}

					return;
				}

				if (key === 'halls') {
					data[key].forEach((hall: any) => {
						formData.append('halls', hall);
					});

					return;
				}

				if (key === 'guides') {
					data[key].forEach((guide: any) => {
						formData.append('guides', guide);
					});

					return;
				}

				data[key] && formData.append(key, data[key]);
			});

			updateExcursion(formData);
		}
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['update']} onClose={() => setIsModalOpen({ ...isModalOpen, update: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('excursionPage.update')}
				</Typography>
				<form
					autoComplete="off"
					onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();

						const data = {
							// @ts-ignore
							name: event.currentTarget?.name?.value,
							description: event.currentTarget?.description?.value,
							status: event.currentTarget?.status[1].value,
							days_of_week: JSON.stringify(daysOpen),
							price: JSON.stringify(price),
							count_person_from: countPersonFrom,
							count_person_to: countPersonTo,
							start_date: moment(startDate).format('YYYY-MM-DD'),
							end_date: moment(endDate).format('YYYY-MM-DD'),
							guides: selectedGuides,
							halls: selected,
							person_age: event.currentTarget?.adult[0].checked ? 1 : 0,
							tags: event.currentTarget?.tags?.value,
						};

						// if (selected.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.hallAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						// if (selectedGuides.length === 0) {
						// 	setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.guidesAssigned') + ')');
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						if (data.name.length > 60) {
							setTextAlert(t('errors.max_length') + ' (' + t('excursionPage.excursionName') + ') - 60');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (data.description.length > 3000) {
							setTextAlert(t('errors.max_length') + ' (' + t('excursionPage.excursionInfo') + ') - 3000');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (countPersonTo < countPersonFrom) {
							setTextAlert(t('errors.max_count_person'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (
							Object.keys(price).filter((key: any) => {
								// @ts-ignorea
								return price[key] !== undefined && price[key] !== null;
							}).length === 0
						) {
							setTextAlert(t('errors.price'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate === null || endDate === null) {
							setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.date') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (startDate > endDate) {
							setTextAlert(t('errors.date_end'));
							setColor('danger');
							setOpen(true);
							return;
						}

						// @ts-ignore
						if (Object.keys(daysOpen).filter((day: any) => daysOpen[day] === true).length === 0) {
							setTextAlert(t('errors.empty_field') + ' (' + t('excursionPage.days') + ')');
							setColor('danger');
							setOpen(true);
							return;
						}

						if (selectedFile && selectedFile !== null) {
							updateImage({
								id: currentExcursion?.id,
								data: selectedFile,
							});

							setTmp(data);
						} else {
							updateExcursion({
								id: currentExcursion?.id,
								data,
							});
						}
						// handleUpload(data);
					}}>
					<Stack spacing={2}>
						<FormControl>
							<FormLabel>{t('excursionPage.excursionName')}</FormLabel>
							<Input autoFocus required name="name" defaultValue={currentExcursion?.name} />
						</FormControl>
						<FormControl>
							<FormLabel>{t('excursionPage.excursionInfo')}</FormLabel>
							<Typography
								sx={{
									mb: 1,
									//@ts-ignore
									color: countDescription > 3000 ? 'red' : 'inherit',
								}}>
								{countDescription}/3000
							</Typography>
							<Textarea
								required
								maxRows={4}
								minRows={2}
								name="description"
								defaultValue={currentExcursion?.description}
								onChange={e => {
									setCountDescription(e.target.value.length);
								}}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>{t('excursionPage.guidesAssigned')}</FormLabel>
							<Select
								sx={{
									borderRadius: '10px',
								}}
								labelId="mutiple-select-label"
								multiple
								name="guides"
								value={selectedGuides}
								onChange={handleChangeGuides}
								renderValue={() => {
									if (selectedGuides.length === guidesData?.length) {
										return guidesData?.map((option: any) => option.name).join(', ');
									} else {
										return (
											guidesData
												// @ts-ignore
												?.filter((option: any) => selectedGuides.indexOf(option.id) > -1)
												.map((option: any) => option.name)
												.join(', ')
										);
									}
								}}>
								<MenuItem value="all">
									<ListItemIcon>
										<Checkbox
											checked={isAllSelectedGuides}
											indeterminate={
												// @ts-ignore
												selectedGuides.length > 0 && selectedGuides.length < guidesData?.length
											}
										/>
									</ListItemIcon>
									<ListItemText primary={t('excursionPage.select_all') || 'Select all'} />
								</MenuItem>
								{guidesData?.map((option: any) => (
									<MenuItem key={option.id} value={option.id}>
										<ListItemIcon>
											<Checkbox
												checked={
													// @ts-ignore
													selectedGuides.indexOf(option.id) > -1
												}
											/>
										</ListItemIcon>
										<ListItemText primary={option.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl>
							<FormLabel>{t('excursionPage.hallAssigned')}</FormLabel>
							<Select
								sx={{
									borderRadius: '10px',
								}}
								labelId="mutiple-select-label"
								multiple
								// disabled={selectedGuides.length === 0}
								name="guides"
								value={selected}
								onChange={handleChange}
								renderValue={() => {
									if (selected.length === hallsData?.length) {
										return hallsByGuides?.map((option: any) => option.name).join(', ');
									} else {
										return (
											hallsByGuides
												// @ts-ignore
												?.filter((option: any) => selected.indexOf(option.id) > -1)
												.map((option: any) => option.name)
												.join(', ')
										);
									}
								}}>
								<MenuItem value="all">
									<ListItemIcon>
										<Checkbox checked={isAllSelected} indeterminate={selected.length > 0 && selected.length < hallsByGuides?.length} />
									</ListItemIcon>
									<ListItemText primary={t('excursionPage.select_all') || 'Select all'} />
								</MenuItem>
								{hallsByGuides.map((option: any) => (
									<MenuItem key={option.id} value={option.id}>
										<ListItemIcon>
											<Checkbox
												checked={
													// @ts-ignore
													selected.indexOf(option.id) > -1
												}
											/>
										</ListItemIcon>
										<ListItemText primary={option.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormLabel>{t('excursionPage.status')}</FormLabel>
						<SelectJoy defaultValue={currentExcursion?.status} name="status">
							{
								//@ts-ignore
								t('excursionPage.statuses', { returnObjects: true }).map((state: string, index: number) => {
									return (
										<Option key={index} value={index}>
											{state}
										</Option>
									);
								})
							}
						</SelectJoy>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormLabel>{t('excursionPage.for_adult')}</FormLabel>
							<Checkbox name="adult" defaultChecked={currentExcursion?.person_age === 1 ? true : false} />
						</Stack>

						<Stack direction="row" spacing={2} alignItems="center">
							<FormControl>
								<FormLabel>{t('excursionPage.person_min')}</FormLabel>
								<Input type="number" required name="person_from" value={countPersonFrom} onChange={e => setCountPersonFrom(parseInt(e.target.value))} />
							</FormControl>
							<FormControl>
								<FormLabel>{t('excursionPage.person_max')}</FormLabel>
								<Input type="number" required name="person_to" value={countPersonTo} onChange={e => setCountPersonTo(parseInt(e.target.value))} />
							</FormControl>
						</Stack>

						{Object.keys(price).map((key: string, index: number) => {
							return (
								<FormControl key={index}>
									<FormLabel>{t('excursionPage.price') + ' - ' + t('excursionPage.' + key)}</FormLabel>
									<Stack direction="row" spacing={2} alignItems="center">
										<FormControl>
											<CheckboxJoy
												name={key}
												checked={
													// @ts-ignore
													price[key] !== undefined
												}
												onChange={
													// @ts-ignore
													e =>
														setPrice({
															...price,
															[key]:
																// @ts-ignore
																e.target.checked ? null : undefined,
														})
												}
											/>
										</FormControl>
										<Input
											// disabled={
											// 	// @ts-ignore
											// 	price[key] === undefined
											// }
											name={key}
											type="number"
											value={
												// @ts-ignore
												price[key] ? price[key] : ''
											}
											onClick={e => {
												setPrice({
													...price,
													[key]: null,
												});
											}}
											onChange={
												// @ts-ignore
												e =>
													setPrice({
														...price,
														[key]:
															// @ts-ignore
															e.target.value ? (parseInt(e.target.value) > 0 ? parseInt(e.target.value) : parseInt(e.target.value) * -1) : 0,
													})
											}
										/>
									</Stack>
								</FormControl>
							);
						})}

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack direction="row" spacing={2} alignItems="center" mt={2}>
								<CustomCalendar
									currentValue={startDate}
									setValue={(date: any) => {
										setStartDate(new Date(date));
									}}
									text={t('excursionPage.start_date')}
								/>

								<CustomCalendar
									currentValue={endDate}
									setValue={(date: any) => {
										setEndDate(new Date(date));
									}}
									text={t('excursionPage.end_date')}
								/>
							</Stack>
						</LocalizationProvider>

						<Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
							{
								//@ts-ignore
								Object.keys(t('days', { returnObjects: true })).map((key: string, index: number) => {
									return (
										<FormControl key={index}>
											<FormLabel>{t('days.' + key)}</FormLabel>
											<CheckboxJoy
												name={key}
												checked={
													// @ts-ignore
													daysOpen[key]
												}
												onChange={
													// @ts-ignore
													e =>
														setDaysOpen({
															...daysOpen,
															[key]: e.target.checked,
														})
												}
											/>
										</FormControl>
									);
								})
							}
						</Stack>

						<FormControl>
							<FormLabel>{t('excursionPage.tags')}</FormLabel>
							<Textarea
								maxRows={4}
								minRows={2}
								name="tags"
								placeholder={t('excursionPage.inputTags') || ''}
								defaultValue={
									// @ts-ignore
									currentExcursion?.tags?.map((tag: string) => tag.name).join(', ')
								}
							/>
						</FormControl>

						<ImagesUploader setSelectedFile={setSelectedFile} />

						<Button type="submit" disabled={createIsLoading}>
							{createIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('excursionPage.update')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalDeleteExcursion = ({ excursions, currentExcursion, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { excursions: () => void; currentExcursion: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [getHalls, { data: hallsData, isSuccess: getHallsSuccess }] = useGetHallsMutation();
	const [deleteExcursion, { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess }] = useDelExcursionMutation();
	const { t } = useTranslation();

	useEffect(() => {
		if (deleteIsSuccess) {
			excursions();
			setTextAlert(t('success.date_deleted'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, delete: false });
		}
	}, [deleteIsSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['delete']} onClose={() => setIsModalOpen({ ...isModalOpen, delete: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('excursionPage.delete') + ' ' + currentExcursion?.name + '?'}
				</Typography>

				<Button
					type="button"
					disabled={deleteIsLoading}
					onClick={() => {
						deleteExcursion(currentExcursion?.id);
					}}>
					{deleteIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('excursionPage.delete')}
				</Button>
			</Sheet>
		</Modal>
	);
};

const ModalDeleteAllExcursion = ({ excursions, currentExcursion, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { excursions: () => void; currentExcursion: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [getHalls, { data: hallsData, isSuccess: getHallsSuccess }] = useGetHallsMutation();
	const [deleteExcursion, { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess }] = useDelExcursionMutation();
	const { t } = useTranslation();

	const deleteSelected = (ids: any) => {
		ids.forEach((id: any) => {
			(async () => {
				await deleteExcursion(id);
			})();
		});
	};

	useEffect(() => {
		if (deleteIsSuccess) {
			excursions();
			setTextAlert(t('success.date_deleted'));
			setColor('success');
			setOpen(true);
			setIsModalOpen({ ...isModalOpen, deleteAll: false });
		}
	}, [deleteIsSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['deleteAll']} onClose={() => setIsModalOpen({ ...isModalOpen, deleteAll: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('settingsPage.delete') + ' вибрані екскурсії?'}
				</Typography>

				<Button
					type="button"
					disabled={deleteIsLoading}
					onClick={() => {
						deleteSelected(currentExcursion);
					}}>
					{deleteIsLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('settingsPage.delete')}
				</Button>
			</Sheet>
		</Modal>
	);
};

export default function Excursions() {
	const [isModalOpen, setIsModalOpen] = useState<any>({});
	const [excursions, { isLoading, isError, data }] = useExcursionsMutation();
	const [getExcursion, { isLoading: getIsLoading, isSuccess: getIsSuccess, data: excursionData }] = useGetExcursionMutation();

	const [currentExcursion, setCurrentExcursion] = useState<any>({});
	const [status, setStatus] = useState<any>(0);
	const user_data = useSelector(selectCurrentUser);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

	const { t } = useTranslation();
	const navigator = useNavigate();

	useEffect(() => {
		excursions();

		if ([2, 3].includes(parseInt(user_data?.museum?.type))) {
			navigator('/settings');
		}
	}, [excursions]);

	useEffect(() => {
		if (getIsSuccess) {
			setCurrentExcursion(excursionData);
			if (status === 1) {
				setIsModalOpen({ ...isModalOpen, update: true });
			}
			if (status === 2) {
				setIsModalOpen({ ...isModalOpen, delete: true });
			}
		}
	}, [getIsSuccess, status]);

	useEffect(() => {
		if (status === 3) {
			setIsModalOpen({ ...isModalOpen, deleteAll: true });
			setStatus(0);
		}
	}, [status]);

	const DeleteActions = ({ id }: { id: number }) => {
		return (
			<Box sx={{ display: 'flex', gap: 1 }}>
				<Button
					size="sm"
					variant="plain"
					color="info"
					data-action
					onClick={() => {
						setStatus(1);
						getExcursion(id);
					}}>
					<i className="fa-solid fa-edit"></i>
				</Button>
				<Button
					size="sm"
					variant="soft"
					color="danger"
					data-action
					onClick={() => {
						setStatus(2);
						getExcursion(id);
					}}>
					<i className="fa-solid fa-trash"></i>
				</Button>
			</Box>
		);
	};

	const columns = [
		{
			id: 'id',
			disablePadding: false,
			label: 'ID',
			numeric: false,
			width: '70px',
		},
		{
			id: 'name',
			disablePadding: false,
			label: t('excursionPage.table.name'),
			numeric: false,
			width: '180px',
		},
		{
			id: 'date',
			disablePadding: false,
			label: t('excursionPage.table.date'),
			numeric: false,
			width: '90px',
		},
		{
			id: 'price',
			disablePadding: false,
			label: t('excursionPage.table.price'),
			numeric: false,
			width: '120px',
		},
		{
			id: 'years_limit',
			disablePadding: false,
			label: t('excursionPage.table.years_limit'),
			numeric: false,
			width: '160px',
		},
		{
			id: 'status',
			disablePadding: false,
			label: t('excursionPage.table.status'),
			numeric: false,
			width: '90px',
		},
	];

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<Box sx={{ width: '100%' }}>
				<ModalCreateExcursion excursions={excursions} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />
				{currentExcursion && <ModalUpdateExcursion excursions={excursions} currentExcursion={currentExcursion} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />}
				{currentExcursion && <ModalDeleteExcursion excursions={excursions} currentExcursion={currentExcursion} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />}
				{currentExcursion && <ModalDeleteAllExcursion excursions={excursions} currentExcursion={currentExcursion} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setColor={setColor} setTextAlert={setTextAlert} setOpen={setOpen} />}
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2, mb: 2 }}>
					<h1>{t('excursions')}</h1>

					<Button startDecorator={<Add />} variant="solid" size="md" color="primary" sx={{ fontWeight: 600 }} onClick={() => setIsModalOpen({ ...isModalOpen, create: true })}>
						{t('excursionPage.addExcursion')}
					</Button>
				</Box>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
					{isLoading ? (
						<Loading />
					) : (
						<CustomTable
							title={t('excursionPage.table.title')}
							rows={
								// @ts-ignore
								data?.length > 0
									? data?.map((item: any, index: number) => ({
											id: item.id,
											name: item.name,
											date: new Date(item.end_date).toLocaleDateString('uk-UA', {
												day: 'numeric',
												year: 'numeric',
												month: 'numeric',
											}),
											price: (item.price.adult || item.price.child || item.price.family) + ' грн',
											years_limit: item.person_age === 0 ? t('excursionPage.table.years_limit_not') : t('excursionPage.for_adult') || '',
											// @ts-ignore
											status: t('excursionPage.statuses', { returnObjects: true })[item.status] || '',
									  }))
									: []
							}
							headCells={columns}
							Actions={DeleteActions}
							deleteSelected={selected => {
								setCurrentExcursion(selected);
								setStatus(3);
							}}
						/>
					)}
				</Box>
			</Box>
		</Layout>
	);
}
