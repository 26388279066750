import { Box, Input, Button, CircularProgress, Textarea, Typography, Card, IconButton, ModalClose, Sheet, Modal } from '@mui/joy';
import Layout from '../features/components/Layout';
import { Alert } from '../utils/Alert';
import { useEffect, useState } from 'react';
import { useCreateHallMutation, useDeleteHallMutation, useGetHallsMutation, useUpdateHallMutation } from '../app/services/halls';
import { useTranslation } from 'react-i18next';
import { useGetMuseumMutation } from '../app/services/museum';
import JoyTypography from '@mui/joy/Typography';
import { Loading } from '../utils/Loading';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	textAlert: string;
	setTextAlert: (text: string) => void;
	setColor: (color: string) => void;
}

const ModalCreateHall = ({ halls, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { halls: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [createHall, createHallData] = useCreateHallMutation();

	const [museum, { data: dataMuseum, isLoading: isLoadingMuseum }] = useGetMuseumMutation();

	useEffect(() => {
		museum();
	}, [museum]);

	const { t } = useTranslation();
	const [newHallName, setNewHallName] = useState<string>('');
	const [newHallDesc, setNewHallDesc] = useState<string>('');

	useEffect(() => {
		if (createHallData.isSuccess) {
			setOpen(true);
			setTextAlert(t('success.date_added') || '');
			setColor('success');

			setNewHallDesc('');
			// setNewHallName('');

			setIsModalOpen({ ...isModalOpen, create: false });
			halls();
		}
	}, [createHallData.isSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['create']} onClose={() => setIsModalOpen({ ...isModalOpen, create: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					maxHeight: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<JoyTypography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t([4].includes(parseInt(dataMuseum?.type)) ? 'settingsPage.add_collection' : 'settingsPage.add')}
				</JoyTypography>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
					<Input
						type="text"
						placeholder={t('settingsPage.hallName') || ''}
						value={newHallName}
						sx={{ width: '100%' }}
						autoFocus
						onChange={
							// @ts-ignore
							e => setNewHallName(e.target.value)
						}
					/>
					<Typography
						sx={{
							mt: 1,
							//@ts-ignore
							color: newHallDesc.length > 3000 ? 'red' : 'inherit',
						}}>
						{newHallDesc.length}/3000
					</Typography>
					<Textarea
						placeholder={t('settingsPage.hallDesc') || ''}
						value={newHallDesc}
						minRows={3}
						maxRows={15}
						sx={{ width: '100%' }}
						onChange={
							// @ts-ignore
							e => setNewHallDesc(e.target.value)
						}
					/>
					<Button
						variant="solid"
						size="sm"
						color="primary"
						sx={{ ml: 'auto', fontWeight: 600 }}
						disabled={newHallName.length > 60 || newHallDesc.length > 3000}
						onClick={async () => {
							if (newHallName) {
								await createHall({ name: newHallName, type: 'hall', description: newHallDesc });
								setNewHallName('');
							}
						}}>
						{t('settingsPage.add')}
					</Button>
				</Box>
			</Sheet>
		</Modal>
	);
};

const ModalEditHall = ({ halls, currentHall, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { halls: () => void; currentHall: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [updateHall, updateHallData] = useUpdateHallMutation();
	const [tempValue, setTempValue] = useState<any>();

	const [museum, { data: dataMuseum, isLoading: isLoadingMuseum }] = useGetMuseumMutation();

	useEffect(() => {
		museum();
	}, [museum]);

	useEffect(() => {
		if (currentHall) {
			setTempValue(currentHall);
		}
	}, [currentHall]);

	const { t } = useTranslation();

	useEffect(() => {
		if (updateHallData.isError) {
			setOpen(true);
			// @ts-ignore
			setTextAlert(updateHallData?.error?.data?.message || t('errors.update_error'));
			setColor('danger');
		}
		if (updateHallData.isSuccess) {
			setOpen(true);
			setTextAlert(t('success.date_updated') || '');
			setColor('success');
			setIsModalOpen({ ...isModalOpen, edit: false });
			halls();
		}
	}, [updateHallData.isError, updateHallData.isSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['edit']} onClose={() => setIsModalOpen({ ...isModalOpen, edit: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					maxHeight: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<JoyTypography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t(t([2].includes(parseInt(dataMuseum?.type)) ? 'settingsPage.editing_collection' : 'settingsPage.editing_hall'))}
				</JoyTypography>
				{tempValue !== undefined && (
					<>
						<Input
							sx={{ mb: 2 }}
							value={tempValue.name}
							onChange={e => {
								setTempValue(
									Object.assign({}, tempValue, {
										name: e.target.value,
									}),
								);
							}}
						/>
						<Typography
							sx={{
								mt: 1,
								//@ts-ignore
								color: tempValue.description.length > 3000 ? 'red' : 'inherit',
							}}>
							{tempValue.description.length}/3000
						</Typography>
						<Textarea
							sx={{ mb: 2 }}
							value={tempValue.description}
							minRows={3}
							maxRows={15}
							onChange={e => {
								setTempValue(
									Object.assign({}, tempValue, {
										description: e.target.value,
									}),
								);
							}}
						/>
						<Button
							sx={{ mt: 2 }}
							disabled={tempValue.name.length > 60 || tempValue.description.length > 3000}
							onClick={async () => {
								await updateHall(tempValue);
							}}>
							{updateHallData.isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('save')}
						</Button>
					</>
				)}
			</Sheet>
		</Modal>
	);
};

const ModalDeleteHall = ({ halls, currentHall, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { halls: () => void; currentHall: any; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [deleteHall, deleteHallData] = useDeleteHallMutation();
	const [tempValue, setTempValue] = useState<any>();

	useEffect(() => {
		if (currentHall) {
			setTempValue(currentHall);
		}
	}, [currentHall]);

	useEffect(() => {
		if (deleteHallData.isSuccess) {
			setOpen(true);
			setTextAlert(t('success.date_deleted') || '');
			setColor('success');
			setIsModalOpen({ ...isModalOpen, delete: false });
			halls();
		}
	}, [deleteHallData.isSuccess]);

	const { t } = useTranslation();

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['delete']} onClose={() => setIsModalOpen({ ...isModalOpen, delete: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					maxHeight: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<JoyTypography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('settingsPage.delete') + ' ' + tempValue?.name + '?'}
				</JoyTypography>
				{tempValue !== undefined && (
					<Button
						sx={{ mt: 2 }}
						onClick={async () => {
							await deleteHall(tempValue?.id);
						}}>
						{deleteHallData.isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('settingsPage.delete')}
					</Button>
				)}
			</Sheet>
		</Modal>
	);
};

const HallsSettings = ({ open, setOpen, textAlert, setTextAlert, setColor }: Props) => {
	const [getHalls, { data: hallsData, isLoading: hallsIsLoading }] = useGetHallsMutation();

	const [museum, { data: dataMuseum, isLoading: isLoadingMuseum }] = useGetMuseumMutation();

	const [currentHall, setCurrentHall] = useState<string>();
	const [isModalOpen, setIsModalOpen] = useState<object>({ edit: false });

	const { t } = useTranslation();

	useEffect(() => {
		(async () => {
			await getHalls();
			await museum();
		})();
	}, [getHalls, museum]);

	return (
		<>
			<ModalEditHall halls={getHalls} currentHall={currentHall} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setTextAlert={setTextAlert} setColor={setColor} />
			<ModalCreateHall halls={getHalls} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setTextAlert={setTextAlert} setColor={setColor} />
			<ModalDeleteHall halls={getHalls} currentHall={currentHall} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setTextAlert={setTextAlert} setColor={setColor} />
			{hallsIsLoading ? (
				<Loading />
			) : (
				<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
					<Card
						variant="outlined"
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}>
						<Typography level="h2" fontSize="md" sx={{ mb: 3 }}>
							{t('settingsPage.hall')}
						</Typography>
						<Box sx={{ display: 'flex', mb: 2, flexDirection: 'column' }}>
							{
								//@ts-ignore
								hallsData
									?.filter((item: any) => item.type === 'hall')
									.map((item: any, index: number) => {
										return (
											<div
												key={index}
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													marginBottom: '10px',
												}}>
												{
													<Typography>{item.name}</Typography>
													//@ts-ignore
													// editComplete[item.id] ? (
													// 	<Input
													// 		type={'text'}
													// 		value={currentHall}
													// 		onChange={e => {
													// 			setCurrentHall(e.target.value);
													// 		}}
													// 	/>
													// ) : (
													// 	<Typography>{item.name}</Typography>
													// )
												}
												<div style={{ display: 'flex' }}>
													<IconButton
														variant="plain"
														color="neutral"
														size="md"
														onClick={async () => {
															setCurrentHall(item);
															setIsModalOpen({ ...isModalOpen, edit: true });
														}}>
														<i className="fa-solid fa-edit"></i>
													</IconButton>
													<IconButton
														variant="plain"
														color="neutral"
														size="md"
														// disabled={deleteHallData.isLoading}
														onClick={async () => {
															setCurrentHall(item);
															setIsModalOpen({ ...isModalOpen, delete: true });
														}}>
														<i className="fa-solid fa-trash" style={{ color: 'var(--joy-palette-danger-500, #D3232F)' }}></i>
													</IconButton>
												</div>
											</div>
										);
									})
							}
						</Box>
						<Box sx={{ display: 'flex' }}>
							<Button
								variant="solid"
								size="sm"
								color="primary"
								sx={{ fontWeight: 600 }}
								onClick={async () => {
									setIsModalOpen({ ...isModalOpen, create: true });

									// if (newHallName) {
									// 	await createHall({ name: newHallName, type: 'hall' });
									// 	setNewHallName('');
									// }
								}}>
								{t('settingsPage.add')}
							</Button>
						</Box>
					</Card>
				</Box>
			)}
		</>
	);
};

export const Halls = () => {
    const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

    const [museum, { data: dataMuseum, isLoading: isLoadingMuseum }] = useGetMuseumMutation();

	useEffect(() => {
		museum();
	}, [museum]);

    const { t } = useTranslation();

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<Box sx={{ width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						// on mobile
						flexDirection: {
							xs: 'column',
							sm: 'row',
						},
						mb: {
							xs: '20px',
						},
					}}>
					{/* <h1>{t('settingsPage.' + ([0, 1].includes(parseInt(dataMuseum?.type)) ? 'exposures' : [2].includes(parseInt(dataMuseum?.type)) ? 'collection' : 'series'))}</h1> */}
					<h1>{t('settingsPage.hall')}</h1>
				</Box>
                <HallsSettings open={open} setOpen={setOpen} textAlert={textAlert} setTextAlert={setTextAlert} setColor={setColor} />
			</Box>
		</Layout>
	);
};
