import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';
import { nanoid } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export interface User {
	id: number;
	login: string;
}

export interface UpdateSettings {
	id: number;
	key: string;
	value: string;
	userId: number;
}

export interface Request {
	userId: number;
}

export const api = createApi({
	baseQuery: customBaseQuery,
	endpoints: builder => ({
		exhibits: builder.mutation<[], void>({
			query: () => ({
				url: `/exhibits`,
				method: 'GET',
			}),
		}),
		delExhibits: builder.mutation({
			query: id => ({
				url: `/exhibits/${id}`,
				method: 'DELETE',
			}),
		}),
		getExhibit: builder.mutation({
			query: id => ({
				url: `/exhibits/${id}`,
				method: 'GET',
			}),
		}),
		editExhibit: builder.mutation({
			query: ({ id, data }) => ({
				url: `/exhibits/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		getMemory: builder.mutation<any, void>({
			query: () => ({
				url: `/exhibits/memory`,
				method: 'GET',
			}),
		}),
		addExhibit: builder.mutation({
			queryFn: async data => {
				const res = await axios.post(`${process.env.REACT_APP_API_URL}/exhibits`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + Cookies.get('refreshToken'),
					},
				});

				return res.data;
			},
		}),
		updateImg: builder.mutation({
			queryFn: async ({ id, data }) => {
				const res = await axios.post(`${process.env.REACT_APP_API_URL}/exhibits/updatePhotos/${id}`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + Cookies.get('refreshToken'),
					},
				});

				return res.data;
			},
		}),
	}),
});

export const { useExhibitsMutation, useDelExhibitsMutation, useGetExhibitMutation, useEditExhibitMutation, useAddExhibitMutation, useGetMemoryMutation, useUpdateImgMutation } = api;
