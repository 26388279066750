import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import CssBaseline from '@mui/joy/CssBaseline';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../app/services/auth';
import ColorSchemeToggle from '../../utils/ColorSchemaToggle';
import customTheme from '../../utils/theme';
import { selectCurrentPlan, setCredentials } from './authSlice';
import { Alert } from '../../utils/Alert';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Menu, MenuItem } from '@mui/joy';
import * as CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { getToken, saveToStorage, saveToken } from '../../app/utils';
import { useGetCurrentPlanMutation, useProfileMutation } from '../../app/services/user';
import { useSelector } from 'react-redux';

interface FormElements extends HTMLFormControlsCollection {
	login: HTMLInputElement;
	password: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
	readonly elements: FormElements;
}

export default function Login() {
	const [login, { isLoading, isError, isSuccess, data, error }] = useLoginMutation();
	const [open, setOpen] = useState(false);
	const [textError, setTextError] = useState('');
	const [getUser, { data: user_data2, isLoading: user_loading, isError: userError, isSuccess: userIsSuccess }] = useProfileMutation();
	const [getCurrentPlan, { data: plan_data, isLoading: plan_loading }] = useGetCurrentPlanMutation();
	const thisPlan = useSelector(selectCurrentPlan);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open2 = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (!thisPlan) {
			getCurrentPlan();
		}
	}, [getCurrentPlan]);

	useEffect(() => {
		if (window.location.search) {
			const urlParams = new URLSearchParams(window.location.search);
			const myParam = urlParams.get('stc');
			const to = urlParams.get('to');
			if (myParam) {
				const encrypt = CryptoJS.AES.decrypt(myParam, process.env.SECRET_KEY_REF || 'secret');
				const token = encrypt.toString(CryptoJS.enc.Utf8);

				if (token) {
					saveToken(token);
					localStorage.removeItem('user');

					if (to) {
						navigate('/' + to);
					} else {
						navigate('/settings');
					}
					window.location.reload();
				}
			}
		}
	}, []);

	useEffect(() => {
		// @ts-ignore
		const err = error ? JSON.parse(error?.data) : null;
		if (isError || err) {
			if (err?.error === 'Not Found') {
				setTextError(t('errors.user_not_found') || 'Помилка');
				setOpen(true);
			} else {
				setTextError(t('errors.login_incorrect') || 'Помилка');
				setOpen(true);
			}
		}
	}, [isError, error]);

	return (
		<CssVarsProvider defaultMode="light" disableTransitionOnChange theme={customTheme}>
			<CssBaseline />
			<GlobalStyles
				styles={{
					':root': {
						'--Transition-duration': '0.4s',
					},
				}}
			/>
			<Alert open={open} setOpen={setOpen} text={textError} />
			<Box
				sx={(theme: any) => ({
					width: '100vw',
					transition: 'width var(--Transition-duration)',
					transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
					position: 'relative',
					zIndex: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backdropFilter: 'blur(4px)',
					backgroundColor: 'rgba(255 255 255 / 0.6)',
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'rgba(19 19 24 / 0.4)',
					},
				})}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100dvh',
						width: '100%',
						maxWidth: '100%',
						px: 2,
					}}>
					<Box
						component="header"
						sx={{
							py: 3,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Link to={process.env.REACT_APP_MAIN_URL || '/'}>
							<Typography
								fontWeight="lg"
								startDecorator={
									// <Box
									// 	component="span"
									// 	sx={{
									// 		width: 24,
									// 		height: 24,
									// 		background: (theme: { vars: { palette: { primary: { solidBg: any; softBg: any } } } }) => `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
									// 		borderRadius: '50%',
									// 		boxShadow: (theme: { shadow: { md: any } }) => theme.shadow.md,
									// 		'--joy-shadowChannel': (theme: { vars: { palette: { primary: { mainChannel: any } } } }) => theme.vars.palette.primary.mainChannel,
									// 	}}
									// />
									<img src="favicon.ico" width={50} height={50} />
								}>
								{/* ARTCENTR */}
							</Typography>
						</Link>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
							<IconButton sx={{ ml: '5px', display: { xs: 'none', md: 'inline-flex' } }} id="basic-demo-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClick}>
								<LanguageIcon />
							</IconButton>
							<Menu id="basic-menu" anchorEl={anchorEl} open={open2} onClose={handleClose} aria-labelledby="basic-demo-button">
								<MenuItem
									onClick={() => {
										i18n.changeLanguage('en');
									}}>
									English
								</MenuItem>
								<MenuItem
									onClick={() => {
										i18n.changeLanguage('uk');
									}}>
									Українська
								</MenuItem>
							</Menu>
							<ColorSchemeToggle />
						</Box>
					</Box>
					<Box
						component="main"
						sx={{
							my: 'auto',
							py: 2,
							pb: 5,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							width: 400,
							maxWidth: '100%',
							mx: 'auto',
							borderRadius: 'sm',
							'& form': {
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							},
							[`& .${formLabelClasses.asterisk}`]: {
								visibility: 'hidden',
							},
						}}>
						<div>
							<Typography component="h2" fontSize="xl2" fontWeight="lg">
								{t('authPage.welcome')}
							</Typography>
						</div>
						<form
							onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
								event.preventDefault();
								const formElements = event.currentTarget.elements;
								const data = {
									login: formElements.login.value,
									password: formElements.password.value,
								};

								const user = await login({
									login: data.login,
									password: data.password,
								}).unwrap();

								if (user.user.role !== 'guest') {
									dispatch(setCredentials(user));

									navigate('/settings');
								} else {
									setTextError(t('errors.role_forbidden') || 'Помилка');
									setOpen(true);
								}
							}}>
							<FormControl required>
								<FormLabel>{t('authPage.login')}</FormLabel>
								<Input placeholder={t('authPage.login') || 'Логін'} type="text" name="login" />
							</FormControl>
							<FormControl required>
								<FormLabel>{t('authPage.password') || 'Пароль'}</FormLabel>
								<Input placeholder="•••••••" type="password" name="password" />
							</FormControl>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
								}}>
								<Link to="/register" style={{ textDecoration: 'none' }}>
									<Typography sx={{ color: 'text.secondary' }}>{t('authPage.registerButton' || 'Зареєструватися')}</Typography>
								</Link>
								<Link to="/forgot-password" style={{ textDecoration: 'none' }}>
									<Typography sx={{ color: 'text.secondary' }}>{t('authPage.forgotPasswordButton' || 'Забули пароль?')}</Typography>
								</Link>
							</Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}></Box>
							<Button type="submit" fullWidth disabled={isLoading}>
								{isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('authPage.loginButton') || 'Увійти'}
							</Button>
						</form>
					</Box>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
