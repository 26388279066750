import Box from '@mui/joy/Box';
import Layout from '../features/components/Layout';
import { Alert } from '../utils/Alert';
import { useEffect, useRef, useState } from 'react';
import { useGetAllPlansMutation, useGetDataForPaymentMutation } from '../app/services/user';
import { AspectRatio, Button, Card, CardContent, IconButton, Modal, ModalClose, Select, Option, Sheet, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPlan } from '../features/auth/authSlice';
import { useExhibitsMutation } from '../app/services/exhibits';
import { setSecondBar } from '../features/sidebars/barSlice';
import Cookies from 'js-cookie';

const ModalPay = ({ isModalOpen, setIsModalOpen, currentPlan }: { isModalOpen: any; setIsModalOpen: (isOpen: any) => void; currentPlan: any }) => {
	const { t } = useTranslation();
	const [planDuration, setPlanDuration] = useState('m');
	const [getDataForPayment, { data, isSuccess, isLoading, isError }] = useGetDataForPaymentMutation();

	useEffect(() => {
		if (isSuccess) {
			window.location.href = data.pageUrl;
		}
	}, [isSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['view']} onClose={() => setIsModalOpen({ ...isModalOpen, view: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 99999999999 }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: 'auto',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					План: {t('plans.' + currentPlan?.name)}
				</Typography>
				<Stack spacing={2}>
					{currentPlan?.name === 'free' ? (
						<>
							<Button
								variant="solid"
								color="primary"
								sx={{ width: '100%' }}
								onClick={() => {
									getDataForPayment({
										planId: currentPlan?.id,
										subscribe_periodicity: planDuration === 'y' ? 'year' : planDuration === 'm' ? 'month' : 'year',
									});
								}}
								disabled={isLoading}>
								{isLoading ? 'Завантаження...' : 'Оплатити'}
							</Button>
						</>
					) : (
						<>
							<Typography>Оберіть період підписки</Typography>
							<Select
								defaultValue={planDuration || 'm'}
								// value={planDuration}
								onChange={(e, nV) => {
									setPlanDuration(nV as string);
								}}
								sx={{ width: '100%' }}>
								<Option value="y">Рік</Option>
								<Option value="m">Місяць</Option>
							</Select>

							{isLoading ? (
								<Typography>Завантаження...</Typography>
							) : (
								<>
									{['y', 'm'].includes(planDuration) && (
										<Button
											variant="solid"
											color="primary"
											sx={{ width: '100%' }}
											onClick={() => {
												getDataForPayment({
													planId: currentPlan?.id,
													subscribe_periodicity: planDuration === 'y' ? 'year' : planDuration === 'm' ? 'month' : 'year',
												});
											}}>
											{isLoading ? 'Завантаження...' : 'Оплатити'}
										</Button>
									)}
								</>
							)}
						</>
					)}
				</Stack>
			</Sheet>
		</Modal>
	);
};

export default function Upgrade() {
	const currentPlan = useSelector(selectCurrentPlan);
	const [selectedPlan, setSelectedPlan] = useState<any>(null);
	const [open, setOpen] = useState(false);
	const [textAlert, setTextAlert] = useState('');
	const [color, setColor] = useState('success');
	const [museum, setMuseum] = useState<any>(null);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [isModalOpen, setIsModalOpen] = useState({
		view: false,
	});

	const [getAllExhibits, { data: exhibitsData, isLoading: exhibitsIsLoading, isError: exhibitsIsError }] = useExhibitsMutation();
	const [getAllPlans, { data, isLoading, isError }] = useGetAllPlansMutation();

	useEffect(() => {
		getAllPlans();
		getAllExhibits();
		dispatch(
			setSecondBar({
				key: 'upgrade',
				name: 'Підписка',
				icon: 'Upgrade',
			}),
		);
	}, [getAllPlans, getAllExhibits]);

	useEffect(() => {
		setMuseum(JSON.parse(Cookies.get('museum') || '{}'));
	}, []);

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<ModalPay isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} currentPlan={selectedPlan} />
			<Box sx={{ width: '100%' }}>
				<Stack direction="row" sx={{ flexWrap: 'wrap' }}>
					{data?.map((plan: any) => (
						<Card key={plan} variant="outlined" sx={{ maxWidth: '320px', margin: '7px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', border: `${currentPlan?.plan?.id === plan.id ? '1px solid green' : 'none'}` }}>
							<div>
								<Typography level="h2" fontSize="md">
									{
										// @ts-ignore
										t(`plans.${plan.name}`)
									}
								</Typography>
							</div>
							<div
								style={{
									margin: '15px 0',
								}}>
								<Typography>
									{
										// @ts-ignore
										t(`description_plans.${plan.name}`)
									}
									<br />
									Експонати - до {plan.description.exhibits?.max} експонатів <br />
									{[0, 1, 3].includes(Number(museum?.type)) && (
										<>
											Події - до {plan.description.events?.max} подій/міс <br />
											Екскурсії - до 3-х (комісія сервісу з екскурсії/події {plan.description?.commission}%)
										</>
									)}
								</Typography>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}>
								<div>
									<Typography fontSize="lg" fontWeight="lg">
										Ціна: {plan.description.price?.month || plan.description.price} UAH / місяць <br></br> {plan.description.price?.year ? `${plan.description.price?.year} UAH / рік` : ''}
									</Typography>
								</div>
								<Button
									variant="solid"
									size="sm"
									color="primary"
									sx={{ ml: 'auto', mt: 1, fontWeight: 600 }}
									disabled={currentPlan?.plan?.id === plan.id || (exhibitsData?.length !== undefined && exhibitsData.length > 0 ? exhibitsData?.length >= plan?.description?.exhibits?.max || exhibitsData?.length < plan?.description?.exhibits?.min : false)}
									onClick={() => {
										setSelectedPlan(plan);
										setIsModalOpen({ ...isModalOpen, view: true });
									}}>
									{currentPlan?.plan?.id === plan.id ? 'Поточний' : exhibitsData?.length !== undefined && exhibitsData.length > 0 ? (exhibitsData?.length >= plan?.description?.exhibits?.max || exhibitsData?.length < plan?.description?.exhibits?.min ? 'Ліміт' : 'Обрати') : 'Обрати'}
								</Button>
							</div>
						</Card>
					))}
				</Stack>
			</Box>
		</Layout>
	);
}
