import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';

export interface User {
	id: number;
	login: string;
}

export interface UserResponse {
	user: User;
	refreshToken: string;
}

export interface LoginRequest {
	refreshToken: string;
}

export interface UpdateProfile {
	id: number;
	login: string;
	role: string;
	name?: string;
	phone?: string;
	oldPassword?: string;
	password?: string;
}

export const apiUser = createApi({
	baseQuery: customBaseQuery,
	reducerPath: 'apiUser',
	endpoints: builder => ({
		profile: builder.mutation<any, void>({
			query: () => ({
				url: '/user/profile',
				method: 'GET',
			}),
		}),
		updateProfile: builder.mutation({
			query: data => ({
				url: '/user/profile',
				method: 'PUT',
				body: data,
			}),
		}),
		getCurrentPlan: builder.mutation<any, void>({
			query: () => ({
				url: '/plan/current-plan',
				method: 'GET',
			}),
		}),
		getAllPlans: builder.mutation<any, void>({
			query: () => ({
				url: '/plan',
				method: 'GET',
			}),
		}),
		getDataForPayment: builder.mutation({
			query: data => ({
				url: '/client/pay/plan',
				method: 'POST',
				body: data,
			}),
		}),
		getNotifications: builder.query<any, void>({
			query: () => ({
				url: '/client/notification',
				method: 'GET',
			}),
		}),
		readAllNotifications: builder.mutation<any, void>({
			query: () => ({
				url: `/client/notification/read`,
				method: 'PUT',
			}),
		}),
	}),
});

export const { useReadAllNotificationsMutation, useGetNotificationsQuery, useProfileMutation, useUpdateProfileMutation, useGetCurrentPlanMutation, useGetAllPlansMutation, useGetDataForPaymentMutation } = apiUser;
