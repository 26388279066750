import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

export const customBaseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: headers => {
		const token = Cookies.get('refreshToken');
		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		}
		return headers;
	},
	
	validateStatus: (response) => {
		if (response.status === 401) {
			Cookies.remove('refreshToken');
		}
		return response.status < 500;
	},

	// responseHandler: response => {
	// 	if (response.ok) {
	// 		return response.json();
	// 	}
	// 	else {
	// 		console.log("Asd")
	// 	}

	// 	console.log(response.status)

	// 	if (response.status === 401) {
	// 		removeUser();
	// 	}

	// 	return response.json().then(data => {
	// 		console.log(data)
	// 		return Promise.reject(data);
	// 	});
	// }
});

export const saveToken = (token: string) => {
	Cookies.set('refreshToken', token);
};

export const removeUser = () => {
	Cookies.remove('refreshToken');
    localStorage.removeItem('user');
};

export const getToken = () => {
	return Cookies.get('refreshToken') || null;
};

export const isTokenExpired = () => {
	const token = getToken();
	console.log(token)
	if (!token) {
		return true;
	}

	const [, payload] = token.split('.');
	const payloadObj = JSON.parse(atob(payload));
	const exp = new Date(payloadObj.exp * 1000);
	const now = new Date();

	return now > exp;
};

export const saveToStorage = (data: object) => {
	localStorage.setItem('user', JSON.stringify(data));
};

export const getFromStorage = () => JSON.parse(localStorage.getItem('user') || '{}') || null;
