import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import CssBaseline from '@mui/joy/CssBaseline';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ColorSchemeToggle from '../../utils/ColorSchemaToggle';
import customTheme from '../../utils/theme';
import { setCredentials } from './authSlice';
import { Alert } from '../../utils/Alert';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Menu, MenuItem } from '@mui/joy';
import { useForgotPasswordMutation } from '../../app/services/auth';

interface FormElements extends HTMLFormControlsCollection {
	email: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
	readonly elements: FormElements;
}

export default function ForgotPassword() {
	const [forgot, { data, isLoading, isError, isSuccess, error }] = useForgotPasswordMutation();
	const [open, setOpen] = useState(false);
	const [textError, setTextError] = useState('');
	const [color, setColor] = useState<string>('danger');
	const [disabled, setDisabled] = useState(false);
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open2 = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (data) {
			if (data?.statusCode) {
				if (data?.statusCode !== 200) {
					setColor('danger');
					setTextError(t('errors.error') || 'Помилка');
					setOpen(true);
					setDisabled(true);
					setTimeout(() => {
						setDisabled(false);
					}, 3000);
				}
			} else {
				setColor('success');
				setTextError(t('authPage.checkEmail') || 'Перевірте пошту');
				setOpen(true);
				setDisabled(true);
				setTimeout(() => {
					navigate('/login');
				}, 3000);
			}
		}
	}, [data]);

	// useEffect(() => {
	// 	if (isError && error) {
	// 		setColor('danger');
	// 		setTextError(t('errors.error') || 'Помилка');
	// 		setOpen(true);
	// 	}
	// }, [isError, error]);

	// useEffect(() => {
	// 	if (isSuccess && !error) {
	// 		setDisabled(true);
	// 		setColor('success');
	// 		setTextError(t('authPage.checkEmail') || 'Перевірте пошту');
	// 		setOpen(true);
	// 		navigate('/login');
	// 	}
	// }, [isSuccess, error]);

	return (
		<CssVarsProvider defaultMode="light" disableTransitionOnChange theme={customTheme}>
			<CssBaseline />
			<GlobalStyles
				styles={{
					':root': {
						'--Transition-duration': '0.4s',
					},
				}}
			/>
			<Alert open={open} setOpen={setOpen} text={textError} color={color} />
			<Box
				sx={(theme: any) => ({
					width: '100vw',
					transition: 'width var(--Transition-duration)',
					transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
					position: 'relative',
					zIndex: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backdropFilter: 'blur(4px)',
					backgroundColor: 'rgba(255 255 255 / 0.6)',
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'rgba(19 19 24 / 0.4)',
					},
				})}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100dvh',
						width: '100%',
						maxWidth: '100%',
						px: 2,
					}}>
					<Box
						component="header"
						sx={{
							py: 3,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Link to={process.env.REACT_APP_MAIN_URL || '/'}>
							<Typography
								fontWeight="lg"
								startDecorator={
									// <Box
									// 	component="span"
									// 	sx={{
									// 		width: 24,
									// 		height: 24,
									// 		background: (theme: { vars: { palette: { primary: { solidBg: any; softBg: any } } } }) => `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
									// 		borderRadius: '50%',
									// 		boxShadow: (theme: { shadow: { md: any } }) => theme.shadow.md,
									// 		'--joy-shadowChannel': (theme: { vars: { palette: { primary: { mainChannel: any } } } }) => theme.vars.palette.primary.mainChannel,
									// 	}}
									// />
									<img src="favicon.ico" width={50} height={50} />
								}>
								{/* ARTCENTR */}
							</Typography>
						</Link>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
							<IconButton sx={{ ml: '5px', display: { xs: 'none', md: 'inline-flex' } }} id="basic-demo-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClick}>
								<LanguageIcon />
							</IconButton>
							<Menu id="basic-menu" anchorEl={anchorEl} open={open2} onClose={handleClose} aria-labelledby="basic-demo-button">
								<MenuItem
									onClick={() => {
										i18n.changeLanguage('en');
									}}>
									English
								</MenuItem>
								<MenuItem
									onClick={() => {
										i18n.changeLanguage('uk');
									}}>
									Українська
								</MenuItem>
							</Menu>
							<ColorSchemeToggle />
						</Box>
					</Box>
					<Box
						component="main"
						sx={{
							my: 'auto',
							py: 2,
							pb: 5,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							width: 400,
							maxWidth: '100%',
							mx: 'auto',
							borderRadius: 'sm',
							'& form': {
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							},
							[`& .${formLabelClasses.asterisk}`]: {
								visibility: 'hidden',
							},
						}}>
						<div>
							<Typography component="h2" fontSize="xl2" fontWeight="lg">
								{t('authPage.forgotPassword')}
							</Typography>
						</div>
						<form
							onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
								event.preventDefault();
								const formElements = event.currentTarget.elements;
								const data = {
									email: formElements.email.value,
								};

								await forgot({
									email: data.email,
								}).unwrap();
							}}>
							<FormControl required>
								<FormLabel>{t('authPage.email')}</FormLabel>
								<Input placeholder={t('authPage.email') || 'Email'} type="text" name="email" />
							</FormControl>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}></Box>
							<Button type="submit" fullWidth disabled={isLoading || disabled}>
								{isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('authPage.send') || 'Відправити'}
							</Button>
						</form>
					</Box>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
