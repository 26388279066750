import { Box, Input, Select, Option, Button, CircularProgress, Tabs, TabList, Tab, TabPanel, Textarea, Typography, AspectRatio } from '@mui/joy';
import Layout from '../features/components/Layout';
import { UpdateSettings, useGetCitiesMutation, useGetMuseumMutation, useSettingsMutation, useUpdateMuseumMutation, useUpdateSettingsMutation, useUploadBannerMutation } from '../app/services/museum';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../utils/Alert';
import { UpdateProfile, useProfileMutation, useUpdateProfileMutation } from '../app/services/user';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import { Loading } from '../utils/Loading';
import { TimeWork } from '../features/components/TimeWork';
import ImageUploader from '../utils/ImageUploader.';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { getFromStorage, getToken } from '../app/utils';
import * as CryptoJS from 'crypto-js';
import { BtnRedirectMain } from '../utils/BtnRedirectMain';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	textAlert: string;
	setTextAlert: (text: string) => void;
	setColor: (color: string) => void;
}

let tempSettings: any;
let tempMuseum: any;

const PageSettings = forwardRef(({ open, setOpen, textAlert, setTextAlert, setColor }: Props) => {
	const [settings, { data, isLoading }] = useSettingsMutation();
	const [setSettings, setsettingsData] = useUpdateSettingsMutation();

	const [museum, { data: dataMuseum, isLoading: isLoadingMuseum }] = useGetMuseumMutation();
	const [updateMuseum, { isError: isErrorMuseum, isSuccess: isSuccessMuseum }] = useUpdateMuseumMutation();
	const [uploadBanner, { isError: isErrorBanner, isSuccess: isSuccessBanner }] = useUploadBannerMutation();
	const [getCities, { data: cities }] = useGetCitiesMutation();

	const [tempValue, setTempValue] = useState<UpdateSettings[]>([]);
	const [tempValueMuseum, setTempValueMuseum] = useState<any>();

	const [museumStatus, setMuseumStatus] = useState(0);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [desc, setDesc] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('card');
	const descRef = useRef<any>();

	const { t } = useTranslation();

	useEffect(() => {
		if (isSuccessBanner) {
			tempSettings = null;
			tempMuseum = null;
		}
	}, [isSuccessMuseum]);

	useEffect(() => {
		if (tempSettings && tempSettings?.length > 0) {
			setTempValue(tempSettings);
			setPaymentMethod(tempSettings?.paymentMethod || 'card');
			setDesc(tempSettings.filter((item: any) => item.key === 'museumInfo')[0]?.value);
		}

		if (tempMuseum) {
			setTempValueMuseum(tempMuseum);
		}

		getCities();
	}, []);

	// useEffect(() => {

	// }, [desc]);

	useEffect(() => {
		(async () => {
			await settings();
		})();
	}, [settings]);

	useEffect(() => {
		if (data && data.length > 0) {
			if (tempSettings && tempSettings?.length === 0) setTempValue(data);
			// @ts-ignore
			if (tempSettings && tempSettings?.length === 0) setDesc(data.filter((item: any) => item.key === 'museumInfo')[0]?.value);
		}
	}, [data]);

	useEffect(() => {
		(async () => {
			await museum();
		})();
	}, [museum]);

	useEffect(() => {
		if (dataMuseum) {
			const temp = { ...dataMuseum };
			// delete temp.id;
			delete temp.dateUpdated;
			delete temp.users;

			if (!tempMuseum) setTempValueMuseum(temp);
			if (!tempSettings) setPaymentMethod(tempSettings?.paymentMethod || 'card');

			Cookies.set('museum', JSON.stringify(temp));
		}
	}, [dataMuseum]);

	useEffect(() => {
		if (tempValue) {
			setMuseumStatus(getMuseumStatus(tempValue));
			tempSettings = tempValue;
		}
	}, [tempValue]);

	useEffect(() => {
		if (tempValueMuseum) {
			tempMuseum = tempValueMuseum;
		}
	}, [tempValueMuseum]);

	useEffect(() => {
		if (setsettingsData.isError) {
			setColor('danger');
			setTextAlert(t('errors.update_error') || '');
			setOpen(true);
		}
		if (setsettingsData.isSuccess) {
			setColor('success');
			setTextAlert(t('success.date_updated') || '');
			setOpen(true);
		}
	}, [setsettingsData.isError, setsettingsData.isSuccess, setOpen, setTextAlert, setColor]);

	const getMuseumStatus = (data: any) => {
		return data.filter((item: any) => {
			return item.key === 'museumStatus';
		})[0]?.value;
	};

	const convertPhoneNumber = (inp: string) => {
		if (isValidPhoneNumber(inp, 'UA')) {
			const phoneNumber = parsePhoneNumber(inp, 'UA');
			return phoneNumber.formatInternational();
		}
		return inp;
	};

	const isValidInputs = (inputs: any[]) => {
		for (let i = 0; i < inputs.length; i++) {
			if (inputs[i].key.endsWith('Phone')) {
				if (!isValidPhoneNumber(inputs[i].value, 'UA')) {
					setColor('danger');
					setTextAlert(t('errors.phone_incorrect') + ' (' + t('settingsPage.' + inputs[i].key) + ')');
					setOpen(true);

					return false;
				}
			} else {
				if (['link', 'photo', 'museumInfo', 'cardNumber', 'edrpou'].includes(inputs[i].key)) continue;

				if (inputs[i].key === 'iban') {
					const method = inputs.filter((item: any) => item.key === 'paymentMethod')[0]?.value;

					if (method !== 'card') {
						if (inputs[i].value !== '') {
							const edrpou = inputs.filter((item: any) => item.key === 'edrpou')[0]?.value;

							if (edrpou?.length === 0 || edrpou === '') {
								setColor('danger');
								setTextAlert(t('errors.empty_field') + ' (' + t('settingsPage.edrpou') + ')');
								setOpen(true);

								return false;
							} else {
								continue;
							}
						} else {
							continue;
						}
					}
				} else {
					if (inputs[i].value === '') {
						setColor('danger');
						setTextAlert(t('errors.empty_field') + ' (' + t('settingsPage.' + inputs[i].key) + ')');
						setOpen(true);

						return false;
					}
				}
			}
		}

		return inputs;
	};

	const setTimeWork = (data: any) => {
		setTempValue(
			Object.assign([], tempValue, {
				[tempValue.findIndex((item: any) => {
					return item.key === 'timeWork';
				})]: {
					...tempValue[
						tempValue.findIndex((item: any) => {
							return item.key === 'timeWork';
						})
					],
					value: JSON.stringify(data),
				},
			}),
		);
	};

	// const handleChange = debounce((item: any, newValue: any) => {
	// 	setTempValue(prevObject => {
	// 		const updatedObject = prevObject.map(obj => (obj.id === item.id ? { ...obj, value: newValue } : obj));
	// 		return updatedObject;
	// 	});
	// }, 300);

	const handleChange = (item: any, newValue: any) => {
		setTempValue(prevObject => {
			const updatedObject = prevObject.map(obj => (obj.id === item.id ? { ...obj, value: newValue } : obj));
			return updatedObject;
		});
	};

	const debounced = useDebouncedCallback(value => {
		setDesc(value);
	}, 700);

	const renderInput = (item: any) => {
		if (item.key.endsWith('Phone')) {
			return (
				<Input
					type="text"
					defaultValue={(item.value === '' ? '+380' : '') + item.value}
					onChange={e => {
						if (!e.target.value.startsWith('+380')) {
							e.target.value = '+380';
						}

						// if (convertPhoneNumber(e.target.value)) {
						// 	e.target.value = convertPhoneNumber(e.target.value);
						// }

						const newValue = e.target.value;
						setTempValue(prevObject => {
							const updatedObject = [...prevObject];
							const index = updatedObject.findIndex(obj => obj.id === item.id);
							if (index !== -1) {
								updatedObject[index] = { ...item, value: convertPhoneNumber(newValue) };
							}
							return updatedObject;
						});
					}}
				/>
			);
		}

		switch (item.key) {
			case 'city':
				return (
					<Select
						defaultValue={item.value}
						placeholder={t('settingsPage.city') || 'city'}
						onChange={(event: any, newValue: any) => {
							setTempValue(prevObject => {
								const updatedObject = [...prevObject];
								const index = updatedObject.findIndex(obj => obj.id === item.id);
								if (index !== -1) {
									updatedObject[index] = { ...item, value: newValue };
								}
								return updatedObject;
							});
						}}>
						{cities?.map((item: any, index: number) => {
							return (
								<Option key={item.id} value={item.title}>
									{item.title}
								</Option>
							);
						})}
					</Select>
				);
			case 'museumDirection':
				return (
					<Select
						defaultValue={parseInt(tempValue.filter((item: any) => item.key === 'museumDirection')[0].value)}
						onChange={(e: any, newValue: any) => {
							setTempValue(prevObject => {
								const updatedObject = [...prevObject];
								const index = updatedObject.findIndex(obj => obj.id === item.id);
								if (index !== -1) {
									updatedObject[index] = { ...item, value: newValue };
								}
								return updatedObject;
							});
						}}>
						{
							// @ts-ignore
							t('settingsPage.museumDirections', { returnObjects: true })[parseInt(tempMuseum?.type || tempValueMuseum?.type) || 0]?.map((item: any, index: number) => {
								return (
									<Option key={index} value={index}>
										{item}
									</Option>
								);
							})
						}
					</Select>
				);

			case 'museumStatus':
				return (
					<Select
						defaultValue={parseInt(tempValue.filter((item: any) => item.key === 'museumStatus')[0].value)}
						onChange={(e: any, newValue: any) => {
							setTempValue(prevObject => {
								const updatedObject = [...prevObject];
								const index = updatedObject.findIndex(obj => obj.id === item.id);
								if (index !== -1) {
									updatedObject[index] = { ...item, value: newValue };
								}
								return updatedObject;
							});
						}}>
						{
							// @ts-ignore
							t('settingsPage.museumStatuss', { returnObjects: true }).map((item: any, index: number) => {
								return (
									<Option key={index} value={index}>
										{item}
									</Option>
								);
							})
						}
					</Select>
				);
			case 'photo':
				return (
					<>
						{selectedFile ? (
							<> </>
						) : item.value ? (
							<AspectRatio ratio={2 / 1} sx={{ maxWidth: '500px' }}>
								<LazyLoadImage src={item?.value} />
							</AspectRatio>
						) : (
							<Typography></Typography>
						)}
						<ImageUploader setSelectedFile={setSelectedFile} />

						{item.value ? (
							<Button
								variant="outlined"
								sx={{ marginTop: '10px' }}
								onClick={() => {
									setTempValue(prevObject => {
										const updatedObject = [...prevObject];
										const index = updatedObject.findIndex(obj => obj.id === item.id);
										if (index !== -1) {
											updatedObject[index] = { ...item, value: '' };
										}
										return updatedObject;
									});
								}}>
								{t('settingsPage.delete')}
							</Button>
						) : (
							<></>
						)}
					</>
				);
			case 'timeWork':
				return <TimeWork time_work={JSON.parse(item?.value)} setTimeWork={setTimeWork} />;
			default:
				return (
					<Input
						type="text"
						value={item.value}
						onChange={e => {
							const newValue = e.target.value;
							setTempValue(prevObject => {
								const updatedObject = [...prevObject];
								const index = updatedObject.findIndex(obj => obj.id === item.id);
								if (index !== -1) {
									updatedObject[index] = { ...item, value: newValue };
								}
								return updatedObject;
							});
						}}
					/>
				);
		}
	};

	return (
		<div>
			{isLoading || isLoadingMuseum ? (
				<Loading />
			) : (
				<div>
					{tempValueMuseum && (
						<>
							<div>
								<p>{t('settingsPage.museumName')}</p>

								<Typography sx={{ mb: 1, color: `${tempValueMuseum?.name?.length > 60 ? 'red' : ''}` }}>{tempValueMuseum?.name?.length} / 60</Typography>
								<Input
									type="text"
									value={tempValueMuseum.name}
									onChange={e => {
										setTempValueMuseum({
											...tempValueMuseum,
											name: e.target.value,
										});
									}}
								/>
							</div>

							<div>
								<p>{t('settingsPage.museumType')}</p>
								<Select
									defaultValue={parseInt(tempValueMuseum.type)}
									disabled
									onChange={(e: any, newValue: any) => {
										setTempValueMuseum({
											...tempValueMuseum,
											type: newValue,
										});
									}}>
									{
										// @ts-ignore
										t('settingsPage.museumTypes', { returnObjects: true }).map((item: any, index: number) => {
											return (
												<Option key={index} value={index}>
													{item}
												</Option>
											);
										})
									}
								</Select>
							</div>
						</>
					)}
					<div>
						<Typography sx={{ mb: 1, mt: 2, color: `${desc.length > 3000 ? 'red' : ''}` }}>{desc.length} / 3000</Typography>
						<Textarea
							color="neutral"
							minRows={5}
							maxRows={15}
							size="lg"
							variant="outlined"
							defaultValue={desc}
							onChange={e => {
								debounced(e.target.value);
							}}
						/>
					</div>
					{tempValue && (
						<>
							{tempValue?.map((item: any, index: number) => {
								if (['cardNumber', 'iban', 'edrpou', 'recipientName', 'paymentMethod', 'museumInfo'].includes(item.key)) return null;
								// if (item.key === 'museumStatus') return null;
								return (
									<div key={item.id}>
										<p>{t('settingsPage.' + item.key)}</p>
										{renderInput(item)}
									</div>
								);
							})}
						</>
					)}

					{[0, 1, 3].includes(parseInt(tempValueMuseum?.type)) ? (
						<Box>
							<Typography>{t('settingsPage.payment_data')}</Typography>

							<p>{t('settingsPage.paymentMethod')}</p>
							<Select
								defaultValue={tempValue.filter((item: any) => item.key === 'paymentMethod')[0]?.value}
								value={paymentMethod || 'card'}
								onChange={(e: any, newValue: any) => {
									setPaymentMethod(newValue);

									setTempValue(prevObject => {
										const updatedObject = [...prevObject];
										const index = updatedObject.findIndex(obj => obj.key === 'paymentMethod');
										if (index !== -1) {
											const item = updatedObject[index];
											updatedObject[index] = { ...item, value: newValue };
										} else {
											updatedObject.push({
												key: 'paymentMethod',
												value: newValue,
											});
										}
										return updatedObject;
									});
								}}>
								<Option value="card">{t('settingsPage.payment_method_card')}</Option>
								<Option value="iban">{t('settingsPage.payment_method_iban')}</Option>
							</Select>

							<div>
								<p>{t('settingsPage.recipientName')}</p>

								<Input
									type="text"
									value={tempValue?.filter((item: any) => item.key === 'recipientName')[0]?.value}
									onChange={e => {
										const newValue = e.target.value;
										const item = tempValue?.filter((item: any) => item.key === 'recipientName')[0];
										setTempValue(prevObject => {
											const updatedObject = [...prevObject];
											const index = updatedObject.findIndex(obj => obj.key === 'recipientName');
											if (index !== -1) {
												updatedObject[index] = { ...item, value: newValue };
											} else {
												updatedObject.push({
													key: 'recipientName',
													value: newValue,
												});
											}
											return updatedObject;
										});
									}}
								/>
							</div>

							{paymentMethod === 'card' ? (
								<div>
									<p>{t('settingsPage.cardNumber')}</p>
									<Input
										type="text"
										value={tempValue?.filter((item: any) => item.key === 'cardNumber')[0]?.value}
										onChange={e => {
											const newValue = e.target.value;
											const item = tempValue?.filter((item: any) => item.key === 'cardNumber')[0];
											setTempValue(prevObject => {
												const updatedObject = [...prevObject];
												const index = updatedObject.findIndex(obj => obj.key === 'cardNumber');
												if (index !== -1) {
													updatedObject[index] = { ...item, value: newValue };
												} else {
													updatedObject.push({
														key: 'cardNumber',
														value: newValue,
													});
												}
												return updatedObject;
											});
										}}
									/>
								</div>
							) : (
								<>
									<div>
										<p>{t('settingsPage.iban')}</p>
										<Input
											type="text"
											value={tempValue?.filter((item: any) => item.key === 'iban')[0]?.value}
											onChange={e => {
												const newValue = e.target.value;
												const item = tempValue?.filter((item: any) => item.key === 'iban')[0];
												setTempValue(prevObject => {
													const updatedObject = [...prevObject];
													const index = updatedObject.findIndex(obj => obj.key === 'iban');
													if (index !== -1) {
														updatedObject[index] = { ...item, value: newValue };
													} else {
														updatedObject.push({
															key: 'iban',
															value: newValue,
														});
													}
													return updatedObject;
												});
											}}
										/>
									</div>

									<div>
										<p>{t('settingsPage.edrpou')}</p>
										<Input
											type="text"
											value={tempValue?.filter((item: any) => item.key === 'edrpou')[0]?.value}
											onChange={e => {
												const newValue = e.target.value;
												const item = tempValue?.filter((item: any) => item.key === 'edrpou')[0];
												setTempValue(prevObject => {
													const updatedObject = [...prevObject];
													const index = updatedObject.findIndex(obj => obj.key === 'edrpou');
													if (index !== -1) {
														updatedObject[index] = { ...item, value: newValue };
													} else {
														updatedObject.push({
															key: 'edrpou',
															value: newValue,
														});
													}
													return updatedObject;
												});
											}}
										/>
									</div>
								</>
							)}
						</Box>
					) : (
						<></>
					)}

					<Button
						sx={{ mt: 2 }}
						onClick={() => {
							const nTemp = tempValue.map((item: any) => {
								if (item.key === 'museumInfo') {
									return { ...item, value: desc };
								}
								return item;
							});

							if (nTemp.filter((item: any) => item.key === 'museumInfo')[0]?.value.length > 3000) {
								setColor('danger');
								setTextAlert(t('errors.max_length') + ' (' + t('settingsPage.museumInfo') + ') - 3000');
								setOpen(true);
								return;
							}

							if (tempValueMuseum.name.length > 60) {
								setColor('danger');
								setTextAlert(t('errors.max_length') + ' (' + t('settingsPage.museumName') + ') - 60');
								setOpen(true);
								return;
							}

							const valid = isValidInputs(nTemp);
							if (!valid) return;

							setSettings(valid);
							updateMuseum({
								...tempValueMuseum,
								dateUpdated: new Date(),
							});

							if (selectedFile) {
								const formData = new FormData();
								formData.append('file', selectedFile);

								uploadBanner(formData);
							}
						}}
						disabled={tempValue?.some((item: any) => item.value === '') === true && (museumStatus === 1) === true}>
						{setsettingsData.isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('save')}
					</Button>
				</div>
			)}
		</div>
	);
});

const AccountSettings = ({ open, setOpen, textAlert, setTextAlert, setColor }: Props) => {
	const [updateProfile, updateData] = useUpdateProfileMutation();
	const [profile, profileData] = useProfileMutation();
	const [tempValue, setTempValue] = useState<UpdateProfile>();

	const { t } = useTranslation();

	useEffect(() => {
		(async () => {
			await profile();
		})();
	}, [profile]);

	useEffect(() => {
		if (profileData.data) {
			let temp = { ...profileData.data };

			delete temp.id;
			delete temp.role;

			delete temp.dateCreated;

			temp.oldPassword = undefined;
			temp.newPassword = undefined;

			setTempValue(temp);
		}
	}, [profileData.data]);

	useEffect(() => {
		if (updateData.isError) {
			setOpen(true);
			// @ts-ignore
			setTextAlert(updateData?.error?.data?.message || t('errors.update_error'));
			setColor('danger');
		}
		if (updateData.isSuccess) {
			setOpen(true);
			setTextAlert(t('success.date_updated') || '');
			setColor('success');
		}
	}, [updateData.isError, updateData.isSuccess, setOpen, setTextAlert, setColor]);

	return (
		<>
			{profileData.isLoading ? (
				<Loading />
			) : (
				<div>
					{
						//@ts-ignore
						tempValue !== undefined
							? Object.keys(tempValue).map((item: any, index: number) => {
									return (
										<div key={index}>
											<p>{t('authPage.' + item)}</p>
											<Input
												type={item.includes('Password') ? 'password' : 'text'}
												// @ts-ignore
												value={tempValue[item]}
												disabled={item === 'id' || item === 'dateCreated' || item === 'role'}
												onChange={e => {
													setTempValue(
														Object.assign({}, tempValue, {
															[item]: e.target.value,
														}),
													);
												}}
											/>
										</div>
									);
							  })
							: ''
					}
					<Button
						sx={{ mt: 2 }}
						onClick={async () => {
							await updateProfile(tempValue);
						}}>
						{updateData.isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('save')}
					</Button>
				</div>
			)}
		</>
	);
};

export default function Settings() {
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

	// useEffect(() => {
	// 	museum();
	// }, [museum]);

	const { t } = useTranslation();
	const location = useLocation();

	useEffect(() => {
		return () => {
			tempMuseum = null;
			tempSettings = null;
		};
	}, [location.pathname]);

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<Box sx={{ width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						// on mobile
						flexDirection: {
							xs: 'column',
							sm: 'row',
						},
						mb: {
							xs: '20px',
						},
					}}>
					<h1>{t('settingsPage.settings')}</h1>

					<BtnRedirectMain>
						<Button
							variant="solid"
							size="md"
							color="primary"
							// onClick={() => {
							// 	window.location.href = `${process.env.REACT_APP_MAIN_URL}/authed?museum=${dataMuseum?.url}&u=${keyForLogin}`;
							// }}
						>
							{t('settingsPage.openMainPage')}
						</Button>
					</BtnRedirectMain>
				</Box>
				<PageSettings open={open} setOpen={setOpen} textAlert={textAlert} setTextAlert={setTextAlert} setColor={setColor} />
				{/* <Tabs aria-label="Basic tabs" defaultValue={0} sx={{ borderRadius: 'lg' }}>
					<TabList
						sx={{
							flexWrap: 'wrap',
							justifyContent: 'center',
						}}>
						<Tab>{t('settingsPage.page')}</Tab>
						<Tab>{t('settingsPage.user')}</Tab>
					</TabList>
					<TabPanel value={0} sx={{ p: 2 }}></TabPanel>
					<TabPanel value={1} sx={{ p: 2 }}>
						<AccountSettings open={open} setOpen={setOpen} textAlert={textAlert} setTextAlert={setTextAlert} setColor={setColor} />
					</TabPanel>
				</Tabs> */}
			</Box>
		</Layout>
	);
}
