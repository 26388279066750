import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { User } from '../../app/services/auth';
import type { RootState } from '../../app/store';
import { removeUser } from '../../app/utils';

type AuthState = {
	user: User | null;
	refreshToken: string | null;
	currentPlan: any;
};

const slice = createSlice({
	name: 'auth',
	initialState: { user: null, refreshToken: null, currentPlan: null } as AuthState,
	reducers: {
		setCredentials: (state, { payload: { user, refreshToken, plan } }: PayloadAction<{ user: User; refreshToken: string, plan?: any }>) => {
			state.user = user;
			state.refreshToken = refreshToken;

			if (plan) {
				state.currentPlan = plan;
			}
		},
		logout: () => {
			removeUser();
			return { user: null, refreshToken: null, currentPlan: null } as AuthState;
		},
		setPlan: (state, { payload: { plan } }: PayloadAction<{ plan: any }>) => {
			state.currentPlan = plan;
		}
	},
});

export const { setCredentials, logout, setPlan } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentPlan = (state: RootState) => state.auth.currentPlan;
