import Layout from '../features/components/Layout';

export const NotFound = ({ layout = true }) => {
	return (
		<>
			{layout ? (
				<Layout>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100vh',
							width: '100vw',
							position: 'absolute',
							top: 0,
							left: 0,
							zIndex: -1,
						}}>
						<h1>404 Not Found</h1>
					</div>
				</Layout>
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
						width: '100vw',
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: -1,
					}}>
					<h1>404 Not Found</h1>
				</div>
			)}
		</>
	);
};
