import { AspectRatio, Button } from '@mui/joy';
import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Carousel from 'react-material-ui-carousel';
import { useDispatch } from 'react-redux';
import { Alert } from './Alert';
import { useTranslation } from 'react-i18next';

export default function ImagesUploader({ setSelectedFile }: { setSelectedFile: (file: File[] | null) => void }) {
	const [images, setImages] = useState<any>([]);
	const [open, setOpen] = useState(false);
	const [textAlert, setTextAlert] = useState('');
	const [color, setColor] = useState('success');

	const { t } = useTranslation();

	const handleFileSelect = (event: any) => {
		if (event.target.files.length > 0 && event.target.files.length <= 10) {
			setSelectedFile(event.target.files);

			const temp = [...event.target.files].map((file: any) => URL.createObjectURL(file));
			setImages(temp);
		} else {
			setTextAlert(t('errors.image_limit') || 'You can upload up to 10 images');
			setColor('danger');
			setOpen(true);
		}
	};

	return (
		<Stack spacing={4} direction="column" justifyContent={'center'}>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<Stack spacing={2} direction="row" alignItems={'center'} maxWidth={800}>
				{images.length > 0 ? (
					<>
						<Carousel
							animation="slide"
							indicators={false}
							cycleNavigation={false}
							autoPlay={true}
							navButtonsAlwaysVisible={true}
							stopAutoPlayOnHover
							sx={{
								width: '100%',
							}}>
							{images
								.map((image: any, index: number) => {
									if (index % 3 === 0) {
										const item = images.slice(index, index + 3).map((image: any, index: number) => {
											return (
												<AspectRatio ratio={1} sx={{ width: '100%' }} maxHeight={100}>
													<LazyLoadImage
														src={image}
														style={{
															marginLeft: '5px',
														}}
													/>
												</AspectRatio>
											);
										});

										if (item.length > 0) {
											return <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>{item}</Box>;
										}
									}
								})
								.filter((item: any) => item !== undefined)}
						</Carousel>
					</>
				) : (
					<></>
				)}

				<input accept=".png,.jpg,.jpeg" multiple style={{ display: 'none' }} id="image-file" type="file" onChange={handleFileSelect} />
			</Stack>

			<Stack spacing={2} direction="row" alignItems={'center'} sx={{ marginTop: '15px !important' }}>
				<label htmlFor="image-file">
					<Button color="primary" component="span">
						{t('exhibitsPage.addPhoto')}
					</Button>
				</label>
			</Stack>
		</Stack>
	);
}
