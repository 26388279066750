import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Layout from '../features/components/Layout';
import { useTranslation } from 'react-i18next';
import { CircularProgress, FormControl, FormLabel, Input, Modal, ModalClose, Stack } from '@mui/joy';
import { useAddGuideMutation, useDelGuideMutation, useEditGuideMutation, useGuidesMutation } from '../app/services/guides';
import { useEffect, useState } from 'react';
import Add from '@mui/icons-material/Add';
import { useGetHallsMutation } from '../app/services/halls';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItemText, MenuItem, Select, Checkbox } from '@mui/material';
import { Alert } from '../utils/Alert';
import { Loading } from '../utils/Loading';
import { useNavigate } from 'react-router-dom';
import { useGetMuseumMutation } from '../app/services/museum';
import { selectCurrentUser } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';

interface FormElements extends HTMLFormControlsCollection {
	name: HTMLInputElement;
	phone: HTMLInputElement;
	halls: HTMLInputElement;
}
interface GuideForm extends HTMLFormElement {
	readonly elements: FormElements;
}

const ModalUpdate = ({ currentItem, guides, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { currentItem: any; guides: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [updateGuide, { isSuccess: guideSuccess, isLoading: guideLoading }] = useEditGuideMutation();
	const [getHalls, { data: hallsData }] = useGetHallsMutation();

	const [selected, setSelected] = useState([]);
	const isAllSelected = hallsData?.length > 0 && selected.length === hallsData?.length;

	const { t } = useTranslation();

	useEffect(() => {
		getHalls();
	}, [isModalOpen['update'], getHalls]);

	useEffect(() => {
		if (currentItem) {
			setSelected(currentItem.halls.map((hall: any) => hall.id));
		}
	}, [currentItem]);

	useEffect(() => {
		if (guideSuccess) {
			setIsModalOpen({ ...isModalOpen, update: false });
			setOpen(true);
			setColor('success');
			setTextAlert(t('success.date_updated'));
			guides();
		}
	}, [guideSuccess]);

	const handleChange = (event: any) => {
		const value = event.target.value;
		if (value[value.length - 1] === 'all') {
			setSelected(selected.length === hallsData?.length ? [] : hallsData.map((hall: any) => hall.id));
			return;
		}
		setSelected(value);
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['update']} onClose={() => setIsModalOpen({ ...isModalOpen, update: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('guidesPage.add')}
				</Typography>
				<form
					onSubmit={async (event: React.FormEvent<GuideForm>) => {
						event.preventDefault();

						const { name, phone, halls } = event.currentTarget.elements;

						if (!isValidPhoneNumber(phone.value)) {
							setTextAlert(t('errors.phone_incorrect'));
							setColor('danger');
							setOpen(true);
							return;
						}

						if (halls.value.length === 0) {
							setTextAlert(t('errors.select_hall'));
							setColor('danger');
							setOpen(true);
							return;
						}

						const hallsIds = halls.value.split(',').map((item: any) => parseInt(item));

						const data = {
							name: name.value,
							phone: phone.value,
							halls: hallsIds,
						};

						await updateGuide({
							id: currentItem.id,
							data,
						}).unwrap();
					}}>
					<Stack spacing={2}>
						<FormControl>
							<FormLabel>{t('guidesPage.name')}</FormLabel>
							<Input autoFocus required name="name" defaultValue={currentItem?.name} />
						</FormControl>
						<FormControl>
							<FormLabel>{t('guidesPage.phone')}</FormLabel>
							<Input required name="phone" defaultValue={currentItem?.phone} />
						</FormControl>

						<FormControl>
							<FormLabel>{t('guidesPage.hall')}</FormLabel>
							<Select
								sx={{
									borderRadius: '10px',
								}}
								labelId="mutiple-select-label"
								multiple
								name="halls"
								value={selected}
								onChange={handleChange}
								renderValue={() => {
									if (selected.length === hallsData?.length) {
										return hallsData?.map((option: any) => option.name).join(', ');
									} else {
										return (
											hallsData
												// @ts-ignore
												?.filter((option: any) => selected.indexOf(option.id) > -1)
												.map((option: any) => option.name)
												.join(', ')
										);
									}
								}}>
								<MenuItem value="all">
									<ListItemIcon>
										<Checkbox checked={isAllSelected} indeterminate={selected.length > 0 && selected.length < hallsData?.length} />
									</ListItemIcon>
									<ListItemText primary={t('guidesPage.select_all') || 'Select all'} />
								</MenuItem>
								{hallsData?.map((option: any) => (
									<MenuItem key={option.id} value={option.id}>
										<ListItemIcon>
											<Checkbox
												checked={
													// @ts-ignore
													selected.indexOf(option.id) > -1
												}
											/>
										</ListItemIcon>
										<ListItemText primary={option.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Button type="submit" disabled={guideLoading}>
							{guideLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('guidesPage.update')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalCreate = ({ guides, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { guides: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [createGuide, { isLoading: guideLoading, isSuccess: guideSuccess }] = useAddGuideMutation();
	const [getHalls, { data: hallsData }] = useGetHallsMutation();

	const [selected, setSelected] = useState([]);
	const isAllSelected = hallsData?.length > 0 && selected.length === hallsData?.length;

	const { t } = useTranslation();

	useEffect(() => {
		getHalls();
	}, [isModalOpen['create'], getHalls]);

	useEffect(() => {
		if (guideSuccess) {
			setIsModalOpen({ ...isModalOpen, create: false });
			setOpen(true);
			setColor('success');
			setTextAlert(t('success.date_added'));
			guides();
		}
	}, [guideSuccess]);

	const handleChange = (event: any) => {
		const value = event.target.value;
		if (value[value.length - 1] === 'all') {
			setSelected(selected.length === hallsData?.length ? [] : hallsData.map((hall: any) => hall.id));
			return;
		}
		setSelected(value);
	};

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['create']} onClose={() => setIsModalOpen({ ...isModalOpen, create: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('guidesPage.add')}
				</Typography>
				<form
					onSubmit={async (event: React.FormEvent<GuideForm>) => {
						event.preventDefault();

						const { name, phone, halls } = event.currentTarget.elements;

						if (!isValidPhoneNumber(phone.value)) {
							setTextAlert(t('errors.phone_incorrect'));
							setColor('danger');
							setOpen(true);
							return;
						}

						// if (halls.value.length === 0) {
						// 	setTextAlert(t('errors.select_hall'));
						// 	setColor('danger');
						// 	setOpen(true);
						// 	return;
						// }

						const hallsIds = halls.value.split(',').map((item: any) => parseInt(item));

						const data = {
							name: name.value,
							phone: phone.value,
							halls: hallsIds,
						};

						await createGuide(data).unwrap();
					}}>
					<Stack spacing={2}>
						<FormControl>
							<FormLabel>{t('guidesPage.name')}</FormLabel>
							<Input autoFocus required name="name" />
						</FormControl>
						<FormControl>
							<FormLabel>{t('guidesPage.phone')}</FormLabel>
							<Input
								required
								name="phone"
								defaultValue={'+380'}
								onChange={(e: any) => {
									if (!e.target.value.startsWith('+380')) {
										e.target.value = '+380';
									}
								}}
							/>
						</FormControl>

						<FormControl>
							<FormLabel>{t('guidesPage.hall')}</FormLabel>
							<Select
								sx={{
									borderRadius: '10px',
								}}
								labelId="mutiple-select-label"
								multiple
								name="halls"
								value={selected}
								onChange={handleChange}
								renderValue={() => {
									if (selected.length === hallsData?.length) {
										return hallsData?.map((option: any) => option.name).join(', ');
									} else {
										return (
											hallsData
												// @ts-ignore
												?.filter((option: any) => selected.indexOf(option.id) > -1)
												.map((option: any) => option.name)
												.join(', ')
										);
									}
								}}>
								<MenuItem value="all">
									<ListItemIcon>
										<Checkbox checked={isAllSelected} indeterminate={selected.length > 0 && selected.length < hallsData?.length} />
									</ListItemIcon>
									<ListItemText primary={t('guidesPage.select_all') || 'Select all'} />
								</MenuItem>
								{hallsData?.map((option: any) => (
									<MenuItem key={option.id} value={option.id}>
										<ListItemIcon>
											<Checkbox
												checked={
													// @ts-ignore
													selected.indexOf(option.id) > -1
												}
											/>
										</ListItemIcon>
										<ListItemText primary={option.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Button type="submit" disabled={guideLoading}>
							{guideLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('guidesPage.addGuide')}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
};

const ModalDelete = ({ currentItem, guides, isModalOpen, setIsModalOpen, setOpen, setTextAlert, setColor }: { currentItem: any; guides: () => void; isModalOpen: any; setIsModalOpen: (isOpen: any) => void; setTextAlert: (test: string) => void; setColor: (color: string) => void; setOpen: (isOpen: boolean) => void }) => {
	const [deleteGuide, { isLoading: deleteGuideLoading, isSuccess: deleteGuideSuccess }] = useDelGuideMutation();
	const [getHalls, { data: hallsData }] = useGetHallsMutation();

	const { t } = useTranslation();

	useEffect(() => {
		getHalls();
	}, [isModalOpen['delete'], getHalls]);

	useEffect(() => {
		if (deleteGuideSuccess) {
			setTextAlert(t('success.date_deleted') || 'Date deleted');
			setColor('success');
			setOpen(true);
			guides();
		}
	}, [deleteGuideSuccess]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['delete']} onClose={() => setIsModalOpen({ ...isModalOpen, delete: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					// height: '90vh',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						// top: 'calc(-1/4 * var(--IconButton-size))',
						// right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('guidesPage.are_you_sure') + ' (' + currentItem?.name + ')'}
				</Typography>
				<Button
					onClick={async () => {
						if (currentItem) await deleteGuide(currentItem?.id);
						setIsModalOpen({ ...isModalOpen, delete: false });
					}}>
					{deleteGuideLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('guidesPage.delete')}
				</Button>
			</Sheet>
		</Modal>
	);
};

export default function Guides() {
	const [guides, { data: guidesData, isLoading: guidesIsLoading }] = useGuidesMutation();
	const user_data = useSelector(selectCurrentUser);
	const navigator = useNavigate();

	useEffect(() => {
		if ([2, 3].includes(parseInt(user_data?.museum?.type))) {
			navigator('/settings');
		}
	}, []);

	const [isModalOpen, setIsModalOpen] = useState<any>({});
	const [currentGuide, setCurrentGuide] = useState<any>();

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

	const { t } = useTranslation();

	useEffect(() => {
		guides();
	}, [guides]);

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			{guidesIsLoading ? (
				<Loading />
			) : (
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
					<ModalCreate guides={guides} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setColor={setColor} setTextAlert={setTextAlert} />
					<ModalUpdate currentItem={currentGuide} guides={guides} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setColor={setColor} setTextAlert={setTextAlert} />
					<ModalDelete currentItem={currentGuide} guides={guides} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setOpen={setOpen} setColor={setColor} setTextAlert={setTextAlert} />
					<Sheet
						variant="outlined"
						sx={{
							'--TableCell-height': '40px',
							// the number is the amount of the header rows.
							'--TableHeader-height': 'calc(1 * var(--TableCell-height))',
							'--Table-firstColumnWidth': '80px',
							'--Table-lastColumnWidth': '80px',
							// background needs to have transparency to show the scrolling shadows
							'--TableRow-stripeBackground': 'rgba(0 0 0 / 0.04)',
							'--TableRow-hoverBackground': 'rgba(0 0 0 / 0.08)',
							overflow: 'auto',
							background: theme => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
							linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
							radial-gradient(
							farthest-side at 0 50%,
							rgba(0, 0, 0, 0.12),
							rgba(0, 0, 0, 0)
							),
							radial-gradient(
								farthest-side at 100% 50%,
								rgba(0, 0, 0, 0.12),
								rgba(0, 0, 0, 0)
							)
							0 100%`,
							backgroundSize: '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
							backgroundRepeat: 'no-repeat',
							backgroundAttachment: 'local, local, scroll, scroll',
							backgroundPosition: 'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
							backgroundColor: 'background.surface',
						}}>
						<Table
							borderAxis="bothBetween"
							// stripe="odd"
							hoverRow
							sx={{
								'& tr > *:first-child': {
									position: 'sticky',
									left: 0,
									boxShadow: '1px 0 var(--TableCell-borderColor)',
									bgcolor: 'background.surface',
								},
								'& tr > *:last-child': {
									position: 'sticky',
									right: 0,
									bgcolor: 'var(--TableCell-headBackground)',
								},
							}}>
							<thead>
								<tr>
									<th style={{ width: 'var(--Table-firstColumnWidth)' }}>№</th>
									<th style={{ width: 200 }}>{t('guidesPage.name')}</th>
									<th style={{ width: 200 }}>{t('guidesPage.phone')}</th>
									<th style={{ width: 200 }}>{t('guidesPage.hall')}</th>
									<th aria-label="last" style={{ width: 'var(--Table-lastColumnWidth)' }} />
								</tr>
							</thead>
							<tbody>
								{guidesData?.map((row, index) => (
									<tr key={row.id}>
										<td>{index + 1}</td>
										<td>{row.name}</td>
										<td>{row.phone}</td>
										<td>{row.halls?.map((hall: any) => hall.name).join(', ')}</td>
										<td>
											<Box sx={{ display: 'flex', gap: 1 }}>
												<Button
													size="sm"
													variant="plain"
													color="neutral"
													onClick={() => {
														setCurrentGuide(row);
														setIsModalOpen({ ...isModalOpen, update: true });
													}}>
													<i className="fa-solid fa-edit"></i>
												</Button>
												<Button
													size="sm"
													variant="soft"
													color="danger"
													onClick={async () => {
														setCurrentGuide(row);
														setIsModalOpen({ ...isModalOpen, delete: true });
													}}>
													<i className="fa-solid fa-trash"></i>
												</Button>
											</Box>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginTop: '15px',
						}}>
						<Button startDecorator={<Add />} variant="solid" size="md" color="primary" sx={{ fontWeight: 600 }} onClick={() => setIsModalOpen({ ...isModalOpen, create: true })}>
							{t('guidesPage.addGuide')}
						</Button>
					</div>
				</Box>
			)}
		</Layout>
	);
}
