import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';
import { nanoid } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export interface User {
	id: number;
	login: string;
}

export interface UpdateSettings {
	id: number;
	key: string;
	value: string;
	userId: number;
}

export interface Request {
	userId: number;
}

export const api = createApi({
	baseQuery: customBaseQuery,
	endpoints: builder => ({
		excursions: builder.mutation<[], void>({
			query: () => ({
				url: `/excursions`,
				method: 'GET',
			}),
		}),
		delExcursion: builder.mutation({
			query: id => ({
				url: `/excursions/${id}`,
				method: 'DELETE',
			}),
		}),
		getExcursion: builder.mutation({
			query: id => ({
				url: `/excursions/${id}`,
				method: 'GET',
			}),
		}),
		editExcursion: builder.mutation({
			query: ({ id, data }) => ({
				url: `/excursions/${id}`,
				method: 'PATCH',
				body: data,
			}),
		}),
		addExcursion: builder.mutation({
			queryFn: async data => {
				const res = await axios.post(`${process.env.REACT_APP_API_URL}/excursions`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + Cookies.get('refreshToken'),
					},
				});

				return res.data;
			},
		}),
		updateImgExcursion: builder.mutation({
			queryFn: async ({ id, data }) => {
				const res = await axios.post(`${process.env.REACT_APP_API_URL}/excursions/updatePhotos/${id}`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: 'Bearer ' + Cookies.get('refreshToken'),
					},
				});

				return res.data;
			},
		}),
	}),
});

export const { useAddExcursionMutation, useDelExcursionMutation, useEditExcursionMutation, useExcursionsMutation, useGetExcursionMutation, useUpdateImgExcursionMutation } = api;
