import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import CssBaseline from '@mui/joy/CssBaseline';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../../app/services/auth';
import ColorSchemeToggle from '../../utils/ColorSchemaToggle';
import customTheme from '../../utils/theme';
import { setCredentials } from './authSlice';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { Select, Option, IconButton, Textarea, Checkbox } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../utils/Alert';
import LanguageIcon from '@mui/icons-material/Language';
import { Menu, MenuItem } from '@mui/joy';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useGetCitiesMutation } from '../../app/services/museum';
import * as CryptoJS from 'crypto-js';

interface FormElements extends HTMLFormControlsCollection {
	login: HTMLInputElement;
	password: HTMLInputElement;
	repeatPassword: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
	readonly elements: FormElements;
}

export default function Register() {
	const [register, { isLoading, isError, error, isSuccess, data }] = useRegisterMutation();
	const [open, setOpen] = useState(false);
	const [textError, setTextError] = useState('');
	const [index, setIndex] = useState(0);
	const [museumType, setMuseumType] = useState<number>(0);
	const [museumName, setMuseumName] = useState('');
	const [museumDescription, setMuseumDescription] = useState('');
	const [authorPhone, setAuthorPhone] = useState<string>('');
	const [visitorsPhone, setVisitorsPhone] = useState<string>('');
	const [address, setAddress] = useState('');
	const [link, setLink] = useState('');
	const [museum_direction, setMuseumDirection] = useState<number>();
	const [time_work, setTimeWork] = useState({
		mon: { start: '09:00', end: '18:00' },
		tue: { start: '09:00', end: '18:00' },
		wed: { start: '09:00', end: '18:00' },
		thu: { start: '09:00', end: '18:00' },
		fri: { start: '09:00', end: '18:00' },
		sat: { start: '', end: '' },
		sun: { start: '', end: '' },
	});
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [city, setCity] = useState();
	const [redir_after, setRedirAfter] = useState<string | undefined>(undefined);
	const [subscribeMuseum, setSubscribeMuseum] = useState<any>(false);
	const [getCities, { data: cities }] = useGetCitiesMutation();

	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open2 = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const convertPhoneNumber = (inp: string) => {
		if (isValidPhoneNumber(inp, 'UA')) {
			const phoneNumber = parsePhoneNumber(inp, 'UA');
			return phoneNumber.formatInternational();
		}
		return inp;
	};

	useEffect(() => {
		if (isSuccess && !isError) {
			if (!data?.user) {
				// @ts-ignore
				setTextError(t(`server_error.${data?.message}`));
				setOpen(true);
				return;
			}

			dispatch(setCredentials(data));

			if (!redir_after) {
				navigate('/settings');
			} else {
				const url = new URL(redir_after).origin;
				const other = new URL(redir_after).pathname.slice(1);

				const cryptoRefreshKey = CryptoJS.AES.encrypt(
					JSON.stringify({
						refreshToken: data.refreshToken,
						user: {
							...data.user,
							password: undefined,
						},
					}),
					process.env.REACT_APP_SECRET_KEY_REF || '',
				).toString();

				window.location.href = `${url}/authed?u=${encodeURIComponent(cryptoRefreshKey)}&museum=${other}`;
			}
		}
	}, [isSuccess, dispatch, navigate]);

	useEffect(() => {
		getCities();
	}, []);

	useEffect(() => {
		// get get params
		const params = new URLSearchParams(window.location.search);
		const museumType = params.get('type');
		const redirect = params.get('redirect') || undefined;
		const subscribe = params.get('subscribe') || undefined;

		if (museumType) {
			setMuseumType(parseInt(museumType));
			setIndex(1);
		}

		if (subscribe) {
			setSubscribeMuseum(subscribe);
		}

		if (redirect) {
			setRedirAfter(decodeURIComponent(redirect));
		}
	}, []);

	const ControlsIndex = ({ rightDisabled = false }): ReactElement => {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button
					onClick={() => {
						setIndex(index - 1);
					}}
					disabled={index === 0}>
					<i className="fa-solid fa-arrow-left"></i>
				</Button>
				<Button
					onClick={() => {
						setIndex(index + 1);
					}}
					disabled={rightDisabled}>
					<i className="fa-solid fa-arrow-right"></i>
				</Button>
			</Box>
		);
	};

	return (
		<CssVarsProvider defaultMode="light" disableTransitionOnChange theme={customTheme}>
			<CssBaseline />
			<GlobalStyles
				styles={{
					':root': {
						'--Transition-duration': '0.4s',
					},
				}}
			/>
			<Alert open={open} setOpen={setOpen} text={textError} />
			<Box
				sx={(theme: any) => ({
					width: '100vw',
					transition: 'width var(--Transition-duration)',
					transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
					position: 'relative',
					zIndex: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backdropFilter: 'blur(4px)',
					backgroundColor: 'rgba(255 255 255 / 0.6)',
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'rgba(19 19 24 / 0.4)',
					},
				})}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100dvh',
						width: '100%',
						maxWidth: '100%',
						px: 2,
					}}>
					<Box
						component="header"
						sx={{
							py: 3,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Link to={process.env.REACT_APP_MAIN_URL || '/'}>
							<Typography
								fontWeight="lg"
								startDecorator={
									// <Box
									// 	component="span"
									// 	sx={{
									// 		width: 24,
									// 		height: 24,
									// 		background: (theme: { vars: { palette: { primary: { solidBg: any; softBg: any } } } }) => `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
									// 		borderRadius: '50%',
									// 		boxShadow: (theme: { shadow: { md: any } }) => theme.shadow.md,
									// 		'--joy-shadowChannel': (theme: { vars: { palette: { primary: { mainChannel: any } } } }) => theme.vars.palette.primary.mainChannel,
									// 	}}
									// />
									<img src="favicon.ico" width={50} height={50} />
								}>
								{/* ARTCENTR */}
							</Typography>
						</Link>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
							<IconButton sx={{ ml: '5px', display: { xs: 'none', md: 'inline-flex' } }} id="basic-demo-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} size="sm" variant="plain" color="neutral" onClick={handleClick}>
								<LanguageIcon />
							</IconButton>
							<Menu id="basic-menu" anchorEl={anchorEl} open={open2} onClose={handleClose} aria-labelledby="basic-demo-button">
								<MenuItem
									onClick={() => {
										i18n.changeLanguage('en');
									}}>
									English
								</MenuItem>
								<MenuItem
									onClick={() => {
										i18n.changeLanguage('uk');
									}}>
									Українська
								</MenuItem>
							</Menu>
							<ColorSchemeToggle />
						</Box>
					</Box>
					<Box
						component="main"
						sx={{
							my: 'auto',
							py: 2,
							pb: 5,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							width: 600,
							maxWidth: '100%',
							mx: 'auto',
							borderRadius: 'sm',
							'& form': {
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							},
							[`& .${formLabelClasses.asterisk}`]: {
								visibility: 'hidden',
							},
						}}>
						<div>
							<Typography component="h2" fontSize="xl2" fontWeight="lg">
								{t('authPage.register')}
							</Typography>
						</div>

						<Tabs defaultValue={0} sx={{ borderRadius: 'lg' }} onChange={(event, value) => setIndex(value as number)} value={index}>
							<TabList>
								<Tab disabled>{t('authPage.step')} 1</Tab>
								<Tab disabled>{t('authPage.step')} 2</Tab>
								<Tab disabled>{t('authPage.step')} 3</Tab>
							</TabList>
							<TabPanel value={0} sx={{ p: 2 }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
									<Select
										defaultValue={museumType}
										onChange={(event: any, newValue: any) => {
											setMuseumType(parseInt(newValue));
										}}>
										{
											// @ts-ignore
											t('settingsPage.museumTypes', { returnObjects: true }).map((item: any, index: number) => {
												return (
													<Option key={index} value={index}>
														{item}
													</Option>
												);
											})
										}
									</Select>
									<ControlsIndex />
								</Box>
							</TabPanel>
							<TabPanel value={1} sx={{ p: 2 }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
									<form
										onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
											event.preventDefault();

											setIndex(index + 1);
										}}>
										<Input
											placeholder={t('settingsPage.name') || 'name'}
											type="text"
											required
											autoFocus
											name="museumName"
											value={museumName}
											onChange={(event: any) => {
												if (event.target.value.length > 60) {
													return;
												}

												setMuseumName(event.target.value);
											}}
										/>

										{[0, 1, 3].includes(museumType) && (
											<Select
												placeholder={t('settingsPage.city') || 'city'}
												onChange={(event: any, newValue: any) => {
													setCity(newValue);
												}}>
												{cities?.map((item: any, index: number) => {
													return (
														<Option key={item.id} value={item.title}>
															{item.title}
														</Option>
													);
												})}
											</Select>
										)}

										{[0, 1].includes(museumType) && (
											<Input
												placeholder={t('settingsPage.address') || 'address'}
												type="text"
												required
												name="museumAddress"
												value={address}
												onChange={(event: any) => {
													setAddress(event.target.value);
												}}
											/>
										)}

										{[0, 1].includes(museumType) && (
											<>
												<label htmlFor="visitorsPhone">{t('settingsPage.visitorsPhone') || 'visitorsPhone'}</label>

												<Input
													placeholder={t('settingsPage.visitorsPhone') || 'visitorsPhone'}
													type="text"
													required
													id="visitorsPhone"
													name="visitorsPhone"
													value={(visitorsPhone === '' ? '+380' : '') + visitorsPhone}
													onChange={(event: any) => {
														if (!event.target.value.startsWith('+380')) {
															const old = event.target.value;
															event.target.value = '+380' + old;
														}
														setVisitorsPhone(convertPhoneNumber(event.target.value));
													}}
												/>
											</>
										)}

										{/* {[0, 1].includes(museumType) && (
											<Input
												placeholder={t('settingsPage.authorPhoneOrPIB') || 'authorPhoneOrPIB'}
												type="text"
												// required
												name="authorPhoneOrPIB"
												value={authorPhone}
												onChange={(event: any) => {
													setAuthorPhone(event.target.value);
												}}
											/>
										)} */}

										<Select
											// defaultValue={museum_direction}
											placeholder={t('settingsPage.museumDirection') || 'museumDirection'}
											value={museum_direction}
											onChange={(event: any, newValue: any) => {
												setMuseumDirection(parseInt(newValue));
											}}>
											{
												// @ts-ignore
												t('settingsPage.museumDirections', { returnObjects: true })[museumType].map((item: any, index: number) => {
													return (
														<Option key={index} value={index}>
															{item}
														</Option>
													);
												})
											}
										</Select>

										<Input
											placeholder={t('settingsPage.link') || 'link'}
											type="text"
											name="link"
											value={link}
											onChange={(event: any) => {
												setLink(event.target.value);
											}}
										/>

										<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
											<Button
												onClick={() => {
													setIndex(index - 1);
												}}
												disabled={index === 0}>
												<i className="fa-solid fa-arrow-left"></i>
											</Button>
											<Button type="submit" disabled={!museumName || ([0, 1].includes(museumType) && !address) || museum_direction === undefined || ([0, 1].includes(museumType) && !isValidPhoneNumber(visitorsPhone, 'UA'))}>
												<i className="fa-solid fa-arrow-right"></i>
											</Button>
										</Box>
										{/* <ControlsIndex rightDisabled={!isValidPhoneNumber(visitorsPhone, 'UA')} /> */}
									</form>
								</Box>
							</TabPanel>
							<TabPanel value={2} sx={{ p: 2 }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
									<form
										onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
											event.preventDefault();
											const formElements = event.currentTarget.elements;

											const data = {
												login: formElements.login.value,
												password: formElements.password.value,
												passwordRepeat: formElements.repeatPassword.value,
											};

											if (data.password !== data.passwordRepeat) {
												setTextError(t('errors.passwords_notMatch') || 'passwords_notMatch');
												setOpen(true);

												return;
											}

											await register({
												login: data.login,
												password: data.password,
												museumType,
												museumName,
												museumInfo: museumDescription,
												museumStatus: 1,
												photo: '',
												museumDirection: museum_direction,
												link,
												city,
												address: [0, 1].includes(museumType) ? address : undefined,
												// authorPhoneOrPIB: authorPhone,
												visitorsPhone: [0, 1].includes(museumType) ? visitorsPhone : undefined,
												timeWork: [0, 1].includes(museumType) ? time_work : undefined,
												paymentMethod: 'card',
												cardNumber: '',
												recipientName: '',
												edrpou: '',
												iban: '',
												subscribe: subscribeMuseum || false,
											}).unwrap();
										}}>
										<FormControl required>
											<FormLabel>{t('authPage.email')}</FormLabel>
											<Input
												placeholder={t('authPage.email') || ''}
												type="text"
												name="login"
												value={email}
												onChange={(event: any) => {
													if (event.target.value.length > 30) {
														return;
													}

													setEmail(event.target.value);
												}}
											/>
										</FormControl>
										<FormControl required>
											<FormLabel>{t('authPage.password')}</FormLabel>
											<Input
												placeholder="•••••••"
												type="password"
												name="password"
												value={password}
												onChange={(event: any) => {
													setPassword(event.target.value);
												}}
											/>
										</FormControl>
										<FormControl required>
											<FormLabel>{t('authPage.repeatPassword')}</FormLabel>
											<Input placeholder="•••••••" type="password" name="repeatPassword" />
										</FormControl>

										<FormControl
											sx={{
												display: 'flex',
												alignItems: 'center',
												flexDirection: 'row',
												gap: 1,
												'& .MuiCheckbox-root': {
													padding: 0,
												},
											}}>
											<Checkbox required />
											<FormLabel>
												<a href={process.env.REACT_APP_MAIN_URL + '/privacy_policy'} target='_blank'>{t('accept_privacy')}</a>
											</FormLabel>
										</FormControl>

										<Box
											sx={{
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'center',
											}}></Box>
										<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
											<Button
												onClick={() => {
													setIndex(index - 1);
												}}
												sx={{ mr: 1 }}>
												<i className="fa-solid fa-arrow-left"></i>
											</Button>
											<Button type="submit" fullWidth disabled={isLoading}>
												{isLoading ? <CircularProgress color="primary" determinate={false} size="sm" variant="soft" /> : t('authPage.registerButton') || 'register'}
											</Button>
										</Box>
									</form>
								</Box>
							</TabPanel>
						</Tabs>
					</Box>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
