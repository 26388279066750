import { CircularProgress } from '@mui/joy';

export const Loading = () => (
	<CircularProgress
		sx={{
			width: '100vw',
			height: '100vh',
			top: 0,
			left: 0,
			transform: {
				sm: 'translateX(120px)', 
			},
			position: 'fixed',
			zIndex: -1,
		}}
	/>
);
