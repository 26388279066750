export const openSidebar = () => {
	if (typeof document !== 'undefined') {
		document.body.style.overflow = 'hidden';
		document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
	}
};

export const closeSidebar = () => {
	if (typeof document !== 'undefined') {
		document.documentElement.style.removeProperty('--SideNavigation-slideIn');
		document.body.style.removeProperty('overflow');
	}
};

export const toggleSidebar = () => {
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue('--SideNavigation-slideIn');
		if (slideIn) {
			closeSidebar();
		} else {
			openSidebar();
		}
	}
};

export const sidebarMenu = [
	{
		key: '/info',
		name: 'Панель керування',
		icon: 'info',
		subMenu: [
			{
				key: 'settings',
				name: 'Налаштування сторінки',
				icon: 'fa-solid fa-cog',
				isAdmin: false,
			},
			{
				key: 'halls',
				name: 'Зали',
				icon: 'fa-solid fa-hotel',
				isAdmin: false,
			},
			{
				key: 'exhibits',
				name: 'Експонати',
				icon: 'fa-solid fa-cubes',
				isAdmin: false,
			},
			{
				key: 'excursions',
				name: 'Екскурсії',
				icon: 'fa-solid fa-route',
				isAdmin: false,
			},
			{
				key: 'booking',
				name: 'Бронювання',
				icon: 'fa-solid fa-calendar-alt',
				isAdmin: false,
			},
			{
				key: 'guides',
				name: 'Гіди',
				icon: 'fa-solid fa-user-tie',
				isAdmin: false,
			},
			{
				key: 'events',
				name: 'Події',
				icon: 'fa-solid fa-calendar-plus',
				isAdmin: false,
			},
			{
				key: 'museums',
				name: 'Музеї',
				icon: 'fa-solid fa-landmark',
				isAdmin: true,
			}
		],
	},
];
